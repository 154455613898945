import React from "react";
import "./themeCard.css";
const ThemeCard = ({ children, cardTitle, customClass, customMainClass ,id}) => {
  return (
    <div className={`theme-card-component ${customMainClass}`} id={id}>
      <div className="card-compponent">
        <span className={`card-title ${customClass}`}>{cardTitle}</span>
        {children}
      </div>
    </div>
  );
};

export default ThemeCard;
