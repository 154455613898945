import React from "react";
import { WaterHeaterUserDeviceListPageContextProvider } from "./WaterHeaterUserDeviceListView";
import WaterHeaterUserDeviceListPage from "./views/WaterHeaterUserDeviceList";
const WaterHeaterUserDeviceList =()=>{
    return(
    <WaterHeaterUserDeviceListPageContextProvider>
        <WaterHeaterUserDeviceListPage/>
    </WaterHeaterUserDeviceListPageContextProvider>
    )
}
export default WaterHeaterUserDeviceList