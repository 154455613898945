import React from "react";
import { WaterHeaterDashBoardContextProvider } from "./WaterHeaterDashboardView";
import WaterHeaterDashboardPage from "./views/WaterHeaterDasboard";

const WaterHeaterDashBoard = () => {
  return (
    <WaterHeaterDashBoardContextProvider>
      <WaterHeaterDashboardPage />
    </WaterHeaterDashBoardContextProvider>
  );
};

export default WaterHeaterDashBoard;