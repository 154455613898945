import React, { useState } from "react";
import "./checkBoxComponent.css";
import { MDBCheckbox } from "mdb-react-ui-kit";


const CheckBoxComponent = ({
  id,
  label,
  value,
  checked,
  onChangeCheckbox,
  customClass,
}) => {
  return (
    <>
      <MDBCheckbox
        id={id}
        label={label}
        checked={checked}
        onChange={() => onChangeCheckbox(id, label, value, checked)}
        value={value}
        className={customClass}
      />
    </>
  );
};

export default CheckBoxComponent;
