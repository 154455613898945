import { useLocation, Navigate, Outlet } from "react-router-dom";
import RouteConstants from "../constants/routeConstants.json";
import { getCookie } from "../utils/service";

const AuthRouteProtection = (props) => {
  const location = useLocation();
  let loadComponent = false;
  let route = RouteConstants.LOGIN;
  const accessTocken = getCookie("x-access-token");
  const pathname = location.pathname;

  const loggedInUsersRoute = [
    RouteConstants.PLUG_TRENDS,
    RouteConstants.DEVICE_CONTROL,
    RouteConstants.DASHBOARD,
    RouteConstants.DEVICE_DATA,
    RouteConstants.PLUG_DASHBOARD,
    RouteConstants.DEVICE_CONNECTIVITY,
    RouteConstants.DEVICE_ALERT,
    RouteConstants.SEND_COMMAND,
    RouteConstants.PLUG_POWER_STATUS,
    RouteConstants.DEVICE_LOGS,
    RouteConstants.USER_DEVICE_LIST,
    RouteConstants.USER_MANAGEMENT,
    RouteConstants.USER_MANAGEMENT_CREATE,
    RouteConstants.HOME,
    RouteConstants.USER_DETAILS,
    RouteConstants.STATUS_DEVICE_LIST,
    // FAN UPDATES 
    RouteConstants.FAN_DASHBOARD,
    RouteConstants.FAN_DEVICE_DATA,
    RouteConstants.FAN_USER_DEVICE_LIST,
    //waterheater updates 
  RouteConstants.WATER_HEATER_DASHBOARD ,
  RouteConstants.WATER_HEATER_DEVICE_DATA,
  RouteConstants.WATER_HEATER_USER_DEVICE_LIST,
    // plug notification
    RouteConstants.NOTIFY_CONFIGURATION,
    // stabilizer update 
    RouteConstants.STABILIZER_DASHBOARD,
    RouteConstants.STABILIZER_DEVICE_DATA,
    RouteConstants.STABILIZER_USER_DEVICE_LIST,
    // retrofit update
    RouteConstants.RETROFIT_DASHBOARD,
    RouteConstants.RETROFIT_DEVICE_DATA,
    RouteConstants.RETROFIT_USER_DEVICE_LIST,
    // dups update
    RouteConstants.DUPS_DASHBOARD,
    RouteConstants.DUPS_DEVICE_DATA,
    RouteConstants.DUPS_USER_DEVICE_LIST
  ];

  const nonLoggedInUsersRoute = [
    RouteConstants.LOGIN,
    RouteConstants.RESET_PASSWORD,
    RouteConstants.FORGOT_PASSWORD,
  ];

  if (accessTocken) {
    if (nonLoggedInUsersRoute.includes(pathname)) {
      route = RouteConstants.HOME;
    } else {
      loadComponent = true;
    }
  } else {
    if (loggedInUsersRoute.includes(pathname)) {
      route = RouteConstants.LOGIN;
    } else {
      loadComponent = true;
    }
  }

  return (
    <>
      {loadComponent ? (
        <Outlet />
      ) : (
        <Navigate to={route} state={{ from: location }} replace />
      )}
    </>
  );
};

export default AuthRouteProtection;
