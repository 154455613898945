import React, { createContext, useEffect, useState } from "react";
import dummyData from "../../components/DataTableComponent/constant.json";
import ContentFile from "../../constants/ContentFile.json";

import {
  plugCustomCommand,
  plugQuickSendCommand,
  getSendCommandLogs,
  getListOfFirmware,
  getCurrentFirwareversion,
} from "../../apis/Api";
import { blankSpaceCheck } from "../../utils/service";
export const SendCommandPageContext = createContext({});
export const SendCommandContextProvider = ({ children }) => {
  const pageContent = ContentFile.deviceControlPage;
  const commadContent = ContentFile.sendCommandContentPage;
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [openTimeIntervalModal, setOpenTimeIntervalModal] = useState(false);
  const [openUpgradeFirmwareModal, setUpgradeFirmwareModal] = useState(false);
  const [version, setVersion] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [invalidVersion, setInvalidVersion] = useState(false);
  const [addCommandRequried, setAddCommandRequried] = useState(false);
  const [customCommand, setCustomCommand] = useState("");
  const [startModalVisible, setStartModalVisible] = useState(false);
  const [stopModalVisible, setstopModalVisible] = useState(false);
  const [successModal, setSuccessModal] = useState(false); // success modal show and hide
  const [sucessModalText, setSuccessModalText] = useState("");
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorPopupMessage, setErrorPopupMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [commandLogData, setCommandLogData] = useState();
  const [pageState, setpageState] = useState(null);
  const [tableBadgeRowId, setTableBadgeRowId] = useState(); // state fo table badge clicked
  const [commandDetailShowModal, setCommandDetailShowModal] = useState(false); // table target device badge modal

  const [currentVersion, setCurrentVersion] = useState("");

  const targetDevice = localStorage?.getItem("serialNo_NavBar");
  const categoryId = localStorage.getItem("dashboard_Id");
  useEffect(() => {
    getCommandLogs(pageState); // api call for get command logs
    // api call firmware api list
    getFirmwareList();
    getFirwareCurrentVersion(targetDevice);
  }, []);
  //cancel on modal
  const onCanclBtnClick = () => {
    setInvalidVersion(false);
    setUpgradeFirmwareModal(false);
  };

  // api call firmware list
  const getFirmwareList = async () => {
    setShowLoader(true);
    try {
      const response = await getListOfFirmware();
      if (response.data.status == 200) {
        setVersion(response?.data?.data);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setShowErrorPopup(true);
      setErrorPopupMessage(true);
    }
  };

  //dropdown select  on firmware function
  const handleSelect = (value) => {
    setSelectedValue(value?.name);
    validateFirmwareDropdown(value?.name);
  };

  const validateFirmwareDropdown = (value) => {
    let validationStatus = false;
    if (value === "") {
      setInvalidVersion(true);
      validationStatus = true;
    } else {
      setInvalidVersion(false);
      validationStatus = false;
    }
    return validationStatus;
  };
  //submit on upgrade firmware modal
  const onUpgradeFirmWareSubmitClick = () => {
    if (selectedValue === null) {
      setInvalidVersion(true);
      return true;
    } else {
      setInvalidVersion(false);
      setUpgradeFirmwareModal(false);
      setSelectedValue(null);

      const activeQuickSendCommandObject = {
        key: "updateFirmvare",
        value: selectedValue,
      };
      quickSendCommandAPI(targetDevice, activeQuickSendCommandObject);

      return false;
    }
  };

  // change on addcommand textinput
  const handleInputChange = (e) => {
    setCustomCommand(e.target.value);
    customCommandValidation(e.target.value);
  };

  const customCommandValidation = (value) => {
    if (value === "") {
      setAddCommandRequried(true);
      return true;
    } else if (blankSpaceCheck(value)) {
      setAddCommandRequried(true);
      return true;
    } else {
      setAddCommandRequried(false);
      return false;
    }
  };

  // submit on addcomand btn
  const sendCommandBtnClick = () => {
    if (addCommandRequried) {
    } else {
      if (customCommand === "") {
        setAddCommandRequried(true);
        return true;
      } else {
        customCommandAPI(targetDevice);
      }
    }
  };

  // on submit start command modal
  const onSubmitStartCommand = () => {
    const activeQuickSendCommandObject = {
      key: "start",
      value: "",
    };
    setStartModalVisible(false);
    quickSendCommandAPI(targetDevice, activeQuickSendCommandObject);
  };

  // on submit stop command modal
  const onSubmitStopCommand = () => {
    setstopModalVisible(false);
    const activeQuickSendCommandObject = {
      key: "stop",
      value: "",
    };
    quickSendCommandAPI(targetDevice, activeQuickSendCommandObject);
  };

  // on submit Time Interval modal
  const onSubmitTimeInterval = (time) => {
    const activeQuickSendCommandObject = {
      key: "timeInterval",
      value: time,
    };
    quickSendCommandAPI(targetDevice, activeQuickSendCommandObject);
    setOpenTimeIntervalModal(false);
  };

  // Quick send command API integration function
  const quickSendCommandAPI = async (
    targetDevice,
    activeQuickSendCommandObject
  ) => {
    setShowLoader(true);
    const command = {
      logType: "sendCommand",
      targetType: "srno",
      target: [targetDevice],
      key: activeQuickSendCommandObject.key,
      interval:
        activeQuickSendCommandObject?.key === "timeInterval"
          ? activeQuickSendCommandObject?.value
          : "",
      firmvareVersion:
        activeQuickSendCommandObject?.key === "updateFirmvare"
          ? activeQuickSendCommandObject?.value
          : "",
    };
    try {
      const response = await plugQuickSendCommand(command);
      setShowLoader(false);
      if (response?.data?.status === 200) {
        if (activeQuickSendCommandObject?.key === "start") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.startMessage);
        } else if (activeQuickSendCommandObject?.key === "stop") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.stopMessage);
        } else if (activeQuickSendCommandObject?.key === "timeInterval") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.TimeintervalSubmit);
        } else if (activeQuickSendCommandObject?.key === "updateFirmvare") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.FirmwareSubmit);
        }
      }
      getCommandLogs();
    } catch (error) {
      setShowErrorPopup(true);
      setErrorPopupMessage(error?.response?.data?.message);
      setShowLoader(false);
    }
  };

  // Custom command API integration function
  const customCommandAPI = async (targetDevice) => {
    setShowLoader(true);
    const command = {
      logType: "sendCommand",
      targetType: "srno",
      target: [targetDevice],
      command: customCommand,
      categoryId: categoryId, // category id 
    };
    try {
      const response = await plugCustomCommand(command);
      setShowLoader(false);
      if (response?.data?.status === 200) {
        setSuccessModal(true);
        setSuccessModalText(commadContent.customCommandSuccessmessage);
        getCommandLogs();
        setCustomCommand("");
      }
    } catch (error) {
      setShowErrorPopup(true);
      setErrorPopupMessage(error?.response?.data?.message);
    }
  };

  // api for command logs
  const getCommandLogs = async (pageState) => {
    setShowLoader(true);
    const data = {
      logType: "sendCommand",
      targetType: "srno",
      srno: targetDevice,
      fetchSize: 10,
      pageState: pageState,
    };
    try {
      const response = await getSendCommandLogs(data);
      setShowLoader(false);
      if (response.data.status === 200) {
        setCommandLogData(response.data.data.rows);
        setpageState(response.data.data.pageState);
      }
    } catch (error) {
      setShowErrorPopup(true);
      setErrorPopupMessage(error?.response?.data?.message);
    }
  };
  // pagination fuction
  const onHandlePagination = (pageState) => {
    getCommandLogs(pageState);
  };
  // show command details modal
  const showCommandModal = (time) => {
    setCommandDetailShowModal(true);
    setTableBadgeRowId(time);
  };
  // cancel table badge modal
  const badgeModalCancel = () => {
    setCommandDetailShowModal(false);
  };

  // get current firmware version
  const getFirwareCurrentVersion = async (serialNumber) => {
    setShowLoader(true);
    try {
      const response = await getCurrentFirwareversion(serialNumber);
      setShowLoader(false);
      if (response?.data?.status === 200) {
        setCurrentVersion(response?.data?.data);
      }
    } catch (error) {
      setShowErrorPopup(true);
      setErrorPopupMessage(error?.response?.data?.message);
    }
  };

  const providerValue = {
    dummyData,
    pageCount,
    setPageCount,
    currentPage,
    setcurrentPage,
    openTimeIntervalModal,
    setOpenTimeIntervalModal,
    openUpgradeFirmwareModal,
    setUpgradeFirmwareModal,
    onCanclBtnClick,
    onUpgradeFirmWareSubmitClick,
    version,
    setVersion,
    selectedValue,
    setSelectedValue,
    selectedItemId,
    setSelectedItemId,
    handleSelect,
    setInvalidVersion,
    invalidVersion,
    sendCommandBtnClick,
    customCommand,
    addCommandRequried,
    setCustomCommand,
    handleInputChange,
    startModalVisible,
    setStartModalVisible,
    stopModalVisible,
    setstopModalVisible,
    pageContent,
    onSubmitStartCommand,
    onSubmitStopCommand,
    setSuccessModal,
    successModal,
    sucessModalText,
    onSubmitTimeInterval,
    setInvalidVersion,
    showErrorPopup,
    setShowErrorPopup,
    errorPopupMessage,
    showLoader,
    commandLogData,
    onHandlePagination,
    pageState,
    currentVersion, // current frimware verison
    showCommandModal,
    setCommandDetailShowModal,
    commandDetailShowModal,
    badgeModalCancel, // badge modal cancel function
    tableBadgeRowId, // clicked row id
  };
  return (
    <SendCommandPageContext.Provider value={providerValue}>
      {children}
    </SendCommandPageContext.Provider>
  );
};
