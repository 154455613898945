import React from 'react'
import {DupsDeviceDataContextProvider} from "./dupsDeviceDataView"
import DupsDeviceData from "./views/DupsDeviceData"
const DupsDeviceDataPage = () => {

  return (
   <DupsDeviceDataContextProvider>
    <DupsDeviceData/>
   </DupsDeviceDataContextProvider>
  )
}

export default DupsDeviceDataPage
