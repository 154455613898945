import React from "react";
import {DupsDashboardContextProvider} from "./DupsDashboardView"
import DupsDashboardPage from "./views/DupsDasboard"
const DupsDashBoard = () => {

  return (
    <DupsDashboardContextProvider>
      <DupsDashboardPage />
    </DupsDashboardContextProvider>
  );
};

export default DupsDashBoard;