import React from 'react'
import { RetrofitDeviceDataContextProvider } from './retrofitDeviceDataView'
import RetrofitDeviceData from './views/RetrofitDeviceData'

const RetrofitDeviceDataPage = () => {
  return (
   <RetrofitDeviceDataContextProvider>
    <RetrofitDeviceData/>
   </RetrofitDeviceDataContextProvider>
  )
}

export default RetrofitDeviceDataPage
