import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RouteConstant from "../../constants/routeConstants.json";
import { viewUserDetailsAPI } from "../../apis/Api";

export const UserDetailsContext = createContext();
export const UserDetailsContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState("");
  const [roleName, setRoleName] = useState("");
  const [showLoader, setShowloader] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [ErrorAlertMessage, setErrorAlertMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.id;

  useEffect(() => {
    getUserDetails(userId);
  }, []);

  const getUserDetails = async (id) => {
    setShowloader(true);
    try {
      const response = await viewUserDetailsAPI(id);
      setShowloader(false);
      if (response?.data?.status === 200) {
        setUserDetails(response?.data?.data?.userDetails);
        setRoleName(response?.data?.data?.userDetails?.role);
      }
    } catch (error) {
      setShowloader(false);
      setShowErrorAlert(true);
      setErrorAlertMessage(error?.response?.data?.message);
    }
  };

  const onHandleCancel = () => {
    navigate(RouteConstant.USER_MANAGEMENT);
  };

  const providerValue = {
    showLoader,
    userDetails,
    roleName,
    onHandleCancel,
    showErrorAlert,
    setShowErrorAlert,
    ErrorAlertMessage,
  };
  return (
    <UserDetailsContext.Provider value={providerValue}>
      {children}
    </UserDetailsContext.Provider>
  );
};
