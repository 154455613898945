import React, { createContext, useState } from "react";
import viewUser from "../../assets/images/icons/viewUser.svg";
import editUser from "../../assets/images/icons/EditUser.svg";
import deleteUser from "../../assets/images/icons/DeleteUser.svg";
import RouteConstants from "../../constants/routeConstants.json";
import { useNavigate, useLocation } from "react-router-dom";
import { userListApi, deleteUserData } from "../../apis/Api";
import { useEffect } from "react";

export const UserManagementContext = createContext({});
export const UserManagementContextProvider = ({ children }) => {
  const [userList, setUserList] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const [deleteConform, setDeleteConform] = useState(false);
  const [conformationPopup, setConformationPopup] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [userId, setUserId] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const deletUserDetails = async (id) => {
    setConformationPopup(false)
    setLoaderStatus(true);
    try {
      setLoaderStatus(false);
      const deleteUser = await deleteUserData(id);
      if (deleteUser.data.status === 200) {
        setLoaderStatus(false);
        setSuccessAlert(true);
        setErrorMessage(deleteUser.data.message);
      }
    } catch (error) {
      setConformationPopup(false);
      setLoaderStatus(false);
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401 ||
        error?.response?.data?.status === 403
      ) {
        setLoaderStatus(false);
        const errorMessage = error?.response?.data?.message;
        setErrorMessage(errorMessage);
        setErrorAlertShow(true);
      } else {
        setLoaderStatus(false);
        setErrorMessage("Something went wrong...");
        setErrorAlertShow(true);
      }
    }
  };

  const header = [
    {
      title: "Name",
      prop: "name",
    },

    {
      title: "Email",
      prop: "email",
    },
    {
      title: "Role",
      prop: "role",
    },
    {
      title: "Action",
      prop: "action",
      cell: (row) => (
        <div className="action-column">
          <div
            onClick={() => {
              onHandleViewDetails(row.id);
            }}
          >
            <img src={viewUser} />
          </div>
          <div
            onClick={() => {
              editUserDetail(row.id);
            }}
          >
            <img src={editUser} />
          </div>
          <div
            onClick={() => {
              setConformationPopup(true);
              setUserId(row.id);
            }}
          >
            <img src={deleteUser} />
          </div>
        </div>
      ),
    },
  ];
  const data = [
    {
      id: 1,
      name: "Avinash",
      email: "avinash@gmail.com",
      role: "R&D",
      action: "action",
    },
    {
      id: 2,
      name: "name",
      email: "email",
      role: "role",
      action: "action",
    },
    {
      id: 3,
      name: "name",
      email: "email",
      role: "role",
      action: "action",
    },
    {
      id: 4,
      name: "Neethu",
      email: "neethu@gmail.com",
      role: "R&D",
      action: "action",
    },
  ];
  const userManagementList = async (page) => {
    try {
      setLoaderStatus(true);
      const getUserList = await userListApi(page);
      if (getUserList?.status === 200) {
        setLoaderStatus(false);
        setUserList(
          getUserList?.data?.data?.adminUsers?.data?.map((item) => ({
            ...item,
            name: item?.name,
            email: item?.email,
            role: item?.role?.roleName,
            roleId:item?.role?.id
          }))
        );
        setCurrentPage(getUserList?.data?.data?.adminUsers?.currentPage);
        setTotalPage(getUserList?.data?.data?.adminUsers?.totalPages);
        setErrorAlertShow(false);
      }
    } catch (error) {
      setStatusCode(error?.response?.data?.status);
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        setLoaderStatus(false);
        const errorMessage = error?.response?.data?.message;
        setErrorMessage(errorMessage);
        setErrorAlertShow(true);
      } else {
        setLoaderStatus(false);
        setErrorMessage("Something went wrong...");
        setErrorAlertShow(true);
      }
    }
  };
  const onPageChange = (e) => {
    setCurrentPage(e);
  };

  useEffect(() => {
    userManagementList(1);
  }, []);
  const addUserClick = () => {
    navigate(
      {
        pathname: RouteConstants.USER_MANAGEMENT_CREATE,
        search: "?plug=Plug",
      },
      {
        state: {
          isEdit: false,
        },
      }
    );
  };
  const editUserDetail = (id) => {
    const userDetail = userList?.filter((item) => {
      if (item?.id === id) {
        getUserDetail(item?.id);
      }
    });
    return userDetail;
  };
  const getUserDetail = (id) => {
    const userInfo = userList.find((obj) => obj.id === id);
    navigate(
      {
        pathname: RouteConstants.USER_MANAGEMENT_CREATE,
        search: "?plug=Plug",
      },
      {
        state: {
          isEdit: true,
          userData: userInfo,
        },
      }
    );
  };

  const onHandleViewDetails = async (userId) => {
    navigate(
      { pathname: RouteConstants.USER_DETAILS, search: "?plug=Plug" },
      { state: { id: userId } }
    );
  };
  const loginPage = () => {
    navigate(RouteConstants.LOGIN);
  };

  const providerValue = {
    header,
    data,
    addUserClick,
    editUserDetail,
    userList,
    errorAlertShow,
    setErrorAlertShow,
    errorMessage,
    currentPage,
    totalPage,
    loaderStatus,
    onPageChange,
    statusCode,
    loginPage,
    deleteConform,
    successAlert,
    conformationPopup,
    setConformationPopup,
    setDeleteConform,
    userId,
    deletUserDetails,
    setLoaderStatus,
    setSuccessAlert,
    userManagementList
  };
  return (
    <UserManagementContext.Provider value={providerValue}>
      {children}
    </UserManagementContext.Provider>
  );
};
