import React, { useState } from "react";

import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import "./roundeddropDown.css";

const RoundedDropDownItem = ({
  list,
  onSelect,
  selectedValue,
  PlaceHolderValue,
  onchange,
  customClass,
}) => {
  return (
    <div className={`customRounded-dropdown ${customClass}`}>
      <MDBDropdown>
        <MDBDropdownToggle className="dropDown-toggle">
          {/* {selectedValue ? ( */}
          <div>
           {selectedValue === "All"?<span>Model:</span>:""} 
            <span className="selected-text">
              {selectedValue ? selectedValue : PlaceHolderValue}
            </span>
          </div>
          {/* ) : (
             PlaceHolderValue
          )} */}
        </MDBDropdownToggle>
        <MDBDropdownMenu className="menuItem">
          <MDBDropdownItem
            onClick={() => onSelect("", "All", "")}
            onChange={onchange}
            Selected
          >
            All
          </MDBDropdownItem>
          {list.map((item) => (
            <MDBDropdownItem
              key={item.id}
              onClick={() => onSelect(item?.id, item?.name, item?.model)}
              onChange={onchange}
            >
              {item?.name}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export default RoundedDropDownItem;
