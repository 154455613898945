import React from "react";
import "./loaderComponent.css";

const Loader = ({ position = "fixed" }) => {
  return (
    <div
      className={
        position === "fixed"
          ? "loader-container fixed"
          : "loader-container absolute"
      }
    >
      <svg
        class="pl"
        viewBox="0 0 200 200"
        width="200"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
            <stop offset="0%" stop-color="#f39200" />
            <stop offset="100%" stop-color="#FFC739" />
          </linearGradient>
          <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stop-color="#FFC739" />
            <stop offset="100%" stop-color="#f39200" />
          </linearGradient>
        </defs>
        <circle
          class="pl__ring"
          cx="100"
          cy="100"
          r="75"
          fill="none"
          stroke="url(#pl-grad1)"
          stroke-width="20"
          stroke-dasharray="0 257 1 257"
          stroke-dashoffset="0.01"
          stroke-linecap="round"
          transform="rotate(-98,100,100)"
        />
        <line
          class="pl__ball"
          stroke="url(#pl-grad2)"
          x1="100"
          y1="18"
          x2="100.01"
          y2="182"
          stroke-width="25"
          stroke-dasharray="1 165"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default Loader;
