import React from "react";
import { FanUserDeviceListPageContextProvider } from "./FanUserDeviceListView";
import FanUserDeviceListPage from "./views/FanUserDeviceList";
const FanUserDeviceList =()=>{
    return(
    <FanUserDeviceListPageContextProvider>
        <FanUserDeviceListPage/>
    </FanUserDeviceListPageContextProvider>
    )
}
export default FanUserDeviceList