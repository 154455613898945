import React, { useContext } from "react";
import { PlugDeviceLogContext } from "../PlugDeviceLogView";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import { MDBRow } from "mdb-react-ui-kit";
import "./plugDeviceLog.css";
import DataTable from "../../../components/DataTableComponent/DataTable";
import errorIcon from "../../../assets/images/icons/errorIcon.svg";
import { Pagination } from "../../../components/Pagination/Pagination";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import ErrorIcon from "../../../assets/images/icons/Error.svg";
import moment from "moment";
const PlugDeviceLog = () => {
  const header = [
    {
      title: "Timestamp",
      prop: "time",
      cell: (row) => (
        <div className="time-stamp-row">
         { moment(row.time, 'DD/MM/YYYY, h:mm:ss a').format('DD/MM/YYYY, HH:mm:ss ')}
        </div>
      ),
    },

    {
      title: " Status",
      prop: "status",
      cell: (row) => (
        <>
          {row.status === "error" ? (
            <>
              <div className="error-icon text-start">
                {row.status}
                <span className="error-tooltip">
                  <img src={errorIcon} className="ms-1" />
                  <span className="error-text-on-hover">{row.message}</span>
                </span>
              </div>
            </>
          ) : (
            <div className="text-start">{row.status}</div>
          )}
        </>
      ),
    },
  ];
  const {
    deviceLogDataList, // Device log data list
    currentpageState,
    onHandlePagination,
    showLoader,
    errorModalMessage,
    errorModal,
    setErrorModal,
  } = useContext(PlugDeviceLogContext);
  return (
    <div className="device-view-logs">
      {showLoader ? <Loader /> : ""}
      <MDBRow className="device-log-heading">Device Logs</MDBRow>
      <ThemeCard customClass={"device-log-card-title"}>
        <div className="time-stamp-table">
          <DataTable
            header={header}
            tableData={deviceLogDataList}
            isPagination={true}
          />
        </div>
      </ThemeCard>
      <div className="pagination-mt">
        {deviceLogDataList ? (
          <Pagination
            onChnagePagination={(pageState) => onHandlePagination(pageState)}
            currentPageState={currentpageState}
            dataTablevalue={deviceLogDataList}
          />
        ) : (
          ""
        )}
      </div>

      {/* Alert pop to show error */}
      <Alertpopup
        image={ErrorIcon}
        setShowModal={setErrorModal}
        showModal={errorModal}
        titleText="Error"
        DetailText={errorModalMessage}
        modalType="error-modal"
        alertButtonText="Continue"
      />
    </div>
  );
};

export default PlugDeviceLog;
