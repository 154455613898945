import React from "react";
import { DeviceConnectivityContextProvider } from "./DeviceConnectivityView";
import DeviceConnectivity from "./views/DeviceConnectivity";

const DeviceConnectivityPage = () => {
  return (
    <DeviceConnectivityContextProvider>
      <DeviceConnectivity />
    </DeviceConnectivityContextProvider>
  );
};

export default DeviceConnectivityPage;
