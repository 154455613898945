import React, { createContext, useContext, useState } from "react";
import contentFile from "../../constants/ContentFile.json";
import { createCookie, validateEmail } from "../../utils/service";
import { useNavigate } from "react-router";
import RouteContstants from "../../constants/routeConstants.json";
import { loginApi } from "../../apis/Api";
import { useCookies } from "react-cookie";
import { GlobalStateContext } from "../../store/store";
import { LAYOUT } from "../../store/type";

export const LoginContext = createContext({});

export const LoginContextProvider = ({ children }) => {
  const pageContent = contentFile.authPage;
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  // Validation Error message state
  const [emailValidationMsg, setEmailValidationMsg] = useState(" ");
  const [passwordValidateMsg, setPasswordValidateMsg] = useState(" ");
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");

  const { getUserRoleId } = useContext(GlobalStateContext);

  // set input value to state using handle change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    emailValidation(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    passwordValidation(e.target.value);
  };

  // Email validation

  const emailValidation = (value) => {
    if (value === "") {
      setEmailValidationMsg(pageContent.emailRequired);
      return false;
    } else if (!validateEmail(value)) {
      setEmailValidationMsg(pageContent.invalidEmail);
      return false;
    } else {
      setEmailValidationMsg("");
      return true;
    }
  };

  // Password validation

  const passwordValidation = (value) => {
    if (value === "") {
      setPasswordValidateMsg(pageContent.passwordRequired);
      return false;
    } else {
      setPasswordValidateMsg("");
      return true;
    }
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    const userData = {
      email: email,
      password: password,
    };
    if (!validateEmail(email)) {
      setEmailValidationMsg(pageContent.emailRequired);
    } else {
      setEmailValidationMsg(false);
    }
    if (!password) {
      setPasswordValidateMsg(pageContent.passwordRequired);
    } else {
      setPasswordValidateMsg(false);
    }

    if (validateEmail(email) && password !== "") {
      try {
        setIsLoading(true);
        const getData = await loginApi(userData);
        if (getData?.status === 200) {
          handleLocalStorage();
          const accessTocken = getData?.data?.data?.userDetails?.accessToken;
          const userRoleId = getData?.data?.data?.userDetails?.role?.id;
          getUserRoleId(userRoleId);
          setIsLoading(false);
          createCookie("x-access-token", accessTocken);
          navigate(RouteContstants.HOME);
          setErrorAlertShow(false);
        }
      } catch (error) {
        if (
          error?.response?.data?.status === 400 ||
          error?.response?.data?.status === 401
        ) {
          setIsLoading(false);
          const errorMessage = error?.response?.data?.message;
          setErrorAlertMessage(errorMessage);
          setErrorAlertShow(true);
        } else {
          setIsLoading(false);
          setErrorAlertMessage("Something went wrong...");
          setErrorAlertShow(true);
        }
      }
    }
  };

  // Page navigation forgot password

  const navigatePage = () => {
    navigate(RouteContstants.FORGOT_PASSWORD);
  };

  // Store value to localstorage

  const handleLocalStorage = () => {
    sessionStorage.setItem("value", true);
  };

  const providerValue = {
    pageContent,
    submitLogin,
    emailValidationMsg,
    email,
    password,
    handleEmailChange,
    errorAlertMessage,
    errorAlertShow,
    spinnerStatus,
    setErrorAlertShow,
    handlePasswordChange,
    passwordValidateMsg,
    navigatePage,
    setPassword,
    passwordType,
    setPasswordType,
    isLoading,
    setIsLoading,
  };
  return (
    <LoginContext.Provider value={providerValue}>
      {children}
    </LoginContext.Provider>
  );
};
