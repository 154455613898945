import React, { useContext } from "react";
import { SendCommandPageContext } from "../SendCommandView";
import sendCommandContentPage from "../../../constants/ContentFile.json";
import "./sendCommand.css";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import InputComponent from "../../../components/InputComponent/InputComponent";
import DataTable from "../../../components/DataTableComponent/DataTable";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import TimeIntervalModal from "../../../components/TimeIntervalModal/TimeIntervalModal";
import DropDownItem from "../../../components/DropDownComponent/DropDown";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import ConfrimationImage from "../../../assets/images/Confirmation.svg";
import SuccessImage from "../../../assets/images/Success.svg";
import ErrorImage from "../../../assets/images/icons/Error.svg";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import { Pagination } from "../../../components/Pagination/Pagination";
import eyeIcon from "../../../assets/images/icons/eyeIcon.svg";
import moment from "moment";

const SendCommandPage = () => {
  const sendCommand = sendCommandContentPage.sendCommandContentPage;
  const {
    pageCount,
    setPageCount,
    currentPage,
    setcurrentPage,
    dummyData,
    openTimeIntervalModal,
    setOpenTimeIntervalModal,
    openUpgradeFirmwareModal,
    setUpgradeFirmwareModal,
    onCanclBtnClick,
    onUpgradeFirmWareSubmitClick,
    version,
    selectedValue,
    sendCommandBtnClick,
    handleSelect,
    invalidVersion,
    customCommand,
    addCommandRequried,
    handleInputChange,
    startModalVisible,
    setStartModalVisible,
    stopModalVisible,
    setstopModalVisible,
    pageContent,
    onSubmitStartCommand,
    onSubmitStopCommand,
    setSuccessModal,
    successModal,
    sucessModalText,
    onSubmitTimeInterval,
    setInvalidVersion,
    showErrorPopup,
    setShowErrorPopup,
    errorPopupMessage,
    showLoader,
    commandLogData,
    onHandlePagination,
    pageState,
    currentVersion, // current frimware verison
    showCommandModal,
    setCommandDetailShowModal,
    commandDetailShowModal,
    badgeModalCancel, // badge modal cancel function
    tableBadgeRowId, // clicked row id
  } = useContext(SendCommandPageContext);
  const header = [
    {
      title: "User",
      prop: "user",
      cell: (row) => (row?.user ? row.user : "-"),
    },
    {
      title: "Command",
      prop: "command",
      cell: (row) => {
        const commandLength = row.command.length;
        const commandSendTime = new Date(row?.time).getTime();
        return commandLength > 50 ? (
          <div className="command-column-box">
            <div className="command-column">{row.command}</div>
            <div>
              <img
                src={eyeIcon}
                onClick={() => {
                  showCommandModal(commandSendTime);
                }}
              />
            </div>
          </div>
        ) : (
          row.command
        );
      },
    },
    {
      title: "Date & Time",
      prop: "time",
      cell: (row) => (
        <div>
          <div>{row?.time ? moment(row?.time).format("DD/MM/YYYY") : "-"}</div>
          <div>{row?.time ? moment(row?.time).format("HH:mm:ss") : "-"}</div>
        </div>
      ),
    },
  ];
  return (
    <div className="send-command-page">
      {showLoader === true ? <Loader /> : ""}
      <div className="command-Text">{sendCommand.sendCommand}</div>

      <div className="quick-send-row">
        <MDBRow className="command-row">
          <MDBCol xxl={6} xl={7} lg={7} className="quick-send-input-row mt-4">
            <ThemeCard cardTitle={sendCommand.quickSend}>
              <div className="text-quick-send-row">
                <MDBContainer fluid>
                  <MDBRow>
                    <MDBCol
                      xxl={2}
                      xl={2}
                      lg={6}
                      md={6}
                      className="lg-padding-right"
                    >
                      <ButtonComponent
                        text="Start"
                        buttonWidth="100%"
                        customClass="mt-3 button-text"
                        onClick={() => {
                          setStartModalVisible(true);
                        }}
                      />
                    </MDBCol>
                    <MDBCol
                      xxl={2}
                      xl={2}
                      lg={6}
                      md={6}
                      className="lg-padding-right lg-padding-left  md-padding-left"
                    >
                      <ButtonComponent
                        text="Stop"
                        buttonWidth="100%"
                        customClass="mt-3 button-text "
                        onClick={() => {
                          setstopModalVisible(true);
                        }}
                      />
                    </MDBCol>
                    <MDBCol
                      xxl={4}
                      xl={4}
                      lg={6}
                      md={6}
                      className="lg-padding-right padding-left"
                    >
                      <ButtonComponent
                        text="Time Interval"
                        buttonWidth="100%"
                        customClass="mt-3 button-text"
                        onClick={() => {
                          setInvalidVersion(false);
                          setOpenTimeIntervalModal(true);
                        }}
                      />
                    </MDBCol>
                    <MDBCol
                      xxl={4}
                      xl={4}
                      lg={6}
                      md={6}
                      className="lg-padding-right lg-padding-left md-padding-left"
                    >
                      <ButtonComponent
                        text="Upgrade Firmware"
                        buttonWidth="100%"
                        customClass="mt-3 button-text"
                        onClick={() => {
                          setUpgradeFirmwareModal(true);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </div>
            </ThemeCard>
          </MDBCol>

          <MDBCol xxl={6} xl={5} lg={5} className="mt-4">
            <ThemeCard cardTitle={sendCommand.sendCommand}>
              <MDBContainer fluid>
                <MDBRow>
                  <MDBCol
                    xxl={9}
                    xl={8}
                    lg={12}
                    md={8}
                    className="lg-padding-right mt-3"
                  >
                    <InputComponent
                      placeholder="Add command here"
                      value={customCommand}
                      type="text"
                      onChange={handleInputChange}
                      customClass={addCommandRequried ? "error" : "valid"}
                    />
                  </MDBCol>
                  <MDBCol
                    xxl={3}
                    xl={4}
                    lg={5}
                    md={4}
                    className="lg-padding-right mt-3 md-padding-left"
                  >
                    <ButtonComponent
                      coloredButton={true}
                      buttonWidth="100%"
                      text="Submit"
                      onClick={() => {
                        sendCommandBtnClick();
                      }}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </ThemeCard>
          </MDBCol>
        </MDBRow>
      </div>
      <div>
        <MDBRow>
          <ThemeCard cardTitle={sendCommand.commandLogs}>
            <DataTable
              header={header}
              tableData={commandLogData}
              isPagination={true}
              // onPageChange={(e) => onPageChange(e)}
              currentPage={currentPage}
              pageCount={pageCount}
            />
          </ThemeCard>
        </MDBRow>
        {/* pagination */}
        {commandLogData ? (
          <div className="pagination-button">
            <Pagination
              onChnagePagination={(pageState) => onHandlePagination(pageState)}
              currentPageState={pageState}
              dataTablevalue={commandLogData}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {/* --------------- time interval modal ------------------------ */}
      <TimeIntervalModal
        title={"Time Interval"}
        showModal={openTimeIntervalModal}
        setShowModal={setOpenTimeIntervalModal}
        onClickSubmit={(value) => {
          onSubmitTimeInterval(value);
        }}
      />

      {/* --------------- time interval modal ------------------------ */}

      <ModalComponent
        showModal={openUpgradeFirmwareModal}
        setShowModal={setUpgradeFirmwareModal}
        title={sendCommand.updateFirmaware}
        cancelButtonText={"Cancel"}
        SubmitButtonText={"Submit"}
        onClickCancel={onCanclBtnClick}
        onClickSubmit={onUpgradeFirmWareSubmitClick}
        customWidth="upgrade-modal-width"
      >
        <div className="current-version-box">
          <div className="version-text-box">
            <h9 className="text-current-target-version">Current Version</h9>
            <h5 className="text-version">{currentVersion}</h5>
          </div>
        </div>
        <div className="target-version-box">
          <p className="text-target-version">Target version</p>
        </div>
        <DropDownItem
          placeHolderValue="Select"
          list={version}
          selectedValue={selectedValue}
          onSelect={(value) => {
            handleSelect(value);
          }}
          customClass={invalidVersion === true ? "drop-error" : ""}
          // selectedValue={selectedValue}
          // onSelect={handleSelect}
          // customClass={invalidVersion ? "dropdown" : "error"}
        />
      </ModalComponent>

      {/* ------------- start confrimation modal ------------------------- */}
      <Alertpopup
        showModal={startModalVisible}
        setShowModal={setStartModalVisible}
        image={ConfrimationImage}
        buttonText={pageContent.startCommadAlertPopup.start}
        titleText={pageContent.startCommadAlertPopup.title}
        DetailText={pageContent.startCommadAlertPopup.detailText}
        onClick={onSubmitStartCommand}
      ></Alertpopup>
      {/* --------------- start confrimation modal --------------------------------- */}

      {/* -------------------- stop confirmtaion modal ------------------------------- */}
      <Alertpopup
        showModal={stopModalVisible}
        setShowModal={setstopModalVisible}
        image={ConfrimationImage}
        buttonText={pageContent.stopCommadAlertPopup.stop}
        titleText={pageContent.stopCommadAlertPopup.title}
        DetailText={pageContent.stopCommadAlertPopup.detailText}
        onClick={onSubmitStopCommand}
      ></Alertpopup>
      {/* -------------------- stop confirmtaion modal ------------------------------- */}
      {/* ---------- success Alert --------------------------------------- */}

      <Alertpopup
        modalType={pageContent.successmodal.type}
        showModal={successModal}
        setShowModal={setSuccessModal}
        image={SuccessImage}
        titleText={pageContent.successmodal.title}
        DetailText={sucessModalText}
        alertButtonText={"Continue"}
      />
      {/* ---------- success Alert --------------------------------------- */}

      {/* ---------- Error Alert --------------------------------------- */}

      <Alertpopup
        modalType={pageContent.errorPopup.type}
        showModal={showErrorPopup}
        setShowModal={setShowErrorPopup}
        image={ErrorImage}
        titleText={pageContent.errorPopup.title}
        DetailText={errorPopupMessage}
        alertButtonText={"Continue"}
      />
      {/* ---------- Error Alert --------------------------------------- */}
      {/* -------------------command modal---------------------- */}
      <div className="badge-modal">
        <ModalComponent
          showModal={commandDetailShowModal}
          setShowModal={setCommandDetailShowModal}
          cancelButtonText={pageContent.cancelText}
          onClickCancel={badgeModalCancel}
          title={"Command"}
          customWidth="command-badge-modal-width"
        >
          {commandLogData?.map((item) => {
            const commandSendTime = new Date(item?.time).getTime();
            if (tableBadgeRowId === commandSendTime) {
              return (
                <div className="command-listing-box">
                  <div className="item-command"> {item.command}</div>
                </div>
              );
            }
          })}
        </ModalComponent>
      </div>
      {/* -------------------command modal----------------------- */}
    </div>
  );
};
export default SendCommandPage;
