import React, { useContext, useState } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBBreadcrumbItem,
  MDBCol,
} from "mdb-react-ui-kit";

import Logo from "../../assets/images/Logo.svg";
import Userimage from "../../assets/images/ProfileIcon.svg";
import Search from "../../assets/images/icons/searchClickIcon.svg";
import logoutImage from "../../assets/images/Logout.svg";
import success from "../../assets/images/Success.svg";

import BreadCrumb from "../BreadCrumbComponent/BreadCrumb";
import BreadCrumbItems from "../../constants/ContentFile.json";
import Error from "../../assets/images/icons/Error.svg";

import "./topbar.css";
import contentData from "../../constants/ContentFile.json";
import {
  BreadcrumbItem,
  Container,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import DisabledBoxComponent from "../DisabledInputComponent/DisabledBoxComponent";
import routeConstant from "../../constants/routeConstants.json";
import InputComponent from "../InputComponent/InputComponent";
import { phoneNumberSearch, getprofileDetail, logoutAPI } from "../../apis/Api";
import Alertpopup from "../AlertPopup/AlertPopup";
import { deleteCookie, validateMobileNumber } from "../../utils/service";
import { useEffect } from "react";
import Loader from "../LoaderComponent/LoaderComponent";
import { GlobalStateContext } from "../../store/store";

const Topbar = () => {
  const { state } = useContext(GlobalStateContext);
  const location = useLocation();
  const currentPage = location?.pathname; // Replace with your actual current page or route
  // Determine whether to show the breadcrumb based on the current page

  const showBreadcrumb = currentPage === routeConstant.HOME ? false : true;
  const searchByPhoneNumber =
    currentPage === routeConstant.DASHBOARD ||
    currentPage === routeConstant.FAN_DASHBOARD ||
    currentPage === routeConstant.NOTIFY_CONFIGURATION ||
    currentPage === routeConstant.WATER_HEATER_DASHBOARD ||
    currentPage === routeConstant.STABILIZER_DASHBOARD ||
    currentPage === routeConstant.RETROFIT_DASHBOARD ||
    currentPage === routeConstant.DUPS_DASHBOARD
      ? true
      : false;

  const serialNumberShow =
    (currentPage === routeConstant.HOME) |
    (currentPage === routeConstant.DASHBOARD) |
    (currentPage === routeConstant.DEVICE_CONTROL) |
    (currentPage === routeConstant.PLUG_TRENDS) |
    (currentPage === routeConstant.USER_DEVICE_LIST) |
    (currentPage === routeConstant.USER_MANAGEMENT) |
    (currentPage === routeConstant.USER_MANAGEMENT_CREATE) |
    (currentPage === routeConstant.USER_DETAILS) |
    (currentPage === routeConstant.STATUS_DEVICE_LIST) |
    (currentPage === routeConstant.FAN_DASHBOARD) |
    (currentPage === routeConstant.FAN_USER_DEVICE_LIST) |
    (currentPage === routeConstant.NOTIFY_CONFIGURATION) |
    (currentPage === routeConstant.WATER_HEATER_DASHBOARD) |
    (currentPage === routeConstant.WATER_HEATER_USER_DEVICE_LIST) |
    (currentPage === routeConstant.STABILIZER_DASHBOARD) |
    (currentPage === routeConstant.STABILIZER_USER_DEVICE_LIST) |
    (currentPage === routeConstant.RETROFIT_DASHBOARD) |
    (currentPage === routeConstant.RETROFIT_USER_DEVICE_LIST)|
    (currentPage === routeConstant.DUPS_DASHBOARD)|
    (currentPage === routeConstant.DUPS_USER_DEVICE_LIST)
      ? false
      : true;
  const navbarserialmnuber = localStorage.getItem("serialNo_NavBar");

  const [phoneNumber, setPhoneNumber] = useState();
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(true);
  const [phoneNumberRequired, setPhoneNumberRequired] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const categoryId = localStorage.getItem("dashboard_Id");
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [statusCode, setStatusCode] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    setPhoneNumber("");
    setPhoneNumberRequired(false);
    getUserProfieDetail();
  }, [currentPage]);
  // validation for phone number search
  const validatePhoneNumber = (number) => {
    if (number === "") {
      setInvalidPhoneNumber(true);
      setPhoneNumberRequired(true);
    } else if (!validateMobileNumber(number)) {
      setInvalidPhoneNumber(true);
      setPhoneNumberRequired(true);
    } else {
      setInvalidPhoneNumber(false);
      setPhoneNumberRequired(false);
    }
  };
  const onClickPhoneNumberSearch = (phoneNumber) => {
    if (phoneNumber === "") {
      setInvalidPhoneNumber(true);
      setPhoneNumberRequired(true);
    } else {
      if (invalidPhoneNumber) {
      } else {
        getPhoneNumberSearch(phoneNumber);
      }
    }
  };
  // api for phone number search
  const getPhoneNumberSearch = async (number) => {
    const data = {
      categoryId: categoryId,
      mobile: number,
    };
    setIsLoading(true);
    try {
      const response = await phoneNumberSearch(data);

      if (response.data.status == 200) {
        if (categoryId == 3) {
          navigate(
            {
              pathname: routeConstant.FAN_USER_DEVICE_LIST,

              search: "?fan=Fan",
            },
            {
              state: {
                result: response?.data?.data?.result,
                phoneNumber: phoneNumber,
              },
            }
          );
        } else if (categoryId == 1) {
          navigate(
            {
              pathname: routeConstant.WATER_HEATER_USER_DEVICE_LIST,

              search: "?waterheater=waterheater",
            },
            {
              state: {
                result: response?.data?.data?.result,
                phoneNumber: phoneNumber,
              },
            }
          );
        } else if (categoryId == 5) {
          navigate(
            {
              pathname: routeConstant.STABILIZER_USER_DEVICE_LIST,

              search: "?stabilizer=stabilizer",
            },
            {
              state: {
                result: response?.data?.data?.result,
                phoneNumber: phoneNumber,
              },
            }
          );
        } else if (categoryId == 7) {
   
          navigate(
            {
              pathname: routeConstant.RETROFIT_USER_DEVICE_LIST,

              search: "?retrofit=retrofit",
            },
            {
              state: {
                result: response?.data?.data?.result,
                phoneNumber: phoneNumber,
              },
            }
          );
        }  else if (categoryId == 2) {
          
          navigate(
            {
              pathname: routeConstant.DUPS_USER_DEVICE_LIST,

              search: "?Inverter=Inverter",
            },
            {
              state: {
                result: response?.data?.data?.result,
                phoneNumber: phoneNumber,
              },
            }
          );
        } 
        
        else {
          navigate(
            {
              pathname: routeConstant.USER_DEVICE_LIST,

              search: "?plug=Plug",
            },
            {
              state: {
                result: response?.data?.data?.result,
                phoneNumber: phoneNumber,
              },
            }
          );
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.status == 400) {
        setShowErrorAlert(true);
        setShowErrorMessage(error?.response?.data?.data?.error?.message);
      } else if (error?.response?.data?.status == 401) {
        setShowErrorAlert(true);
        setShowErrorMessage(error?.response?.data?.data?.error?.message);
      }
    }
  };
  const submitOnClick = () => {
    if (phoneNumber == "") {
      setInvalidPhoneNumber(true);
      setPhoneNumberRequired(true);
    } else {
      if (invalidPhoneNumber) {
      } else {
        getPhoneNumberSearch(phoneNumber);
      }
    }
  };

  const logoutClick = () => {
    setShowLogoutPopup(true);
  };
  const cancelLogout = () => {
    setShowLogoutPopup(false);
  };
  // API for logout
  const logout = async () => {
    setShowLogoutPopup(false);
    setIsLoading(true);
    try {
      const response = await logoutAPI();
      deleteCookie("x-access-token");
      if (response?.data?.status === 200) {
        navigate(routeConstant.LOGIN);
      }
    } catch (error) {
      deleteCookie("x-access-token");
      if (error?.response?.data?.status === 400) {
        setShowErrorAlert(true);
        setShowErrorMessage(error?.response?.data.message);
        navigate(routeConstant.LOGIN);
      } else if (error?.response?.data?.status === 401) {
        setShowErrorAlert(true);
        setShowErrorMessage(error?.response?.data.message);
        navigate(routeConstant.LOGIN);
      } else {
        setShowErrorAlert(true);
        setShowErrorMessage(error?.response?.data.message);
      }
    }
    setIsLoading(false);
  };
  //  show profile detail
  const getUserProfieDetail = async () => {
    setIsLoading(true);
    try {
      const response = await getprofileDetail();
      if (response.data.status == 200) {
        setUserName(response.data.data.adminUser.name);
        setRoleName(response.data.data.adminUser.role.roleName);
      }
      setIsLoading(false);
    } catch (error) {
      setStatusCode(error?.response?.data?.status);
      setIsLoading(false);
      if (
        error?.response?.data?.status == 400 ||
        error?.response?.data?.status == 401
      ) {
        setShowErrorAlert(true);
        setShowErrorMessage(error?.response?.data?.data?.error?.message);
        deleteCookie("x-access-token");
      }
    }
  };
  const navigateToLogin = () => {
    logout();
  };
  return (
    <div className="topbar">
      {isLoading ? <Loader /> : ""}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-light-tertiary"
        light
        fixed="top"
      >
        <Container fluid className="topbar-container ">
          <Navbar.Brand
            onClick={() => sessionStorage.setItem("productName", "Plug")}
          >
            <img src={Logo} alt="logo" />
            {showBreadcrumb ? (
              currentPage == routeConstant.DEVICE_LOGS ? (
                <BreadCrumb items={BreadCrumbItems.BreadCrumbItemWithDevice} />
              ) : currentPage == routeConstant.PLUG_DASHBOARD ? (
                <BreadCrumb items={BreadCrumbItems.BreadCrumbItemPlug} />
              ) : currentPage == routeConstant.USER_DETAILS ? ( //user details
                <BreadCrumb items={BreadCrumbItems.BreadCrumbItemUser} />
              ) : currentPage == routeConstant.USER_MANAGEMENT_CREATE ? ( //user edit
                <BreadCrumb items={BreadCrumbItems.BreadCrumbItemUser} />
              ) : currentPage == routeConstant.STATUS_DEVICE_LIST ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemDeviceStatus}
                />
              ) : currentPage == routeConstant.FAN_DASHBOARD ? (
                <BreadCrumb items={BreadCrumbItems.BreadCrumbItemWithFan} />
              ) : currentPage == routeConstant.FAN_DEVICE_DATA ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleFan}
                />
              ) : currentPage == routeConstant.FAN_USER_DEVICE_LIST ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleFan}
                />
              ) : currentPage == routeConstant.WATER_HEATER_DASHBOARD ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithWaterheater}
                />
              ) : currentPage == routeConstant.WATER_HEATER_DEVICE_DATA ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleWaterheater}
                />
              ) : currentPage == routeConstant.WATER_HEATER_USER_DEVICE_LIST ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleWaterheater}
                />
              ) : currentPage == routeConstant.STABILIZER_DASHBOARD ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithStabilizer}
                />
              ) : currentPage == routeConstant.STABILIZER_DEVICE_DATA ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleStabilizer}
                />
              ) : currentPage == routeConstant.STABILIZER_USER_DEVICE_LIST ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleStabilizer}
                />
              ) : currentPage == routeConstant.RETROFIT_DASHBOARD ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithRetrofit}
                />
              ) : currentPage == routeConstant.RETROFIT_DEVICE_DATA ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleRetrofit}
                />
              ) : currentPage == routeConstant.RETROFIT_USER_DEVICE_LIST ? (
                <BreadCrumb
                  items={BreadCrumbItems.BreadCrumbItemWithSingleRetrofit}
                />
              ) :  currentPage == routeConstant.DUPS_DASHBOARD ?(
                <BreadCrumb
                items={BreadCrumbItems.BreadCrumbItemWithDups}
              />
              ) :currentPage == routeConstant.DUPS_DEVICE_DATA ?(
                <BreadCrumb
                items={BreadCrumbItems.BreadCrumbItemWithSingleDups}
              />
              ) :currentPage == routeConstant.DUPS_USER_DEVICE_LIST ? (
                <BreadCrumb
                items={BreadCrumbItems.BreadCrumbItemWithSingleDups}
              />
              )
              
              
              
             : (
                <BreadCrumb items={BreadCrumbItems.BreadCrumbItem} />
              )
              
            ) : null}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav
              className={
                searchByPhoneNumber ? "phone-number-row" : "serial-number-row"
              }
            >
              {/* <Nav.Link className="serial-number-row"> */}

              {/*search devices with phone number */}
              {searchByPhoneNumber ? (
                <MDBCol md={7} >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onClickPhoneNumberSearch(phoneNumber);
                    }}
                  >
                    <InputComponent
                      customImageClass={"search-button"}
                      roundedButton={true}
                      image={Search}
                      imageOnClick={submitOnClick}
                      placeholder={"Search by phone number"}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        validatePhoneNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      type="number"
                      customClass={
                        phoneNumberRequired
                          ? "error-input-field"
                          : "search-input-field"
                      }
                    />
                  </form>
                </MDBCol>
              ) : (
                ""
              )}
              {serialNumberShow ? (
                // <div className="serial-number ">
                <MDBCol md={5} className="serial-number ">
                  <DisabledBoxComponent
                    text={contentData.topbar.serialNumber}
                    value={navbarserialmnuber}
                  />
                </MDBCol>
              ) : (
                //  </div>
                ""
              )}

              {/* </Nav.Link> */}
              <div>
                <NavDropdown
                  title={
                    <>
                      <div className="profile-box">
                        <div className="profile-image-box me-3">
                          <span className="user-status"></span>
                          <div className="profile-icon">
                            <img
                              src={Userimage}
                              alt="User-image"
                              width="100%"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="username">{userName}</div>
                          <div className="user-role">{roleName}</div>
                        </div>
                      </div>
                    </>
                  }
                >
                  <NavDropdown.Item onClick={logoutClick}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* ---------------------------------------------------------------------------- */}
      {/* error alert */}
      <Alertpopup
        modalType="successForm"
        image={Error}
        setShowModal={setShowErrorAlert}
        showModal={showErrorAlert}
        titleText="Error"
        DetailText={showErrorMessage}
        alertButtonText={statusCode == 401 ? "Login" : "Continue"}
        onclickModalContinue={() => {
          statusCode == 401 ? navigateToLogin() : setShowErrorAlert(false);
        }}
      ></Alertpopup>
      {/* logout   */}
      <Alertpopup
        image={logoutImage}
        setShowModal={setShowLogoutPopup}
        showModal={showLogoutPopup}
        titleText="Are you sure?"
        DetailText={"Are you sure you want to logout?"}
        buttonText={"Logout"}
        onClick={logout}
        onclickModalCancel={cancelLogout}
      ></Alertpopup>
    </div>
  );
};

export default Topbar;
