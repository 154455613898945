import React, { useContext, useState } from "react";
import { DeviceConnectivityContext } from "../DeviceConnectivityView";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import downloadDataImage from "../../../assets/images/exportGraph.png";
import "./deviceConnectivity.css";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import MixedChart from "../../../components/ChartComponent/ChartComponent";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import infoIcon from "../../../assets/images/icons/info-button.svg";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
const DeviceConnectivity = () => {
  const {
    downloadFileHandler,
    totalConnectivityStatus,
    graphData,
    showLoader,
    setGraphDate,
    startDate,
    endDate,
    setErrorModal,
    errorModalMessage,
    errorModal,
    showGraphInfo,setShowGraphInfo,
    InfomationModalCancel,
    showGraphInfoamtion
  } = useContext(DeviceConnectivityContext);
 let currentDate = new Date();
 const date = new Date(currentDate.setDate(currentDate.getDate() - 1));
  return (
    <div className="device-connectivity-main">
      {showLoader ? <Loader /> : ""}
      {/* heading */}
      <div className=" device-heading-row">
        <div className="device-data-tilte"> Device Connectivity
    

        </div>
        <div>
          <ButtonComponent
            text={"Download Data"}
            coloredButton={true}
            buttonImage={downloadDataImage}
            customClass={"download-data"}
            onClick={downloadFileHandler}
          />
        </div>
      </div>

      <MDBRow>
        <ThemeCard
          id={"deviceConnectivityID"}
          customClass={"device-connectivity-card-title"}
        >
          {/* themecard top row */}
          <div className="mb-4 device-connectivity-status">
            <div className="connectivity-status">Connectivity Status
            <div
                className="information-icon"
                 onClick={showGraphInfoamtion}
              >
                <img src={infoIcon} />
              </div>
            </div>
            <div>
              <div className="status-text">Total Online</div>
              <div className="status-hours">
                {totalConnectivityStatus.totalOnline} Hours
              </div>
            </div>
            <div>
              <div className="status-text">Total Offline</div>
              <div className="status-hours">
                {totalConnectivityStatus.totalOffline} Hours
              </div>
            </div>
            <div>
              <div className="status-text">Data Unavailable</div>
              <div className="status-hours">
                {totalConnectivityStatus.totalError} Hours
              </div>
            </div>
            <div className="date-picker">
              <DateRangePicker
                getSelectedDates={(startDate, endDate) => {
                  setGraphDate(startDate, endDate);
                }}
                dateRange={6}
                maximumDate={date}
                intialStartDate={startDate}
                initialEndDate={endDate}
              />
            </div>
          </div>

          <MDBRow>
            <div className="device-connectivity-chart">
              <MixedChart
                data={graphData}
                stepSize={6}
                legendShow={true}
                customLegendClass={"legend-device-connectivity"}
              />
              <span className="yaxis-hours-label">Hours</span>
            </div>
          </MDBRow>
        </ThemeCard>
      </MDBRow>
      {/* api error alert popup */}
      <Alertpopup
        showModal={errorModal}
        setShowModal={setErrorModal}
        modalType={true}
        titleText={"Error"}
        DetailText={errorModalMessage}
        image={Error}
        alertButtonText={"Close"}
      ></Alertpopup>
          <div className="badge-modal">
        <ModalComponent
          showModal={showGraphInfo}
          setShowModal={setShowGraphInfo}
          cancelButtonText={"Cancel"}
          onClickCancel={InfomationModalCancel}
          title={"Connectivity Graph"}
          customWidth="command-badge-modal-width"
        >
          <div className="calculation-description">
            Graph shows the online and offline status of the device.
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};

export default DeviceConnectivity;
