import React, { useContext } from "react";
import { GlobalStateContext } from "../store/store";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import RouteConstants from "../constants/routeConstants.json";

const RouteProtection = () => {
  const { state } = useContext(GlobalStateContext);

  const userRoleId = state.UserRoleId;
  let loadComponent = false;
  const location = useLocation();
  const pathname = location.pathname;
  const route = RouteConstants.PAGE_NOT_FOUND;

  const superAdmin = 1;
  const RnD = 2;
  const strategyAndService = 3;

  const routeWithAccessList = [
    {
      route: RouteConstants.DASHBOARD,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.DEVICE_CONTROL,
      access: ["admin", "RnD"],
    },
    {
      route: RouteConstants.PLUG_TRENDS,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.USER_MANAGEMENT,
      access: ["admin"],
    },
    {
      route: RouteConstants.USER_DETAILS,
      access: ["admin"],
    },
    {
      route: RouteConstants.USER_MANAGEMENT_CREATE,
      access: ["admin"],
    },
    {
      route: RouteConstants.PLUG_DASHBOARD,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.DEVICE_DATA,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.DEVICE_ALERT,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.DEVICE_CONNECTIVITY,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.PLUG_POWER_STATUS,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.SEND_COMMAND,
      access: ["admin", "RnD"],
    },
    {
      route: RouteConstants.DEVICE_LOGS,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.STATUS_DEVICE_LIST,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.USER_DEVICE_LIST,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.FAN_DASHBOARD,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.FAN_DEVICE_DATA,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.FAN_USER_DEVICE_LIST,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route: RouteConstants.WATER_HEATER_DASHBOARD,
      access: ["admin", "RnD", "strategyAndService"],
     
    },
    { route: RouteConstants.NOTIFY_CONFIGURATION,
      access: ["admin", "RnD", "strategyAndService"],
    },
    { route: RouteConstants.WATER_HEATER_DEVICE_DATA,
      access: ["admin", "RnD", "strategyAndService"],
    },
    { route: RouteConstants.WATER_HEATER_USER_DEVICE_LIST,
      access: ["admin", "RnD", "strategyAndService"],
    },
    { route: RouteConstants.STABILIZER_DASHBOARD,
      access: ["admin", "RnD", "strategyAndService"],
    },
    { route: RouteConstants.STABILIZER_DEVICE_DATA,
      access: ["admin", "RnD", "strategyAndService"],
    },
    { route: RouteConstants.STABILIZER_USER_DEVICE_LIST,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route : RouteConstants.RETROFIT_DASHBOARD,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route : RouteConstants.RETROFIT_DEVICE_DATA,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route : RouteConstants.RETROFIT_USER_DEVICE_LIST,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route : RouteConstants.DUPS_DASHBOARD,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route : RouteConstants.DUPS_DEVICE_DATA,
      access: ["admin", "RnD", "strategyAndService"],
    },
    {
      route : RouteConstants.DUPS_USER_DEVICE_LIST,
      access: ["admin", "RnD", "strategyAndService"],
    }
  ];

  if (userRoleId === superAdmin) {
    loadComponent = true;
  } else if (userRoleId === RnD) {
    const filteredMenuList = routeWithAccessList.filter(
      (menuList) => menuList.route === pathname
    );
    if (filteredMenuList[0]?.access.includes("RnD")) {
      loadComponent = true;
    } else {
      loadComponent = false;
    }
  } else if (userRoleId === strategyAndService) {
    const filteredMenuList = routeWithAccessList.filter(
      (menuList) => menuList.route === pathname
    );

    if (filteredMenuList[0]?.access.includes("strategyAndService")) {
      loadComponent = true;
    } else {
      loadComponent = false;
    }
  }

  return (
    <>
      {loadComponent ? (
        <Outlet />
      ) : (
        <Navigate to={route} state={{ from: location }} replace />
      )}
    </>
  );
};

export default RouteProtection;
