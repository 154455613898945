import React from "react";
import "./homePageCard.css";
import image from "../../assets/images/outerCorner.png";

const HomePageCard = ({
  categoryName,
  count,
  cardColur,
  categoryImage,
  onClick,
}) => {
  return (
    <div
      className="card-component"
      style={{ background: cardColur }}
      onClick={onClick}
    >
      <img src={image} alt="background" />
      <div className="d-flex inner-container">
        <div className="content-container">
          <p className="count">{count}</p>
          <p className="text">{categoryName}</p>
        </div>
        <div>
          {categoryImage ? <img src={categoryImage} alt="image" /> : ""}
        </div>
      </div>
    </div>
  );
};

export default HomePageCard;
