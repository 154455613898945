import React from "react";
import { Forgotpassword } from "./views/Forgotpassword";
import { ForgotPasswordContextProvider } from "./FrogotPasswordView";

const ForgotPasswordPage = () => {
  return (
    <ForgotPasswordContextProvider>
      <Forgotpassword />
    </ForgotPasswordContextProvider>
  );
};

export default ForgotPasswordPage;
