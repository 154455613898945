import React from "react";
import { HomePageContextProvider } from "./HomePageView";
import HomePage from "./views/HomePage";

const Home = () => {
  return (
    <HomePageContextProvider>
      <HomePage />
    </HomePageContextProvider>
  );
};

export default Home;
