import React, { useContext } from "react";
import { PlugPowerContext } from "../PlugPowerView";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import { MDBRow } from "mdb-react-ui-kit";
import downloadDataImage from "../../../assets/images/exportGraph.png";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import MixedChart from "../../../components/ChartComponent/ChartComponent";
import "./plugPower.css";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import contentFile from "../../../constants/ContentFile.json";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import Error from "../../../assets/images/icons/Error.svg";
import infoIcon from "../../../assets/images/icons/info-button.svg";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
const pagecontents = contentFile.powerStatus;
const PlugPower = () => {
  const {
    data1,
    downloadFileHandler,
    powerTotalData,
    isLoading,
    selectedDate,
    setErrorModal,
    errorModalMessage,
    errorModal,
    startDate,
    endDate,
    showGraphInfo,setShowGraphInfo,
    InfomationModalCancel,
    showGraphInfoamtion
  } = useContext(PlugPowerContext);
  let currentDate=new Date()
  const date = new Date(currentDate.setDate(currentDate.getDate() - 1));
  return (
    <div className="plug-power-status-main">
      {/* heading */}
      <div className=" status-heading-row">
        <div className="status-data-tilte">{pagecontents.onOffStatus}</div>
        <div>
          <ButtonComponent
            text={"Download Data"}
            coloredButton={true}
            buttonImage={downloadDataImage}
            customClass={"download-data"}
            onClick={downloadFileHandler}
          />
        </div>
      </div>

      <MDBRow>
        {isLoading ? (
          <Loader />
        ) : (
          <ThemeCard
            id={"deviceConnectivityID"}
            customClass={"power-status-card-title"}
          >
            {/* themecard top row */}
            <div className="mb-4 power-status-graph-section">
              <div className="power-status-heading">
                {pagecontents.onOffStatus}
                <div
                className="information-icon"
                 onClick={showGraphInfoamtion}
              >
                <img src={infoIcon} />
              </div>
              </div>
              <div>
                <div className="status-text">{pagecontents.powerOn}</div>
                <div className="status-hours">
                  {powerTotalData.totalOnline} Hours
                </div>
              </div>
              <div>
                <div className="status-text">{pagecontents.powerOff}</div>
                <div className="status-hours">
                  {powerTotalData.totalOffline} Hours
                </div>
              </div>
              <div>
                <div className="status-text">
                  {pagecontents.dataUnavailable}
                </div>
                <div className="status-hours">
                  {powerTotalData.totalError} Hours
                </div>
              </div>
              <div className="date-picker">
                <DateRangePicker
                  maximumDate={date}
                  intialStartDate={startDate}
                  initialEndDate={endDate}
                  getSelectedDates={(startDate, endDate) => {
                    selectedDate(startDate, endDate);
                  }}
                  dateRange={6}
                />
              </div>
            </div>

            <MDBRow>
              <div className="power-status-chart">
                <MixedChart
                  data={data1}
                  stepSize={6}
                  legendShow={true}
                  customLegendClass={"legend-device-power"}
                />
                <span className="yaxis-hours-label">{pagecontents.hours}</span>
              </div>
            </MDBRow>
          </ThemeCard>
        )}
      </MDBRow>
      {/* api error alert popup */}
      <Alertpopup
        showModal={errorModal}
        setShowModal={setErrorModal}
        modalType={true}
        titleText={"Error"}
        DetailText={errorModalMessage}
        image={Error}
        alertButtonText={"Close"}
      ></Alertpopup>
         <div className="badge-modal">
        <ModalComponent
          showModal={showGraphInfo}
          setShowModal={setShowGraphInfo}
          cancelButtonText={"Cancel"}
          onClickCancel={InfomationModalCancel}
          title={"Power Graph"}
          customWidth="command-badge-modal-width"
        >
          <div className="calculation-description">
            Graph shows the on off status of switch.
          </div> 
        </ModalComponent>
      </div>
    </div>
  );
};

export default PlugPower;
