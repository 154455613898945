import React from "react";
import { SendCommandContextProvider } from "./SendCommandView";
import SendCommandPage from "./views/SendCommand";
const SendCommand = () => {
  return (
    <SendCommandContextProvider>
      <SendCommandPage />
    </SendCommandContextProvider>
  );
};
export default SendCommand
