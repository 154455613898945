import React from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
} from "mdb-react-ui-kit";
import "./breadCrumb.css";
import RightArrow from "../../assets/images/icons/light_right.svg";
const BreadCrumb = ({ items }) => {

  return (
    <MDBContainer fluid>
      <MDBBreadcrumb className="breadcrumb-item ">
        <MDBBreadcrumbItem className="d-flex">
          {items.map((item, index) => (
            <a href={item.url} className="d-flex align-items-center">
              {index != 0 ? (
                <span>
                  <img src={RightArrow} className="right-arrow" />
                </span>
              ) : null}
              <img
                src={require(`../../assets/images/icons/${item.icon}`)}
                className="icon-navbar"
                width="30"
                height="30"
                alt=""
              />
              <span className="breadcrumb-label">{item.label}</span>
              {/* style the label */}
            </a>
          ))}
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
    </MDBContainer>
  );
};
export default BreadCrumb;
