import React, { createContext, useEffect, useState } from "react";
import ContentFile from "../../constants/ContentFile.json";
import {
  blankSpaceCheck,
  checkCommaSeparatedNumber,
} from "../../utils/service";
import DummyContent from "../../constants/DummyContent.json";
import {
  sendDeviceCommand,
  sendCustomDeviceCommand,
  getSendCommandLogs,
  getListOfFirmware,
  getStateListData,
  locationWiseSendCommand,
  locationWiseCustomSendCommand,
} from "../../apis/Api";
export const DeviceControlContext = createContext({});
export const DeviceControlContextProvider = ({ children }) => {
  const pageContent = ContentFile.deviceControlPage; // page static contents
  const pageDummyContent = DummyContent.deviceControl; // page dummy content
  const colors = ContentFile.colorsList; // color list for location

  const [tabData, setTabData] = useState(pageContent?.tabContent); // tab data storing state
  const [serialNumberAddingModal, setSerialNumberAddingModal] = useState(false); // state for serial number adding modal show and hide
  const [serialNumber, setSerialNumber] = useState(); // state for serial number input
  const [InputValidationError, setInputvalidationError] = useState(false); // state to check input validation
  const [selectDeviceTabId, setSelectDeviceTabId] = useState(1); // selected by serial number and location tab id
  const [serialNumberErrorMsg, setSerialNumberErrorMsg] = useState(""); // state to show serial number input error message
  const [selectedLocation, setSelectedLocation] = useState([]); // sate to store selected location
  const [locationName, setLocationName] = useState(); //state to store selected location name
  const [locationCount, setLocationCount] = useState(); // number of location selected
  const [locationSelectedText, setLocationSelectedText] = useState(); // state to change location to locations when more than one location selected
  const [locationDisable, setLocationDisable] = useState(); // state to enable and disable location
  const [locationErrorvalidationMsg, setLocationErrorValidationMsg] =
    useState(); // state for location validation message
  const [timeIntervalModal, setTimeIntervalModal] = useState(false); // state to show and hide time interval modal
  const [tableBadgeModal, setTableBadgeModal] = useState(false); // table target device badge modal
  const [tableBadgeRowId, setTableBadgeRowId] = useState(); // state fo table badge clicked
  const [commandDetailShowModal, setCommandDetailShowModal] = useState(false); // table target device badge modal

  const [upgradeFirmwareModal, setUpgradeFirmwareModal] = useState(false); //state for upgrade firmware modal
  const [selectVersion, setSelectVersion] = useState(""); // state for version select on frimware dropdown
  const [startCommadConfirmModal, setStartCommadConfirmModal] = useState(false); // state for start command confirmation modal
  const [stopCommadConfrimModal, setStopCommadConfirmModal] = useState(false); // state for stop command confirmation modal
  const [commandInput, setCommandInput] = useState(""); // state to store command input
  const [commandInputValidationError, setCommadInputValidationError] =
    useState(false); // commad input error validation state
  const [successModal, setSuccessModal] = useState(false); // success modal show and hide
  const [sucessModalText, setSuccessModalText] = useState("");
  const [checked, setChecked] = useState(false); // toggle device selction
  const [serialNoExistError, setSerialNoExistError] = useState(false);
  const [addSerialNumberButtonShow, setAddSerialNumberButtonShow] =
    useState(true);
  const [serialNoPrevValueLength, setSerialNoPrevValueLength] = useState("");

  const [
    updateFirwareDropdownValidationError,
    setUpdateFirwareDropdownValidationError,
  ] = useState(false); // upgrade firmware dropdown error validation
  const [isLoading, setIsLoading] = useState(false);
  const [serialNumberQuickSendData, setSerialNumberQuickSendData] = useState({
    start: "",
    stop: "",
    timeInterval: "",
    upgradeFirmware: "",
    enable: false,
  });
  const serialnumberArray = tabData[0].tabBody.toString()?.split(",");
  const [tempSerailNumber, setTempSerailNumber] = useState([]);
  const [invalidVersion, setInvalidVersion] = useState(false);
  const [activeCommand, setSelectionActiveCommand] = useState("");
  const [timeInterval, setTimeInterval] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [commandLogData, setCommandLogData] = useState();
  const [pageState, setpageState] = useState(null);
  const [showError, setShowError] = useState(false);
  const [addedSerailNumberSet, setAddedSerialNumberSet] = useState([]);
  const [version, setVersion] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [stateName, setStateName] = useState("");
  const [selecteStateId, setSelectedStateId] = useState(0);
  const [showDistrictList, setShowDistrictList] = useState(false);
  const [locationPageState, setLocationPageState] = useState(null);
  const categoryId = localStorage.getItem("dashboard_Id");
  useEffect(() => {
    setTempSerailNumber([]);
    tabData.map((item) => {
      item.tabBody = [];
    });
  }, []);
  useEffect(() => {
    getCommandLogs(pageState); // api call for get command logs
    // getLocationCommandLogs(locationPageState)
    getFirmwareList();
    getStateList();
  }, []);
  useEffect(() => {
    tabData?.map((item) => {
      if (selectDeviceTabId === item?.tabId) {
        const tabBodyLength = item?.tabBody?.length;
        if (tabBodyLength === 0) {
          setCommadInputValidationError(false);
          setSerialNumberQuickSendData({
            ...serialNumberQuickSendData,
            enable: true,
          });
        } else {
          setSerialNumberQuickSendData({
            ...serialNumberQuickSendData,
            enable: false,
          });
        }
      }
    });
  }, [tabData]);

  // function to active selected tab
  const handleClickActiveTab = (id) => {
    if (id === 1) {
      getCommandLogs(pageState);
      setLocationPageState(null);
    } else {
      getLocationCommandLogs(locationPageState);
      setpageState(null);
    }
    const ActiveTabArray = tabData?.map((item) => {
      const activeStatus = item?.tabId === id ? true : false;
      let updatedTabBody = []; // update tab body on changing tab
      if (activeStatus == true) {
        updatedTabBody = item.tabBody;
      } else {
        updatedTabBody = [];
        setSelectedLocation("");
        setLocationCount("");
        setLocationSelectedText("");
        setTempSerailNumber([]);
      }
      return { ...item, active: activeStatus, tabBody: updatedTabBody };
    });
    setSelectDeviceTabId(id);

    setTabData(ActiveTabArray);
    if (tabData[0].tabBody?.length < 10) {
      setAddSerialNumberButtonShow(true);
    }
  };

  // function to remove selected serial number
  const removeSelectedSerialNumber = (id, index) => {
    const selectedItemRemovedArray = tabData?.map((item) => {
      if (id === item.tabId) {
        item?.tabBody.splice(index, 1);
        setSerialNumberQuickSendData({
          ...serialNumberQuickSendData,
          start: "",
          stop: "",
        });
        setTempSerailNumber(item?.tabBody);
        return { ...item, tabBody: item?.tabBody };
      } else {
        return item;
      }
    });
    if (selectedItemRemovedArray[0].tabBody?.length < 10) {
      setAddSerialNumberButtonShow(true);
    }
    setTabData(selectedItemRemovedArray);
  };

  //function to show serial number adding modal
  const showSerialNumberAddingModal = (id) => {
    setSerialNumberAddingModal(true);
  };

  //function to close serial number adding modal
  const closeSerialNumberAddingModal = () => {
    setSerialNumberAddingModal(false);
    setInputvalidationError(false);
    setSerialNumberErrorMsg("");
    setAddSerialNumberButtonShow(true);
    setSerialNumber("");
  };

  // serial number input onChnage function
  const onChnageSerialNumberInput = (e) => {
    const inputValue = e.target.value;
    if (serialNoExistError && inputValue?.length > serialNoPrevValueLength) {
      return false;
    }
    setSerialNoPrevValueLength(inputValue?.length);
    setSerialNoExistError(false);
    setSerialNumber(inputValue);
    validateserialNumberinput(inputValue, "onchange");
  };

  // serial number input validation
  const validateserialNumberinput = (value, mode) => {
    let validationErrorStatus = false;
    if (value === "") {
      setInputvalidationError(true);
      setSerialNumberErrorMsg("");
      validationErrorStatus = true;
      return validationErrorStatus;
    }
    // splitting serial number array based on comma for 16 digits number
    if (value?.includes(",")) {
      let splittedArray = value.split(",");
      for (const item of splittedArray) {
        if (item.length !== 16) {
          setInputvalidationError(true);
          validationErrorStatus = true;
          setSerialNumberErrorMsg("Please enter a valid device serial number");
          return validationErrorStatus;
        } else {
          setInputvalidationError(false);
          validationErrorStatus = false;
          setSerialNumberErrorMsg("");
        }
      }
    } else {
      if (value?.length !== 16) {
        setSerialNumberErrorMsg("Please enter a valid device serial number");
        setInputvalidationError(true);
        validationErrorStatus = true;
        return validationErrorStatus;
      } else {
        setSerialNumberErrorMsg("");
        setInputvalidationError(false);
        validationErrorStatus = false;
      }
    }
    // adding same serial number
    if (serialnumberArray.includes(value) == true) {
      setInputvalidationError(true);
      validationErrorStatus = true;
      setSerialNumberErrorMsg("This serial number is already added");
      return validationErrorStatus;
    }
    // checking if number is separated using morethan one comma
    if (!checkCommaSeparatedNumber(value)) {
      setInputvalidationError(true);
      validationErrorStatus = true; // to don't allow alphabet submit
      return validationErrorStatus;
    }

    // Validation to check how many serial numbers added
    let previousDataLength = 0;
    tabData?.forEach((item) => {
      if (selectDeviceTabId === item?.tabId) {
        previousDataLength = item?.tabBody?.length; // existing serial number array length
      }
    });
    const sliptedInputValue = value?.split(","); // slipt the values from input and return as array
    const arraylength = sliptedInputValue?.length + previousDataLength; // check the length of array
    const serialNumberCount = 10;
    if (arraylength > serialNumberCount) {
      setInputvalidationError(true);
      validationErrorStatus = true;
      setSerialNumberErrorMsg("Only 10 serial numbers allowed");
      return validationErrorStatus;
    } else if (arraylength == serialNumberCount) {
      setAddSerialNumberButtonShow(false);
      validationErrorStatus = false;
      setSerialNumberErrorMsg("");
      setInputvalidationError(false);
    } else {
      setInputvalidationError(false);
      validationErrorStatus = false;
      setSerialNumberErrorMsg("");
    }
    // checking same serial number on seperated by commas
    if (mode === "onchange") {
      if (value.slice(-1) === ",") {
        const inputValuesArray1 = value.split(","); //spliting the values using comma
        const inputValuesArray = inputValuesArray1.slice(
          0,
          inputValuesArray1.length - 1
        ); // getting the value of last element of input array
        const currentValue = inputValuesArray[inputValuesArray.length - 1];
        const serialNumberPrevValuesArray1 = inputValuesArray.slice(
          0,
          inputValuesArray.length - 1
        );
        const newArray = tabData?.map((item) => {
          if (selectDeviceTabId === item?.tabId) {
            return item?.tabBody;
          }
        });
        let combinedArray = newArray[0];
        if (serialNumberPrevValuesArray1?.length) {
          combinedArray = [...newArray[0], ...serialNumberPrevValuesArray1];
        }
        if (combinedArray.includes(currentValue)) {
          setSerialNoExistError(true);
          setInputvalidationError(true);
          validationErrorStatus = true;
          setSerialNumberErrorMsg("This serial number is already added");
        }
        if (combinedArray.length <= 9) {
          setAddSerialNumberButtonShow(true);
        }
      }
    } else if (mode === "onsubmit") {
      // check exisiting serial number on submiting
      const inputValuesArray = value?.split(",");

      const currentValue = inputValuesArray[inputValuesArray.length - 1];
      const serialNumberPrevValuesArray1 = inputValuesArray.slice(
        0,
        inputValuesArray.length - 1
      );
      const newArray = tabData?.map((item) => {
        if (selectDeviceTabId === item?.tabId) {
          return item?.tabBody;
        }
      });

      let combinedArray = newArray[0];
      if (serialNumberPrevValuesArray1?.length) {
        combinedArray = [...newArray[0], ...serialNumberPrevValuesArray1];
      }

      if (combinedArray.includes(currentValue)) {
        setSerialNoExistError(true);
        setInputvalidationError(true);
        validationErrorStatus = true;
        setSerialNumberErrorMsg("This serial number is already added");
      }
      if (combinedArray.length < 9) {
        setAddSerialNumberButtonShow(true);
      }
    }

    return validationErrorStatus;
  };

  // serial number modal submit function
  const onSubmitSerialNumberInput = () => {
    if (!validateserialNumberinput(serialNumber, "onsubmit")) {
      const SplitedSerialNumberArray = serialNumber?.split(","); // split return array as string
      // const convertedArray = SplitedSerialNumberArray.map(Number); // convert that sting array to number
      const tabData1 = [...tabData];
      const serialNumberAddedArray = tabData1?.map((item) => {
        if (selectDeviceTabId === item?.tabId) {
          return {
            ...item,
            tabBody: [...item.tabBody, ...SplitedSerialNumberArray],
          };
        } else {
          return item;
        }
      });
      setTabData(serialNumberAddedArray);
      setSerialNumberAddingModal(false);
      setTempSerailNumber((serialNumberAddedArray) => [
        ...serialNumberAddedArray,
        ...SplitedSerialNumberArray,
      ]);
      setAddedSerialNumberSet(serialNumberAddedArray);
      // setTempSerailNumber(serialNumber)
      setSerialNumber("");
    }
  };

  // api call state list
  const getStateList = async () => {
    setIsLoading(true);
    try {
      const response = await getStateListData();
      const stateArray = response?.data?.data?.map((state) => ({
        id: state.stateId,
        name: state.state,
      }));

      const listOfState = stateArray;

      if (response.data.status == 200) {
        setStateList(listOfState);
        setSelectedState(response.data.data);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
  };
  const [prevStateId, setPrevStateId] = useState("");

  const onStateSelction = (value, tabId) => {
    setLocationCount();
    setSelectedLocation([]);
    tabData?.map((item) => {
      item.tabBody = [];
    });
    const selectedStateID = value["id"];
    setSelectedStateId(selectedStateID);
    setStateName(value?.name);
    // filter based on  district based on state selected
    const stateInfo = selectedState.find(
      (state) => state.stateId === selectedStateID
    );
    setPrevStateId(stateInfo.stateId);

    if (stateInfo) {
      setPrevStateId(stateInfo.stateId);

      const districtListOnState = stateInfo.districts.map((district) => ({
        id: district.districtId,
        name: district.districtName,
      }));
      if (selectedStateID !== prevStateId) {
        tabData[1].tabBody = [];
        // setLocationCount()
        // setSelectedLocation()
        setLocationSelectedText();
        // setTabData(tabData[0].tabBody)
      }
      setShowDistrictList(true);
      setDistrictList(districtListOnState);

      return districtListOnState;
    } else {
      return []; // State with the given stateId not found
    }
  };
  // location dropdown on select funtion
  const onSelectLocation = (value, tabId) => {
    setLocationName(value?.name);
    //  filtering selected value and get selected location length
    let seletedLocationItem;
    if (selectedLocation?.includes(value?.id)) {
      seletedLocationItem = selectedLocation.filter(
        (item) => item !== value?.id
      );
    } else {
      seletedLocationItem = [...selectedLocation, value?.id];
    }
    setSelectedLocation(seletedLocationItem);
    setLocationCount(seletedLocationItem?.length);

    // change text according to number of location selected

    if (seletedLocationItem?.length === 1) {
      setLocationSelectedText("location Selected");
    } else if (seletedLocationItem?.length > 1) {
      setLocationSelectedText("location Selected");
    } else {
      setLocationSelectedText("");
    }

    // Adding selected location to array
    const locationUpdatedArray = tabData?.map((item) => {
      if (tabId === item.tabId) {
        item?.tabBody.push(value?.name);
        return item;
      } else {
        return item;
      }
    });
    setTabData(locationUpdatedArray);
    setAddedSerialNumberSet(locationUpdatedArray);
    // if location count is more that 10 dropdown will be disable
    const locationValidationCount = 10;
    if (seletedLocationItem?.length >= locationValidationCount) {
      setLocationDisable(true);
      setLocationErrorValidationMsg("Only 10 location are allowed to select");
    } else {
      setLocationDisable(false);
      setLocationErrorValidationMsg("");
    }

    // deviceControlDataForLocation(
    //   tabData[1].tabBody
    // );
  };

  // Update location listing array by removing selected item
  const list = districtList;
  const selectedLocationId = selectedLocation;
  const newLocationList = list?.filter((item) => {
    return selectedLocationId.includes(item.id) === false;
  });

  // function to remove selected item
  const removeSelectedLocation = (id, index) => {
    const locationValidationCount = 10;
    const tabData1 = [...tabData];
    const updatedTabData = tabData1?.map((item) => {
      if (id === item.tabId) {
        const updatedLocationArray = item?.tabBody;
        updatedLocationArray.splice(index, 1);
        setSelectedLocation(updatedLocationArray);

        setLocationCount(updatedLocationArray?.length);

        if (updatedLocationArray?.length >= locationValidationCount) {
          setLocationDisable(true);
          setLocationErrorValidationMsg(
            "Only 10 location are allowed to select"
          );
        } else if (updatedLocationArray?.length === 1) {
          setLocationSelectedText("location Selected");
        } else if (updatedLocationArray?.length === 0) {
          setLocationSelectedText();
          setSerialNumberQuickSendData({
            ...serialNumberQuickSendData,
            start: "",
            stop: "",
          });
        } else {
          setLocationDisable(false);
          setLocationErrorValidationMsg("");
        }
        return { ...item, tabBody: updatedLocationArray };
      } else {
        return item;
      }
    });
    setTabData(updatedTabData);
  };

  // show table badge modal
  const showTableModal = (time) => {
    setTableBadgeModal(true);
    setTableBadgeRowId(time);
  };
  // show command details modal
  const showCommandModal = (time) => {
    setCommandDetailShowModal(true);
    setTableBadgeRowId(time);
  };

  // cancel table badge modal
  const badgeModalCancel = () => {
    setTableBadgeModal(false);
    setCommandDetailShowModal(false);
  };

  //onclick show upgrade firmware modal
  const showUpgardeFirmwareModal = (state) => {
    setUpgradeFirmwareModal(true);
    setSelectionActiveCommand(state);
  };
  //onclick cancel upgrade firmware modal
  const cancelupdateFirmwaremodal = () => {
    setUpgradeFirmwareModal(false);
    setUpdateFirwareDropdownValidationError(false);
  };

  // version select onChnage
  const onSelectVersion = (value) => {
    setSelectVersion(value?.name);
    validateFirmwareDropdown(value?.name);
  };

  const validateFirmwareDropdown = (value) => {
    let validationStatus = false;
    if (value === null) {
      setUpdateFirwareDropdownValidationError(true);
      validationStatus = true;
    } else {
      setUpdateFirwareDropdownValidationError(false);
      validationStatus = false;
    }
    return validationStatus;
  };

  // upgrade firmware modal submit
  const updateFirwareSubmit = () => {
    validateFirmwareDropdown(selectVersion);
    if (selectVersion === null) {
      setInvalidVersion(true);
      return true;
    } else {
      setInvalidVersion(false);
      setUpgradeFirmwareModal(false);
      setSelectVersion(null);
      if (tabData[1].tabBody.length === 0) {
        sendDevicControlCommmand();
      } else {
        sendCommmandLocationwise();
      }
      return false;
    }
  };

  // onclick start command show confirmation modal
  const startCommadConfrimationModal = (state) => {
    setSelectionActiveCommand(state);
    setStartCommadConfirmModal(true);
    setSelectionActiveCommand(state);
  };

  // onclick stop command show confirmation modal
  const stopCommadConfrimationModal = (state) => {
    setStopCommadConfirmModal(true);
    setSelectionActiveCommand(state);
  };
  // function to show time interval modal
  const showTimeIntervalModal = (state) => {
    setTimeIntervalModal(true);
    setSelectionActiveCommand(state);
  };

  // on submit Time Interval modal
  const onSubmitTimeInterval = (time) => {
    setTimeInterval(time);
    setTimeIntervalModal(false);
    if (tabData[1].tabBody.length === 0) {
      sendDevicControlCommmand(time);
    } else {
      sendCommmandLocationwise(time);
    }
  };
  // onchange command input
  const onChnageCommadInput = (e) => {
    setCommandInput(e.target.value);
    validateCommadInput(e.target.value);
  };

  // command input validtion
  const validateCommadInput = (value) => {
    let validationStatus = false;
    if (value === "") {
      setCommadInputValidationError(true);
      validationStatus = true;
    } else if (blankSpaceCheck(value)) {
      setCommadInputValidationError(true);
      validationStatus = true;
    } else {
      setCommadInputValidationError(false);
      validationStatus = false;
    }
    return validationStatus;
  };
  // commad input submit function

  const onClickSubmitCommad = () => {
    if (!validateCommadInput(commandInput)) {
      if (tempSerailNumber?.length === 0 && tabData[1].tabBody.length === 0) {
        setShowError(true);
        setShowErrorMessage("Please add  serial number or Location");
        return;
      } else if (tabData[0].tabBody.length > 0) {
        customSendCommand(tempSerailNumber, commandInput);
      } else if (tabData[1].tabBody.length > 0) {
        customSendCommandLocation(tabData[1].tabBody, commandInput);
      }
    }
  };

  // on submit start command modal
  const onSubmmitStartCommand = () => {
    setSerialNumberQuickSendData({
      ...serialNumberQuickSendData,
      start: "start",
      stop: "",
    });
    setStartCommadConfirmModal(false);
    if (tabData[1].tabBody.length === 0) {
      sendDevicControlCommmand();
    } else {
      sendCommmandLocationwise();
    }
  };
  // on submit stop command modal
  const onSubmmitStopCommand = () => {
    setSerialNumberQuickSendData({
      ...serialNumberQuickSendData,
      start: "",
      stop: "stop",
    });
    setStopCommadConfirmModal(false);
    if (tabData[1].tabBody.length === 0) {
      sendDevicControlCommmand();
    } else {
      sendCommmandLocationwise();
    }
  };
  // api call command sending
  const sendDevicControlCommmand = async (time) => {
    const activeTimeInterval = activeCommand === "timeInterval" ? time : "";
    const firmwareVersion = selectVersion !== null ? selectVersion : "";

    const targetList =
      addedSerailNumberSet[0].tabBody !== null
        ? addedSerailNumberSet[0].tabBody
        : addedSerailNumberSet[1].tabBody;
    const data = {
      logType: "deviceControl",
      targetType: "srno",
      target: targetList,
      key: activeCommand,
      interval: activeTimeInterval,
      firmvareVersion: firmwareVersion,
    };
    setIsLoading(true);
    try {
      setIsLoading(false);
      const response = await sendDeviceCommand(data);

      if (response.data.status == 200) {
        getCommandLogs();
        if (activeCommand === "start") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.startMessage);
        } else if (activeCommand === "stop") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.stopMessage);
        } else if (activeCommand === "timeInterval") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.TimeintervalSubmit);
        } else if (activeCommand === "updateFirmware") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.FirmwareSubmit);
        }
      } else if (response.data.status === 500) {
        setIsLoading(false);
        setShowError(true);
        setShowErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // api custom command device contarol
  const customSendCommand = async () => {
    const data = {
      logType: "deviceControl",
      targetType: "srno",
      target: addedSerailNumberSet[0].tabBody,
      command: commandInput,
    };
    setIsLoading(true);
    try {
      const response = await sendCustomDeviceCommand(data);

      setIsLoading(false);
      if (response.data.status == 200) {
        setSuccessModal(true);
        setSuccessModalText(response.data.message);
        getCommandLogs();
        setCommandInput("");
      } else if (response.data.status === 500) {
        setIsLoading(false);
        setShowError(true);
        setShowErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
  };
  // api for command logs
  const getCommandLogs = async (pageState) => {
    setIsLoading(true);
    const data = {
      logType: "deviceControl",
      targetType: "srno",
      srno: tempSerailNumber,
      fetchSize: 10,
      pageState: pageState,
    };
    try {
      const response = await getSendCommandLogs(data);
      setIsLoading(false);
      if (response.data.status === 200) {
        setCommandLogData(response.data.data.rows);
        setpageState(response.data.data.pageState);
      }
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
  };

  // api for command logs location
  const getLocationCommandLogs = async (locationPageState) => {
    setIsLoading(true);
    const data = {
      logType: "deviceControl",
      targetType: "location",
      srno: "",
      fetchSize: 10,
      pageState: locationPageState,
    };
    try {
      const response = await getSendCommandLogs(data);
      setIsLoading(false);
      if (response.data.status === 200) {
        setCommandLogData(response.data.data.rows);
        setLocationPageState(response.data.data.pageState);
      }
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
  };

  // api call firmware list
  const getFirmwareList = async () => {
    setIsLoading(true);
    try {
      const response = await getListOfFirmware();
      if (response.data.status == 200) {
        setVersion(response?.data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
  };
  // api call locationwise send command
  const sendCommmandLocationwise = async (time) => {
    const activeTimeInterval = activeCommand === "timeInterval" ? time : "";
    const firmwareVersion = selectVersion !== null ? selectVersion : "";

    let data = {
      logType: "deviceControl",
      targetType: "location",
      target: tabData[1].tabBody,
      key: activeCommand,
      interval: activeTimeInterval,
      firmvareVersion: firmwareVersion,
      categoryId: categoryId,
    };
    setIsLoading(true);
    try {
      setIsLoading(false);
      const response = await locationWiseSendCommand(data);
      if (response.data.status == 200) {
        getLocationCommandLogs();
        if (activeCommand === "start") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.startMessage);
        } else if (activeCommand === "stop") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.stopMessage);
        } else if (activeCommand === "timeInterval") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.TimeintervalSubmit);
        } else if (activeCommand === "updateFirmware") {
          setSuccessModal(true);
          setSuccessModalText(pageContent.successmodal.FirmwareSubmit);
        }
      } else if (response.data.status === 500) {
        setIsLoading(false);
        setShowError(true);
        setShowErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // api custom command device contarol location wise
  const customSendCommandLocation = async () => {
    const data = {
      logType: "deviceControl",
      targetType: "location",
      target: tabData[1].tabBody,
      command: commandInput,
      categoryId: categoryId, // category id
    };
    setIsLoading(true);
    try {
      const response = await locationWiseCustomSendCommand(data);
      setIsLoading(false);
      if (response.data.status == 200) {
        setSuccessModal(true);
        setSuccessModalText(response.data.message);
        getLocationCommandLogs();
        setCommandInput("");
      } else if (response.data.status === 500) {
        setIsLoading(false);
        setShowError(true);
        setShowErrorMessage(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
  };
  // pagination  for serial number based command logs
  const serialNumberCommandLogPagination = (pageState) => {
    getCommandLogs(pageState);
  };
  // pagination for location based command logs
  const locationCommandLogPagination = (pageState) => {
    getLocationCommandLogs(pageState);
  };
  const ProviderValue = {
    pageContent, // page static contents
    handleClickActiveTab, // function to active tab
    tabData, // state to store tab data
    removeSelectedSerialNumber, // function to remove selected item
    serialNumberAddingModal, // serial number adding modal state
    setSerialNumberAddingModal, // serial number adding modal set state
    showSerialNumberAddingModal, //function to show serial number adding modal
    closeSerialNumberAddingModal, //function to close serial number adding modal
    onChnageSerialNumberInput, // serial number input onChnage fuction
    serialNumber, // serial number input value storing state
    onSubmitSerialNumberInput, // serial number modal submit function
    InputValidationError, // state to checko input validation
    serialNumberErrorMsg, // state to show serial number input error message
    pageDummyContent, // location dummy list
    setSelectedLocation, // set state for selected location
    onSelectLocation, // location dropdown on select funtion
    locationName, // state that store selected location name
    locationCount, // number of location selected
    locationSelectedText, // location selected text
    colors, // color list for location
    newLocationList, // itrated location list
    removeSelectedLocation, // function to remove selected location
    locationDisable, // state to disable location dropdown
    locationErrorvalidationMsg, // state to store location validation message
    timeIntervalModal, // time interval modal state
    setTimeIntervalModal, // time interval modal set state
    showTimeIntervalModal, // function to show time interval modal
    showTableModal, // function to show modal inside table badge
    tableBadgeModal, // table badge modal state
    setTableBadgeModal, // table badge modal set state
    badgeModalCancel, // badge modal cancel function
    tableBadgeRowId, // clicked row id
    showUpgardeFirmwareModal, // function to show upgrade firmware modal
    upgradeFirmwareModal, // upgrade firmware modal state
    setUpgradeFirmwareModal, // upgrade firmware modal set state
    cancelupdateFirmwaremodal, // cancel upgrade firmware modal
    onSelectVersion, // on select function for  version select
    selectVersion, // state that store selected version
    startCommadConfrimationModal, // function to show start command confirmation modal
    startCommadConfirmModal, // start command confirmation modal state
    setStartCommadConfirmModal, // start command confirmation modal setstate
    stopCommadConfrimModal, // stop command confirmation modal state
    setStopCommadConfirmModal, // stop command confirmation modal setstate
    stopCommadConfrimationModal, // function to show stop command  confirmation modal
    onChnageCommadInput, // command input onchange function
    commandInput, // command input state
    commandInputValidationError, // commad input validation error state
    onClickSubmitCommad, //  commad submit button onclick function
    updateFirwareSubmit, // upgrade firmware modal submit function
    updateFirwareDropdownValidationError, // frimware dropdown error validation state
    onSubmmitStartCommand, // onclick start commad inside quick send
    serialNumberQuickSendData,
    onSubmmitStopCommand, // on submit stop command modal
    successModal, // success modal state
    setSuccessModal, // success modal setstate
    sucessModalText, // success modal text
    checked,
    setChecked,
    addSerialNumberButtonShow, //show add serial number button
    onSubmitTimeInterval, // time iterval time
    timeInterval,
    isLoading,
    showErrorAlert,
    showErrorMessage,
    showError,
    setShowError,
    commandLogData,
    serialNumberCommandLogPagination,
    locationCommandLogPagination,
    pageState,
    setCommandDetailShowModal,
    commandDetailShowModal,
    showCommandModal,
    tempSerailNumber, // length of target devices
    version, // list of firmware version
    stateList,
    setStateList,
    districtList,
    locationName,
    onStateSelction,
    stateName,
    setStateName,
    showDistrictList,
    setShowDistrictList,
    setShowErrorMessage,
    selectDeviceTabId,
    locationPageState,
    setLocationPageState,
  };
  return (
    <DeviceControlContext.Provider value={ProviderValue}>
      {children}
    </DeviceControlContext.Provider>
  );
};
