import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import CalendarIcon from "../../assets/images/icons/Calendar.svg";
import CloseIcon from "../../assets/images/icons/Close-icon.svg";

import "./weekAndYearPicker.css";
import { DateRange } from "react-date-range";
import YearPicker from "../YearPickerComponent/YearPicker";

const WeekAndYearPicker = ({
  getSelectedDates,
  dateRange,
  maximumDate,
  intialStartDate,
  initialEndDate,
  showFilteredCalender,
  getYearOfData,
  onRemoveDate, // New callback prop
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  // state to show start and end date inside the input
  const [startDate, setStartDate] = useState(intialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [showRemoveIcon, setShowRemoveIcon] = useState(false); // state for remove icon show and hide
  const [showPlaceholder, setShowPlaceholder] = useState(false); // state for placeholder show and hide
  const [checkStartDate, setCheckStartdate] = useState();
  const [checkEndDate, setCheckEndDate] = useState();
  const [removeDate, setRemoveDate] = useState(false);
  const [showWeek, setShowWeek] = useState(true);
  const [showYear, setShowYear] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [currentDateFormat, setCurrentDateFormat] = useState("WEEKLY");

  // state to set max date and min date
  const [maxDate, setMaxdate] = useState(maximumDate);
  const [minDate, setMinDate] = useState(new Date("1970-01-01"));
  const currentYear = new Date().getFullYear();
  // state for calendar date selection
  const [calendarDate, setCalendarDate] = useState([
    {
      startDate: new Date(intialStartDate),
      endDate: new Date(initialEndDate),
      key: "selection",
    },
  ]);

  // on click calendar checking the dates
  useEffect(() => {
    const startDate = moment(checkStartDate).format("ll");
    const endDate = moment(checkEndDate).format("ll");
    if (checkStartDate != checkEndDate) {
      setEndDate(endDate);
      getSelectedDates(startDate, endDate);
    }
  }, [checkStartDate, checkEndDate]);

  useEffect(() => {
    if (showFilteredCalender) {
      getYearOfData(selectedYear, currentDateFormat);
    }
  }, [selectedYear, currentDateFormat]);
  let currentDate = new Date();
  const previousDay = new Date(currentDate.setDate(currentDate.getDate() - 1));
  // function to close calendar drop down onclicking page
  const dropdownRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        showCalendar &&
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setShowCalendar(false);
        setShowRemoveIcon(false);
      }
      if (showPlaceholder === true) {
        setShowCalendar(true);
      }
    }
    // Add the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);
    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showCalendar, showPlaceholder]);

  // toggle date range calendar
  const toggleCalendar = () => {
  
    setShowCalendar(!showCalendar);
    setShowRemoveIcon(!showRemoveIcon);
    if (onRemoveDate) {
      
      onRemoveDate(true);
    }
  };

  const handleSelect = (ranges) => {
    const numberOfDays = dateRange;
    // state to check start date and end date
    setCheckStartdate(ranges.selection.startDate.toISOString());
    setCheckEndDate(ranges.selection.endDate.toISOString());
    setStartDate(moment(ranges.selection.startDate).format("ll"));

    // store first selected value to variables for function to set min and max date
    const currentMaxDate = new Date(ranges.selection.startDate.toISOString());
    const currentMinDate = new Date(ranges.selection.startDate.toISOString());

    // function to set minimum date
    const newMinimumDate = new Date(
      currentMinDate.setDate(currentMinDate.getDate() - numberOfDays)
    );
    setMinDate(newMinimumDate);
    // function for set maximum date
    let newMaximumDate = new Date(
      currentMaxDate.setDate(currentMaxDate.getDate() + numberOfDays)
    );
    // check maximum date is greather than current date
    if (newMaximumDate > previousDay) {
      newMaximumDate = maximumDate;
    }

    setMaxdate(newMaximumDate);

    setCalendarDate([ranges.selection]);

    setShowRemoveIcon(true);
    setShowPlaceholder(false);
  };

  useEffect(() => {
    if (removeDate === true) {
      setShowCalendar(true);
      setRemoveDate(false);
    }
  }, [removeDate]);

  // function to remove selected values and range
  const removeSelectedDate = () => {
 
    setShowCalendar(false);
    setShowPlaceholder(true);
    setStartDate("");
    setEndDate("");
    setMaxdate(maximumDate);
    setMinDate(new Date("1970-01-01"));
    setShowRemoveIcon(false);
    setCalendarDate([
      {
        startDate: new Date(maximumDate),
        endDate: new Date(maximumDate),
        key: "selection",
      },
    ]);
    setRemoveDate(true);
    setSelectedYear("");
    setShowYear(false);
     // Notify parent to update `showFilteredCalender`
     if (onRemoveDate) {
      onRemoveDate(false);
    }
  };

  const onSelectWeekData = () => {
    setShowWeek(true);
    setShowYear(false);
    setCurrentDateFormat("WEEKLY");
    setSelectedYear("");
  };
  const OnSelectYearData = () => {
    setShowYear(true);
    setShowWeek(false);
    setCurrentDateFormat("YEARLY");
  };
  // for showing placeholder in calender input box
  let inputValue = "";
  if (showWeek) {
    if (!startDate && !endDate) {
      inputValue = "Select date range";
    } else {
      inputValue = startDate + " - " + endDate;
    }
  } else {
    if (!selectedYear) {
     if (currentYear) {
        inputValue = currentYear;
      } else { 
        inputValue = "Select year";
      }
    } else {
      inputValue = selectedYear;
    }
  }

  return (
    <div className="week-range-picker" ref={dropdownRef}>
      <div className="input-container">
        <input
          className="date-range-input"
          value={inputValue}
          onClick={toggleCalendar}
        />
        {showRemoveIcon === true ? (
          <img
            className="close-button"
            src={CloseIcon}
            alt="remove-icon"
            onClick={removeSelectedDate}
          />
        ) : (
          <img
            src={CalendarIcon}
            alt="calendar-icon"
            onClick={toggleCalendar}
          />
        )}
      </div>
      {showCalendar === true ? (
        <div
          className={showWeek ? "date-picker-wrapper" : "year-picker-wrapper"}
        >
          {showFilteredCalender ? (
            <>
              <div className="year-or-week-selection-box">
                <div
                  className={
                    showWeek ? "active-selection-button" : "selection-button"
                  }
                  onClick={onSelectWeekData}
                >
                  Week
                </div>
                <div
                  className={
                    showYear ? "active-selection-button" : "selection-button"
                  }
                  onClick={(value) => {
                    OnSelectYearData(value);
                  }}
                >
                  <YearPicker
                    selectedValue={selectedYear ? selectedYear : currentYear}
                    getSelectedYear={(value) => {
                      setSelectedYear(value);
                      setShowPlaceholder(false);
                    }}
                    renderFunction={(
                      value,
                      openCalendar,
                      handleValueChange
                    ) => {
                      return (
                        <div
                          onClick={(value) => {
                            openCalendar();
                            OnSelectYearData();
                          }}
                          style={{ width: "80px" }}
                        >
                          Year
                        </div>
                      );
                    }}
                  />
                </div>
              </div>

              {showWeek && (
                <DateRange
                  editableDateInputs={false}
                  onChange={(daterange) => {
                    handleSelect(daterange);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={calendarDate}
                  maxDate={maxDate}
                  color={["#9BA5B5"]}
                  rangeColors={["#F39200"]}
                  minDate={minDate}
                  dragSelectionEnabled={false}
                />
              )}
            </>
          ) : (
            ""
            // <DateRange
            //   editableDateInputs={false}
            //   onChange={(daterange) => {
            //     handleSelect(daterange);
            //   }}
            //   moveRangeOnFirstSelection={false}
            //   ranges={calendarDate}
            //   maxDate={maxDate}
            //   color={["#9BA5B5"]}
            //   rangeColors={["#F39200"]}
            //   minDate={minDate}
            //   dragSelectionEnabled={false}
            // />
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WeekAndYearPicker;
