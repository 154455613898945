import React, { useContext } from "react";
import { UserManagementContext } from "../UserManagementView";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import DataTable from "../../../components/DataTableComponent/DataTable";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import addUser from "../../../assets/images/icons/addUser.svg";
import "./userManagement.css";
import { Pagination } from "../../../components/Pagination/Pagination";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import ErrorImage from "../../../assets/images/icons/Error.svg";
import SuccessImage from "../../../assets/images/Success.svg";
import ConfirmationIcon from "../../../assets/images/Confirmation.svg";

const UserManagement = () => {
  const {
    header,
    data,
    addUserClick,
    userList,
    errorAlertShow,
    setErrorAlertShow,
    errorMessage,
    loaderStatus,
    currentPage,
    totalPage,
    onPageChange,
    statusCode,
    loginPage,
    deleteConform,
    successAlert,
    conformationPopup,
    setConformationPopup,
    setDeleteConform,
    userId,
    deletUserDetails,
    setLoaderStatus,
    setSuccessAlert,
    userManagementList,
  } = useContext(UserManagementContext);
  return (
    <div className="user-managment-main-page">
      {loaderStatus && <Loader />}
      <div className="user-managment-heading-row">
        <div className="user-managment-row">
          <div className="user-managment-heading">Dashboard User Management</div>
          <div>
            <ButtonComponent
              coloredButton={true}
              text={"Add User"}
              buttonImage={addUser}
              customClass={"trends-button"}
              onClick={addUserClick}
            />
          </div>
        </div>
      </div>
      <MDBRow>
        <MDBCol>
          <ThemeCard cardTitle={"Users"}>
            <DataTable
              currentPage={currentPage}
              header={header}
              tableData={userList}
              isPagination={true}
              totalPage={totalPage}
              dataPerPage="100"
              // onPageChange={(e) => onPageChange(e)}
            />
          </ThemeCard>
          {/* {userList ? (
            <div className="pagination-button">
              <Pagination onChnagePagination={onChnagePagination} />
            </div>
          ) : (
            ""
          )} */}
        </MDBCol>
      </MDBRow>
      <Alertpopup
        modalType="successForm"
        image={ErrorImage}
        setShowModal={setErrorAlertShow}
        showModal={errorAlertShow}
        titleText="Error"
        DetailText={errorMessage}
        alertButtonText={statusCode == 401 ? "Login" : "Continue"}
        onclickModalContinue={() => {
          statusCode == 401 ? loginPage() : setErrorAlertShow(false);
        }}
      />
      {conformationPopup && (
        <Alertpopup
          image={ConfirmationIcon}
          setShowModal={setConformationPopup}
          showModal={conformationPopup}
          titleText="Are you sure?"
          DetailText={"Are you sure you want to delete this user?"}
          buttonText={"Delete"}
          onClick={() => {
            deletUserDetails(userId);
            setLoaderStatus(true);
          }}
        />
      )}
      {successAlert && (
        <Alertpopup
          modalType="successForm"
          image={SuccessImage}
          setShowModal={setSuccessAlert}
          showModal={successAlert}
          titleText="Success"
          DetailText={errorMessage}
          alertButtonText={"Continue"}
          onclickModalContinue={() => {
            userManagementList(1);
            setSuccessAlert(false);
          }}
        />
      )}
    </div>
  );
};

export default UserManagement;
