import React, { useEffect, useState } from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import { DateRange } from "react-date-range";
import CalendarIcon from "../../assets/images/icons/Calendar.svg";
import moment from "moment";
import "./datePickerModal.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
const DatePickerModal = ({
  showModal,
  setShowModal,
  title,
  initialDate,
  dateRange,
  getSelectedDates,
  onClickSubmit,
  SubmitButtonText,
  submitButtoncustomClass,
  getSelectedTime,
}) => {
  const toggleModal = () => {
    setShowPlaceholder(true);
    setStartDate("");
    setEndDate("");
    setMaxdate(new Date());
    setMinDate(new Date("1970-01-01"));
    setShowModal(!showModal);
    setEndTime(
      new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    );
    setStartTime(
      new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    );
    setCalendarDate([
      {
        startDate: new Date(),
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [showRemoveIcon, setShowRemoveIcon] = useState(false); // state for remove icon show and hide
  const [showPlaceholder, setShowPlaceholder] = useState(false); // state for placeholder show and hide
  const [checkStartDate, setCheckStartdate] = useState();
  const [checkEndDate, setCheckEndDate] = useState();
  const [enableSubmit, setEnableSubmit] = useState(false);
  // state to show start and end date inside the input
  const [startDate, setStartDate] = useState(
    ""
    // moment(initialDate).subtract(6, "days").format("ll")
  );
  const [endDate, setEndDate] = useState(moment(initialDate).format("ll"));
  // state to set max date and min date
  const [maxDate, setMaxdate] = useState(initialDate);
  const [minDate, setMinDate] = useState(new Date("1970-01-01"));
  const [exportBtnIsEnable, setExportBtnIsEnable] = useState(true);
  // state for calendar date selection
  const [calendarDate, setCalendarDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  // time range selection
  const [startTime, setStartTime] = useState(
    new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  );
  const [endTime, setEndTime] = useState(
    new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  );

  // on click calendar checking the dates
  useEffect(() => {
    const startDate = moment(checkStartDate).format("ll");
    const endDate = moment(checkEndDate).format("ll");
    setStartDate(startDate);
    setEndDate(endDate);
    getSelectedDates(startDate, endDate);
  }, [checkStartDate, checkEndDate]);

  const handleSelect = (ranges) => {

    const numberOfDays = 365;
    setEnableSubmit(true);
    setExportBtnIsEnable(false)
    // state to check start date and end date
    setCheckStartdate(ranges.selection.startDate.toISOString());
    setCheckEndDate(ranges.selection.endDate.toISOString());

    // store first selected value to variables for function to set min and max date
    const currentMaxDate = new Date(ranges.selection.startDate.toISOString());
    const currentMinDate = new Date(ranges.selection.startDate.toISOString());

    // // function to set minimum date
    const newMinimumDate = new Date(
      currentMinDate.setDate(currentMinDate.getDate() - numberOfDays)
    );
    setMinDate(newMinimumDate);

    // function for set maximum date
    let newMaximumDate = new Date(
      currentMaxDate.setDate(currentMaxDate.getDate() + numberOfDays)
    );
    // // check maximum date is greather than current date
    if (newMaximumDate > new Date()) {
      newMaximumDate = initialDate;
    }

    setMaxdate(newMaximumDate);
    setCalendarDate([ranges.selection]);

    // formated date that pass to pages
    const selectedStartDate = moment(ranges.selection.startDate).format(
      "YYYY-MM-DD"
    );
    const selectedEndDate = moment(ranges.selection.endDate).format(
      "YYYY-MM-DD"
    );

    setShowRemoveIcon(true);
    setShowPlaceholder(false);
  };

  const onChangeStartTime = (e) => {
    setStartTime(e);
    getSelectedTime(e, endTime);
  };
  const onChangeEndTime = (e) => {
    setEndTime(e);
    getSelectedTime(startTime, e);
  };
  return (
    <div className="date-modal-main">
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        onClickCancel={toggleModal}
        title={title}
        cancelButtonText="Cancel"
        submitButtoncustomClass={
          enableSubmit ? "submit-enable" : "submit-disable"
        }
        SubmitButtonText={SubmitButtonText}
        customWidth="date-range-modal-width"
        buttonCenter={true}
        onClickSubmit={onClickSubmit}
        submitButtonDisable={exportBtnIsEnable}
      >
        <div className="date-picker-modal">
          <div className="input-container">
            <div className="date-range-title">Date Range</div>
            <div className="date-show-box">
              <input
                className="date-input"
                value={
                  showPlaceholder === true
                    ? "Select date range"
                    : startDate + ` ${startDate == "" ? "" : "-"} ` + endDate
                }
              />
              <img
                src={CalendarIcon}
                alt="calendar-icon"
                className="calendar-icon"
              />
            </div>
          </div>
          <DateRange
            color={["#9BA5B5"]}
            rangeColors={["#F39200"]}
            onChange={(daterange) => {
              handleSelect(daterange);
            }}
            moveRangeOnFirstSelection={false}
            ranges={calendarDate}
            maxDate={maxDate}
            editableDateInputs={false}
            minDate={minDate}
          />
          {/* time -range selection */}
          <div className="time-range-selection ">
            <div>
              {" "}
              <TimePicker
                onChange={(e) => onChangeStartTime(e)}
                value={startTime}
                className="time-picker"
                disableClock={true}
                clearIcon={false}
              />
            </div>
            <div className="text-box">To</div>
            <div>
              {" "}
              <TimePicker
                onChange={(e) => onChangeEndTime(e)}
                value={endTime}
                className="time-picker"
                disableClock={true}
                clearIcon={false}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default DatePickerModal;
