import React, { useContext } from "react";
import { HomePageContext } from "../HomePageView";
import Topbar from "../../../components/Topbar/Topbar";
import "./homePage.css";
import HomePageCard from "../../../components/HomePageCardComponent/HomePageCard";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import Loader from "../../../components/LoaderComponent/LoaderComponent";

const HomePage = () => {
  const { categoryList, contents, goToDashboard, isLoading } =
    useContext(HomePageContext);
  return (
    <div className="home-page-main">
      <Topbar />
      {isLoading ? <Loader /> : ""}
      <div className="home-page-background">
        <MDBCol lg="10" md="9" xl="9">
          <div className="home-page-title">{contents.cardTitle}</div>
          <MDBContainer fluid>
            <MDBRow>
              {categoryList?.map((item) => {
                return (
                  <MDBCol
                    xl="4"
                    lg="6"
                    md="6"
                    sm="6"
                    className="mt-4  home-page-card-row"
                  >
                    <HomePageCard
                      categoryName={item.name}
                      cardColur={
                        "linear-gradient(to left, #F39200, #FAB041)"
                      }
                      categoryImage={
                        item?.categoryImage
                          ? item?.categoryImage
                          : item?.iconImage
                      }
                      count={item.count}
                      onClick={() => goToDashboard(item?.name,item?.id)}
                    />
                  </MDBCol>
                );
              })}
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </div>
    </div>
  );
};

export default HomePage;
