import React, { createContext, useState } from "react";
import {
  validateEmail,
  blankSpaceCheck,
  validateName,
  nonEmpty,
  specialCharacter,
  spaceCheck,
  capitalLetterValidate,
  lowercaseLetterValidate,
  singleNumber,
  miniCharacters,
} from "../../utils/service";
import { addUserApi, roleListApi, editUserApi } from "../../apis/Api";
import { useLocation, useNavigate } from "react-router-dom";
import ContentData from "../../constants/ContentFile.json";
import RouteConstants from "../../constants/routeConstants.json";

export const CreateUserManagementContext = createContext();
export const CreateUserManagementContextProvider = ({ children }) => {
  const location = useLocation();
  const userDetails = location?.state?.userData;
  const isEdit = location.state.isEdit;
  const userId = location?.state?.userData?.id;
  const [name, setName] = useState(userDetails ? userDetails.name : "");
  const [invalidName, setInvalidName] = useState(false);
  const [email, setEmail] = useState(userDetails ? userDetails.email : "");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [roleName, setRoleName] = useState(userDetails ? userDetails.role : "");
  const [invalidRole, setInvalidRole] = useState(false);
  const [password, setPassword] = useState(
    userDetails ? userDetails.password : ""
  );
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(
    userDetails ? userDetails.confirmPassword : ""
  );
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
  const [passwordMisMatch, setPasswordMisMatch] = useState(false);
  const [roleCategoryValidate, setRoleCategotyValidate] = useState("");
  const [validatedName, setValidatedName] = useState("");
  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [confirmPasswordValidation, setConfirmPasswordValidation] =
    useState("");
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState(userDetails ? userDetails.roleId : "");
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const navigate = useNavigate();
  const nameValidation = (value) => {
    if (value === "") {
      setValidatedName(ContentData.adminUserPage.nameRequired);
      return false;
    } else if (!validateName(value)) {
      setValidatedName(ContentData.adminUserPage.validNameRequired);
      return false;
    } else {
      setValidatedName("");
      return true;
    }
  };
  const emailValidation = (value) => {
    if (value === "") {
      setEmailValidationMsg(ContentData.adminUserPage.emailRequired);
      return false;
    } else if (!validateEmail(value)) {
      setEmailValidationMsg(ContentData.adminUserPage.invalidEmail);
      return false;
    } else {
      setEmailValidationMsg("");
      return true;
    }
  };
  const handleName = (e) => {
    setName(e.target.value);
    nameValidation(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    emailValidation(e.target.value);
  };
  const roleValidation = (value) => {
    if (value === "") {
      setRoleCategotyValidate("Select role");
      return false;
    } else {
      setRoleCategotyValidate("");
      return true;
    }
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    roleValidation(e.target.value);
  };

  const passwordEnterValidation = (value) => {
    if (value === "") {
      setPasswordValidation(ContentData.adminUserPage.enterPasswordLabel);
      return false;
    } else if (spaceCheck(value)) {
      setPasswordValidation("Blank passwords shall not be permitted");
    } else if (!capitalLetterValidate(value)) {
      setPasswordValidation("At least one capital letter required");
      return false;
    } else if (!lowercaseLetterValidate(value)) {
      setPasswordValidation("At least one small letter required");
      return false;
    } else if (!singleNumber(value)) {
      setPasswordValidation("At least one number required");
      return false;
    } else if (!specialCharacter(value)) {
      setPasswordValidation(
        "At least one special character required, ~, @,#,$, %,^, &,*,(,)"
      );
      return false;
    } else if (!miniCharacters(value)) {
      setPasswordValidation("Minimum of 8 characters required");
      return false;
    } else {
      setPasswordValidation("");
      return true;
    }
  };

  const confirmPasswordEnterValidation = (value) => {
    if (value === "") {
      setConfirmPasswordValidation(
        ContentData.adminUserPage.enterConfirmPasswordLabel
      );
      return false;
    } else if (value !== password) {
      setConfirmPasswordValidation(ContentData.adminUserPage.passwordMtach);
      return false;
    } else {
      setConfirmPasswordValidation("");
      return true;
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    passwordEnterValidation(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    confirmPasswordEnterValidation(e.target.value);
  };
  const onRoleChangeEdit = (value) => {
    setRole(value?.id);
    //roleValidation(value?.id);
    setRoleName(value?.name);
  };
 

  const onSubmitCreateUser = async () => {
    const adminDetails = {
      name: name,
      email: email,
      roleId: role,
      password: password,
    };
    const editDetails = {
      name: name,
      // email: email,
      roleId: role,
    };

    const nameValidate = nameValidation(name);
    const emailValidate = emailValidation(email);

    const roleValidate = roleValidation(role);

    const passwordValidate = passwordEnterValidation(password);
    const confirmPasswordEnterValidationVal =
      confirmPasswordEnterValidation(confirmPassword);

    if (nameValidate && emailValidate && roleValidate)
      if (location?.state?.isEdit) {
        //alert("hi");
        try {
          setSpinnerStatus(true);

          const addUser = await editUserApi(userId, editDetails);
          if (addUser?.data?.status == 200) {
            setSpinnerStatus(false);
            setSuccessAlert(true);
            setAlertMessage(addUser?.data?.message);
          }
        } catch (error) {
          if (
            error?.response?.data?.status === 400 ||
            error?.response?.data?.status === 401
          ) {
            setSpinnerStatus(false);
            const errorMessage = error?.response?.data?.message;
            setAlertMessage(errorMessage);
            setErrorAlertShow(true);
          } else {
            setSpinnerStatus(false);
            setAlertMessage("Something went wrong...");
            setErrorAlertShow(true);
          }
        }
      } else {
        if (passwordValidate && confirmPasswordEnterValidationVal) {
          try {
            setSpinnerStatus(true);
            const addUser = await addUserApi(adminDetails);
            if (addUser?.data?.status == 200) {
              setSpinnerStatus(false);
              setSuccessAlert(true);
              setAlertMessage(addUser?.data?.message);
            }
          } catch (error) {
            setStatusCode(error?.response?.data?.status);
            if (error?.response?.data?.status === 400) {
              setSpinnerStatus(false);
              const errorMessage = error?.response?.data?.message;
              setAlertMessage(errorMessage);
              setErrorAlertShow(true);
            } else if (error?.response?.data?.status === 401) {
              setSpinnerStatus(false);
              const errorMessage = error?.response?.data?.message;
              setAlertMessage(errorMessage);
              setErrorAlertShow(true);
            } else {
              setSpinnerStatus(false);
              setAlertMessage("Something went wrong...");
              setErrorAlertShow(true);
            }
          }
        }
      }
  };

  const createUserDetail = async (adminDetails) => {};

  const editUserDetails = async (userId, editDetails) => {
    //alert("hi");
  };
  const [updatedRoleList, setUpdatedRoleList] = useState([]);
  const roleListShown = async () => {
    try {
      const data = await roleListApi();

      if (data?.data?.status === 200) {
        setRoleList(data?.data?.data?.roles);
        const rolelistupdte = data?.data?.data?.roles.map((item) => ({
          id: item.id,
          name: item.roleName,
        }));
        setUpdatedRoleList(rolelistupdte);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const userManagementList = () => {
    navigate({
      pathname: RouteConstants.USER_MANAGEMENT,
      search: "?plug=Plug",
    });
    // navigate(RouteConstants.USER_MANAGEMENT);
  };

  const loginPage = () => {
    navigate(RouteConstants.LOGIN);
  };

  const providerValue = {
    name, //  state  show name
    setName, // state to set  name
    email, //  state  show email
    setEmail, // state to set  email
    roleList, // state show role list
    setRoleList, // state to set role list
    roleName, // state to show roleName ,
    setRoleName, // state to set role name
    password, // state to show password
    setPassword, //state to set  password
    confirmPassword, // state to show confirm password
    setConfirmPassword, //state to set confirm  password
    invalidName, // state to validate name
    invalidEmail, // state to validate email
    invalidRole, // state to validate role
    invalidPassword, // state to validate password
    invalidConfirmPassword, // state to validate confirm password
    passwordMisMatch,
    setPasswordMisMatch,
    isEdit,
    validatedName,
    emailValidationMsg,
    roleCategoryValidate,
    passwordValidation,
    confirmPasswordValidation,
    handleName,
    handleEmailChange,
    handleRoleChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    onSubmitCreateUser,
    roleListShown,
    userManagementList,
    successAlert,
    errorAlertShow,
    alertMessage,
    setErrorAlertShow,
    setSuccessAlert,
    spinnerStatus,
    statusCode,
    loginPage,
    role,
    updatedRoleList,
    onRoleChangeEdit,
  };

  return (
    <CreateUserManagementContext.Provider value={providerValue}>
      {children}
    </CreateUserManagementContext.Provider>
  );
};
