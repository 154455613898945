import React, { useContext } from "react";
import { PlugDashBoardPageContext } from "../plugdashboardview";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import MixedChart from "../../../components/ChartComponent/ChartComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import PlugDashboardPageContent from "../../../constants/ContentFile.json";
import Document from "../../../assets/images/icons/Document.svg";
import PDFIcon from "../../../assets/images/icons/PdfIcon.svg";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import TimeIntervalModal from "../../../components/TimeIntervalModal/TimeIntervalModal";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import errorImage from "../../../assets/images/Disable.svg";
import noSchedule from "../../../assets/images/noSchedule.svg";
import noHoliday from "../../../assets/images/noHoliday.svg";
import noLoopTimer from "../../../assets/images/loopTimer.svg";
import DatePickerModal from "../../../components/DatePickerModal/DatePickerModal";
import SuccessImage from "../../../assets/images/Success.svg";
import moment from "moment";
import "./plugdashboard.css";
import WeekAndYearPicker from "../../../components/WeekAndYearPickerComponent/WeekAndYearPicker";
import RefreshIcon from "../../../assets/images/icons/refreshicon.svg";
import AlarmCondtionsModal from "../../../components/AlarmConditionsModal/AlarmCondtions";
import viewIcon from "../../../assets/images/icons/viewUser.svg";
import emptyDataIcon from "../../../assets/images/icons/emptyData.svg";
import infoIcon from "../../../assets/images/icons/info-button.svg";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
const PlugDashboardPage = () => {
  let plugPageContent = PlugDashboardPageContent.PlugDashboardPageContent;
  const {
    status,
    data1,
    onClickLegend,
    goToDeviceLogs,
    legendMangement,
    updateFrequncyModal,
    setUpdateFrequencyModal,
    exportPdfModal,
    setExportPdfModal,
    currentReadings,
    profile,
    holiday,
    loopTimer,
    schedule,
    offTimer,
    offTimerArray,
    isLoading,
    errorModal,
    setErrorModal,
    errorModalMessage,
    selectDate,
    selectExportDate,
    exportData,
    startDate,
    endDate,
    onSubmitTimeInterval,
    successModal,
    setSuccessModal,
    sucessModalText,
    downloadGraph,
    updateSelectedDateFormat,
    updateSelectedYear,
    refreshButtonClick,
    setShowAlarmModal,
    showAlarmModal,
    ShowAlarmModal,
    emptyGraphData,
    showModalInfomationOnGraph,
    infoModalVisible,
    InfomationModalCancel,
    setInfoModalVisible,
    showFilteredCalender, setShowFilteredCalender
  } = useContext(PlugDashBoardPageContext);
  let currentDate = new Date();
  const date = new Date(currentDate.setDate(currentDate.getDate() - 1));
  let alarmData = currentReadings?.alarmMessage?.split(",");
  return (
    <div className="plug-dashboard-page">
      {isLoading ? <Loader /> : ""}

      <div className="plug-dashbord-top-button-container">
        {isLoading ? <Loader /> : ""}
        <p className="plug-dashboard-label">{plugPageContent.dashboard}</p>
        <div className="button-container">
          <ButtonComponent
            coloredButton={false}
            customClass="settings-button"
            buttonImage={RefreshIcon}
            onClick={refreshButtonClick}
          />
          <ButtonComponent
            text={"View Device Logs"}
            customClass="ms-2 device-log-button"
            buttonImage={Document}
            buttonWidth="190px"
            onClick={goToDeviceLogs}
          />
          <ButtonComponent
            text={"Export"}
            coloredButton={true}
            customClass="ms-4"
            buttonWidth="110px"
            buttonImage={PDFIcon}
            onClick={() => {
              downloadGraph();
            }}
          />
        </div>
      </div>
      <div id={"dashboardId"}>
        {/* current  readings */}
        <div className="plug-dashboasrd-current-readings">
          <ThemeCard cardTitle={plugPageContent.currentReadings}>
            <MDBRow className="current-reading-row">
              <MDBCol className="current-readings">
                <div className="test">
                  <div className="voltage-label-div">
                    {plugPageContent.voltage}
                  </div>
                  <div className="border-right-div"></div>
                  <div className="voltage-text-div">
                    {currentReadings?.voltage ? currentReadings?.voltage : "-"}
                  </div>
                </div>
              </MDBCol>
              <MDBCol className="current-readings">
                <div className="test">
                  <div className="voltage-label-div">
                    {plugPageContent.current}
                  </div>
                  <div className="border-right-div"></div>
                  <div className="voltage-text-div">
                    {currentReadings?.current ? currentReadings?.current : "-"}
                  </div>
                </div>
              </MDBCol>
              <MDBCol className="current-readings">
                <div className="test">
                  <div className="voltage-label-div">
                    {plugPageContent.power}
                  </div>
                  <div className="border-right-div"></div>
                  <div className="voltage-text-div">
                    {currentReadings?.power ? currentReadings?.power : "-"}
                  </div>
                </div>
              </MDBCol>
              <MDBCol className="current-readings">
                <div className="status-div">
                  <div className="voltage-label-div">
                    {plugPageContent.status}
                  </div>
                  {currentReadings?.status === "online" ? (
                    <div className="status-on-btn">
                      <div className="status-div-label">On</div>
                    </div>
                  ) : currentReadings?.status === "shutdown" ? (
                    <div className="status-off-btn">
                      <div className="status-div-label">Off</div>
                    </div>
                  ) : currentReadings?.status === "error" ? (
                    <div className="status-error-btn">
                      <div className="status-div-label">Error</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </MDBCol>
              {currentReadings?.alarmMessage !== "" && (
                <MDBCol className="current-readings">
                  <div className="alarm-box">
                    <div className="alarm-label">Alarm</div>
                    <div className="alarm-text">
                      <div>
                        {alarmData && (
                          <>
                            {alarmData[0]}{" "}
                            {alarmData?.length > 1 ? (
                              <span onClick={() => ShowAlarmModal(true)}>
                                ...
                                <img src={viewIcon} />
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </MDBCol>
              )}
            </MDBRow>
          </ThemeCard>
        </div>
        {/*graph  */}
        <div className="plug-dashboasrd-energy">
          <ThemeCard
            customClass={"chart-card-title"}
            customMainClass={"chart-card"}
            //cardTitle={"Device parameters"}
          >
            <div className="graph-title-box">
            <div className="tittle-card-graph">Device Parameters

            <div
                className="information-icon"
                 onClick={showModalInfomationOnGraph}
              >
                <img src={infoIcon} />{" "}
              </div>
            </div>
          
              <div className="date-picker">
                <WeekAndYearPicker
                  dateRange={6}
                  maximumDate={date}
                  intialStartDate={startDate}
                  initialEndDate={endDate}
                  getSelectedDates={(startDate, endDate) => {
                    selectDate(startDate, endDate);
                  }}
                 // showFilteredCalender={true}
                 showFilteredCalender={showFilteredCalender}
                  getYearOfData={(selectedYear, currentDateFormat) => {
                    updateSelectedDateFormat(currentDateFormat);
                    updateSelectedYear(selectedYear);
                  }}
                  onRemoveDate={(value) => {
            
                    setShowFilteredCalender(value)
                  }
                
                } // Pass callback to update `showFilteredCalender`
                />
              </div>
              
             
            
          
            </div>
            {!emptyGraphData ? (
              <div>
                <MixedChart
                  data={data1}
                  clickLegend={onClickLegend}
                  legendShow={true}
                  legendMangement={legendMangement}
                  manageClickStatus={true}
                  customLegendClass={"plug-legend"}
                />
              </div>
            ) : (
              <div className="data-unavailable-placeholder">
                <img src={emptyDataIcon} />
                <div>Empty Data</div>
              </div>
            )}
          </ThemeCard>
        </div>
        {/* profile & Schedule */}
        <div className="plug-dashboasrd-profile">
          <MDBRow className="main-plug">
            <MDBCol className="profile-col" size="8">
              {/* profile */}

              <ThemeCard
                customClass={"profile-theme-card"}
                cardTitle={plugPageContent.profile}
              >
                <MDBRow className="serial-no-row-div">
                  <MDBCol size="3">
                    <p className="serial-no-label">
                      {plugPageContent.serailno}
                    </p>
                    <p className="serial-no-value">
                      {profile?.srno ? profile?.srno : "-"}
                    </p>
                  </MDBCol>
                  <MDBCol size="5">
                    <p className="device-type-label">
                      {plugPageContent.deviceType}
                    </p>
                    <p className="device-type-value">
                      {profile?.deviceType ? profile?.deviceType : "-"}
                    </p>
                  </MDBCol>
                  <MDBCol size="4">
                    <p className="serial-no-label">
                      {plugPageContent.modalname}
                    </p>
                    <p className="serial-no-value">
                      {profile?.modelName ? profile?.modelName : "-"}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="serial-no-row-div">
                  <MDBCol size="3">
                    <p className="serial-no-label">{plugPageContent.macId}</p>
                    <p className="serial-no-value">
                      {profile?.macId ? profile?.macId : "-"}
                    </p>
                  </MDBCol>
                  <MDBCol size="5">
                    <p className="device-type-label">
                      {plugPageContent.nickName}
                    </p>
                    <p className="device-type-value">
                      {profile?.productNickName
                        ? profile?.productNickName
                        : "-"}
                    </p>
                  </MDBCol>
                  <MDBCol size="4">
                    <p className="device-type-label">
                      {plugPageContent.firmwareVersion}
                    </p>
                    <p className="device-type-value">
                      {profile?.firmwareVersion
                        ? profile?.firmwareVersion
                        : "-"}
                    </p>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="serial-no-row-div">
                  <MDBCol size="3">
                    <p className="serial-no-label">
                      {plugPageContent.connectedSSID}
                    </p>
                    <p className="serial-no-value">
                      {profile?.ssid ? profile?.ssid : "-"}
                    </p>
                  </MDBCol>

                  <MDBCol size="5" className="frequency-box">
                    <div>
                      <p className="device-type-label">
                        {plugPageContent.stateReportFrequency}
                      </p>
                      <p className="device-type-value">
                        {profile?.stateReportFrequency
                          ? profile?.stateReportFrequency
                          : "-"}
                      </p>
                    </div>
                    <ButtonComponent
                      text={"Update"}
                      coloredButton={true}
                      customClass={"frequency-btn"}
                      onClick={() => {
                        setUpdateFrequencyModal(true);
                      }}
                    />
                  </MDBCol>
                  <MDBCol size="4">
                    <p className="serial-no-label">
                      {plugPageContent.createdOn}
                    </p>
                    <p className="serial-no-value">
                      {profile?.createdOn
                        ? moment(profile?.createdOn).format("L")
                        : "-"}
                    </p>
                  </MDBCol>
                  {/*status  message removed :- it is shown in current reading */}
                  {/* <MDBCol size="3">
                    <p className="serial-no-label">{plugPageContent.Message}</p>
                    <p className="serial-no-value">
                      {profile?.message ? profile?.message : "-"}
                    </p>
                  </MDBCol> */}
                </MDBRow>
              </ThemeCard>
            </MDBCol>

            <MDBCol className="schedule-col" size="4">
              <div className="plug-dashboasrd-schedule scrolBarStyle">
                {/* Schedule */}
                <ThemeCard
                  customMainClass={"schedule-theme-card"}
                  cardTitle={plugPageContent.Schedule}
                >
                  {schedule?.length == 0 ? (
                    <MDBRow>
                      <MDBCol size="12" className="schedule-empty-box">
                        <img src={noSchedule} />
                        <div className="empty-box-text">
                          {plugPageContent.noSchedule}
                        </div>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    schedule?.map((item) => {
                      return (
                        <MDBRow className="serial-no-row-div">
                          <MDBCol size="12" className="schedule-box">
                            <div className="schedule-label">
                              {item.scheduleStatus}-{item.id}
                            </div>
                            <div className="time-box">
                              <span className="serial-no-label">
                                {item.startTime} - {item.endTime}
                              </span>
                              <span className="serial-no-label">
                                {item.everyDay == true
                                  ? "Everyday"
                                  : item.weekdays.join(",")}
                              </span>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      );
                    })
                  )}
                </ThemeCard>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
        {/* {/* Holiday Mode ,Loop Timer& Off timer */}

        <div className="plug-dashboasrd-holiday-mode">
          <MDBRow>
            <MDBCol size="4">
              {/* Holiday Mode */}
              {Object.keys(holiday)?.length === 0 ? (
                <ThemeCard cardTitle={plugPageContent.holidayMode}>
                  <MDBRow>
                    <MDBCol size="12" className="schedule-empty-box">
                      <img src={noHoliday} />
                      <div className="empty-box-text">
                        {plugPageContent.noHolidayMode}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </ThemeCard>
              ) : (
                <ThemeCard cardTitle={plugPageContent.holidayMode}>
                  <MDBRow className="holidaymode-row-div">
                    <MDBCol size="12" className="holidaymode-box">
                      <p className="holidaymode-start-date">Start Date</p>
                      <div className="holidaymode-div">
                        <p className="holidaymode-date-text">
                          {holiday?.startDate ? holiday?.startDate : "-"}
                        </p>
                        <p className="holidaymode-time-text">
                          {holiday?.startTime}
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <div className="line-div"></div>
                  <MDBRow className="holidaymode-row-div">
                    <MDBCol size="12" className="holidaymode-box">
                      <p className="holidaymode-start-date">End Date</p>
                      <div className="holidaymode-div">
                        <p className="holidaymode-date-text">
                          {holiday?.endDate ? holiday?.endDate : "-"}
                        </p>
                        <p className="holidaymode-time-text">
                          {holiday?.endTime}
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </ThemeCard>
              )}
            </MDBCol>
            <MDBCol size="4">
              {/* Loop Timer */}
              {Object.keys(loopTimer)?.length === 0 ? (
                <ThemeCard cardTitle={plugPageContent.loopTimer}>
                  <MDBRow>
                    <MDBCol size="12" className="schedule-empty-box">
                      <img src={noLoopTimer} />
                      <div className="empty-box-text">
                        {plugPageContent.noLoopTime}
                      </div>
                    </MDBCol>
                  </MDBRow>
                </ThemeCard>
              ) : (
                <ThemeCard cardTitle={plugPageContent.loopTimer}>
                  <MDBRow className="holidaymode-row-div">
                    <MDBCol className="loop-timer-div">
                      <p className="holidaymode-start-date">ON Time</p>
                      <div className="loop-mode-div">
                        <p className="loop-time-text">
                          {loopTimer?.onTime ? loopTimer?.onTime : "-"}
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <div className="line-div"></div>
                  <MDBRow className="holidaymode-row-div">
                    <MDBCol className="loop-timer-div">
                      <p className="holidaymode-start-date">OFF Time</p>
                      <div className="loop-mode-div">
                        <p className="loop-time-text">
                          {loopTimer?.offTime ? loopTimer?.offTime : "-"}
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </ThemeCard>
              )}
            </MDBCol>
            <MDBCol size="4">
              {/* off timer */}
              <ThemeCard cardTitle={plugPageContent["offTimer(hr)"]}>
                <MDBRow className="holidaymode-row-div">
                  <MDBCol
                    size="12"
                    className={
                      offTimer?.length === 0
                        ? "off-timer-empty-div"
                        : "off-timer-main-div"
                    }
                  >
                    {offTimerArray?.map((item) => {
                      return (
                        <>
                          <div
                            className={`off-timer-time
                            ${
                              offTimer?.status
                                ? offTimer?.status == "1" &&
                                  offTimer?.hour == item?.value
                                  ? "active-time"
                                  : "inactive-time"
                                : ""
                            }`}
                          >
                            {item.time}
                          </div>
                          <div className="line-div-off-timer"></div>
                        </>
                      );
                    })}
                  </MDBCol>
                </MDBRow>
                <div className="line-div"></div>
                <MDBRow className="holidaymode-row-div">
                  <MDBCol
                    size="12"
                    className={
                      Object.keys(offTimer)?.length === 0
                        ? "loop-timer-empty-div"
                        : "loop-timer-div"
                    }
                  >
                    <div className="loop-mode-div">
                      <p className="loop-time-text">Remaining Time</p>
                      <p className="remaining-time">
                        {offTimer?.remainingTime
                          ? offTimer?.remainingTime
                          : "00:00"}
                      </p>
                    </div>
                  </MDBCol>
                </MDBRow>
              </ThemeCard>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      <TimeIntervalModal
        title={"Report Frequency"}
        showModal={updateFrequncyModal}
        setShowModal={setUpdateFrequencyModal}
        onClickSubmit={(value) => {
          onSubmitTimeInterval(value);
        }}
      />
      {/* error alert modal */}
      <Alertpopup
        showModal={errorModal}
        setShowModal={setErrorModal}
        modalType={true}
        titleText={"Error"}
        DetailText={errorModalMessage}
        image={errorImage}
        alertButtonText={"Close"}
      ></Alertpopup>

      {/* success alert modal */}

      <Alertpopup
        showModal={successModal}
        setShowModal={setSuccessModal}
        modalType={true}
        titleText={"Success"}
        DetailText={sucessModalText}
        image={SuccessImage}
        alertButtonText={"Close"}
      ></Alertpopup>

      {/*  export graph modal */}
      <DatePickerModal
        title={plugPageContent.exportData}
        showModal={exportPdfModal}
        setShowModal={setExportPdfModal}
        initialDate={date}
        dateRange={7}
        getSelectedDates={(fromDate, toDate) => {
          selectExportDate(fromDate, toDate);
        }}
        onClickSubmit={exportData}
        SubmitButtonText={plugPageContent.exportData}
      />

      {/* alarm condtion modal */}
      <AlarmCondtionsModal
        showModal={showAlarmModal}
        setShowModal={setShowAlarmModal}
        title={"Alarm Conditions"}
        alarmData={alarmData}
      />
          <div className="badge-modal">
        <ModalComponent
          showModal={infoModalVisible}
          setShowModal={setInfoModalVisible}
          cancelButtonText={"Cancel"}
          onClickCancel={InfomationModalCancel}
          title={"Device Parameters"}
          customWidth="command-badge-modal-width"
        >
          <div className="calculation-description">
        {plugPageContent.graphDetail}
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};
export default PlugDashboardPage;
