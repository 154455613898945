import React from "react";
import { LoginContextProvider } from "./LoginView";
import LoginPage from "../Login/views/LoginPage";

const Login = () => {
  return (
    <LoginContextProvider>
      <LoginPage />
    </LoginContextProvider>
  );
};

export default Login;
