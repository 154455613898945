import React, { createContext, useState } from "react";
import dummyDeviceListWithSerialNumber from "../../constants/ContentFile.json";
import { useNavigate, useLocation } from "react-router";
import RoutConstants from "../../constants/routeConstants.json";
export const UserDeviceListPageContext = createContext({});
export const UserDeviceListPageContextProvider = ({ children }) => {
  const [count, setCount] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const deviceList = location?.state?.result;
  const catogoryId = localStorage.getItem("dashboard_Id");
  const navigateToDeviceDashboard = (modelName) => {

      navigate({
        pathname: RoutConstants.PLUG_DASHBOARD,
        search: "?plug=singlePlug",
      });
    
 
    localStorage.setItem("model", modelName);
  };
  const providerValue = {
    count,
    deviceList,
    navigateToDeviceDashboard,
  };
  return (
    <UserDeviceListPageContext.Provider value={providerValue}>
      {children}
    </UserDeviceListPageContext.Provider>
  );
};
