import React, { useContext } from "react";
import { UserDeviceListPageContext } from "../UserDeviceListView";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import DataTable from "../../../components/DataTableComponent/DataTable";
import EyeIcon from "../../../assets/images/icons/viewUser.svg";
import "./userDeviceList.css";
const UserDeviceListPage = () => {
  const { deviceList, navigateToDeviceDashboard } = useContext(
    UserDeviceListPageContext
  );
  const header = [
    {
      title: "Model",
      prop: "productNames",
      cell: (row) => <div>{row.products.name}</div>,
    },
    {
      title: "Serial Number",
      prop: "serialNumber",
    },
    {
      title: "",
      prop: "time",
      cell: (row) => (
        <div>
          <div>
            <img
              src={EyeIcon}
              alt="eye-icon"
              onClick={() => {
                localStorage.setItem("serialNo_NavBar", row.serialNumber);
                navigateToDeviceDashboard(row.products.modelName);
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="user-device-list">
      <div className="phone-number-text">{deviceList[0]?.userProfile?.mobile}</div>
      <ThemeCard cardTitle={"Device List"}>
        <DataTable header={header} tableData={deviceList} />
      </ThemeCard>
    </div>
  );
};
export default UserDeviceListPage;
