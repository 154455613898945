import html2canvas from "html2canvas";
import { createContext, useEffect, useState } from "react";
import { getPowerStatus } from "../../apis/Api";
import moment from "moment";
import { GlobalStateContext } from "../../store/store";
import { useContext } from "react";

export const PlugPowerContext = createContext({});
export const PlugPowerContextProvider = ({ children }) => {
  const { state } = useContext(GlobalStateContext);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("ll")
  );
  const [endDate, setEndDate] = useState(moment(new Date()).subtract(1, "days").format("ll"));

  const [powerStatusArr, setPowerStatusArr] = useState([]);
  const [powerTotalData, setPowerTotalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false); // api error alert
  const [errorModalMessage, setErrorModalMessage] = useState([]);

 const [showGraphInfo,setShowGraphInfo] = useState(false)

  const serialNumber = localStorage.getItem("serialNo_NavBar");
  const model = localStorage.getItem("model");
  const onlineArray = powerStatusArr.map((item) => item.online); //power status online
  const offlineArray = powerStatusArr.map((item) => item.offline); //power status offline
  const unavailableArray = powerStatusArr.map((item) => item.error); //power status error
  const dateArray = powerStatusArr.map((item) => item.date); //power status date labels


  const data1 = {
    labels: dateArray,
    datasets: [
      {
        type: "bar",
        label: "Power ON",
        backgroundColor: "#F4B351",
        data: onlineArray,
        barThickness: 8,
        stack: "Stack 0",
        borderRadius: [5, 5, 5, 5],
      },
      {
        type: "bar",
        label: "Power OFF",
        backgroundColor: "#5A9EDD",
        data: offlineArray,
        borderRadius: [5, 5, 5, 5],
        barThickness: 8,
        stack: "Stack 0",
      },
      {
        type: "bar",
        label: "Data Unavailable",
        backgroundColor: "#767676",
        data: unavailableArray,
        borderRadius: [5, 5, 5, 5],
        barThickness: 8,
        stack: "Stack 0",
      },
    ],
  };

  useEffect(() => {
    powerStatus(startDate,endDate);
  }, [startDate,endDate]);
  // power status api call
  const powerStatus = async (startDate,endDate) => {
    setIsLoading(true);
    const data = {
      startDate: startDate,
      endDate: endDate,
      srno: serialNumber,
      type: state?.productType,
      model: model,
    };
    try {
      const response = await getPowerStatus(data);
      setPowerStatusArr(response?.data?.data?.dates);
      setPowerTotalData(response?.data?.data?.time);
      if (response?.data?.status === 200) {
      }
    } catch (error) {
      if (error?.data?.status === 401) {
        setErrorModal(true);
        setErrorModalMessage(error?.response?.data?.message);
      } else {
        setErrorModal(true);
        // setErrorModalMessage(error?.response?.data?.message);
        setErrorModalMessage("Something went wrong");
      }
    }
    setIsLoading(false);
  };

  // download graph as pdf
  const downloadFileHandler = async () => {
    const downloadDiv = document.getElementById("deviceConnectivityID");
    if (!downloadDiv) {
      return;
    }
    html2canvas(downloadDiv).then((canvas) => {
      const dataURL = canvas.toDataURL();
      const link = document.createElement("a");
      link.download = "power-status.jpg";
      link.href = dataURL;
      link.click();
    });
  };
  // selected dates from calender
  const selectedDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    powerStatus(startDate,endDate);
  };
  const showGraphInfoamtion =()=>{
    setShowGraphInfo(true)
  }
  const InfomationModalCancel =()=>{
    setShowGraphInfo(false)
  }
  const providerValue = {
    data1,
    downloadFileHandler,
    powerTotalData,
    isLoading,
    selectedDate,
    setErrorModal,
    errorModalMessage,
    errorModal,
    startDate,
    endDate,
    showGraphInfo,setShowGraphInfo,
    InfomationModalCancel,
    showGraphInfoamtion
  };
  return (
    <PlugPowerContext.Provider value={providerValue}>
      {children}
    </PlugPowerContext.Provider>
  );
};
