import { axiosInstance, axiosInstanceNous } from "./Axios";
// get device count -home page
export const getDeviceCategories = () => {
  // return axioscall.get(`${axiosInstance}/category/count`);
  return axiosInstance.get("/category/count");
};
export const getDeviceAddedonChart = (data) => {
  return axiosInstance.get(
    `/registered-products/count?productId=${data.productId}&categoryId=${data.categoryId}`
  );
};
export const getModalPlugList = (catogoryId) => {
  return axiosInstance.get(`/category/model-list/${catogoryId}`);
};
export const getCountOnline_offline_load = (catogoryId) => {
  return axiosInstanceNous.get(
    `/device/category/status-count/${catogoryId}?model=`
  );
};
export const getCount_modalSelect = (catogoryId, modalId) => {
  return axiosInstanceNous.get(
    `/device/category/status-count/${catogoryId}?model=${modalId}`
  );
};
//  plug trends graph api
export const plugTrends = (data) => {
  return axiosInstanceNous.post("/device/trends/graph", data);
};

// Plug | Device data API
export const getDeviceDataAPI = (data) => {

  return axiosInstanceNous.post("device/data/list", data);
};

export const singlePlugGragh = (data) => {
  return axiosInstanceNous.post(`device/trends/graph/`, data);
};
//Alert API

export const postAlertTargetDevice = (data, pageState) => {
  return axiosInstanceNous.post("/device/alerts/list", {
    ...data,
    pageState: pageState,
  });
};
// plug dashboard api
export const deviceDashboard = (data) => {
  return axiosInstanceNous.post("/device/dashboard", data);
};

// plug device log
export const getPlugDeviceLog = (deviceData) => {
  return axiosInstanceNous.post("device/device-logs", deviceData);
};

// Plug | Device connectivity - graph data
export const PlugConnectivityStatus = (data) => {
  return axiosInstanceNous.post("device/device-connectivity/graph", data);
};
// plug power status
export const getPowerStatus = (deviceData) => {
  return axiosInstanceNous.post("device/device-power/graph", deviceData);
};
// sendcommand device control
export const sendDeviceCommand = (data) => {
  return axiosInstanceNous.post("/nous/smartplug/send-command", data);
};

// plug Quick send command API | send commad module
export const plugQuickSendCommand = (command) => {
  return axiosInstanceNous.post("/nous/smartplug/send-command", command);
};

// send custom command  device control
export const sendCustomDeviceCommand = (data) => {
  return axiosInstanceNous.post("/nous/smartplug/send-command/custom", data);
};
// Plug custom command API | Send commad module
export const plugCustomCommand = (customCommand) => {
  return axiosInstanceNous.post(
    "nous/smartplug/send-command/custom",
    customCommand
  );
};
// get send command logs - send command page
export const getSendCommandLogs = (data) => {
  return axiosInstanceNous.post("/device/command-logs", data);
};
// verify model and serial number api
export const verifyModel = (data) => { 
  //type is tob added
  return axiosInstanceNous.get(
    `/device/verify-model/${data.model}/${data.srno}?type=${data.type}` 
  );
};
// list of firmware
export const getListOfFirmware = () => {
  return axiosInstanceNous.get("/device/firmware-version");
};

export const getCurrentFirwareversion = (serialNumber) => {
  return axiosInstanceNous.get(`/device/firmware-version/${serialNumber}`);
};
// verify model and serial number api
export const phoneNumberSearch = (data) => {
  return axiosInstance.get(
    `/user-products?categoryId=${data.categoryId}&mobile=${data.mobile}`
  );
};
// export csv data
export const exportCsvData = (data) => {
  return axiosInstanceNous.post("/device/csv-create", data);
};
// download csv data
export const downloadCsvData = (data) => {
  return axiosInstanceNous.get(
    `/device/csv-download?key=${data.key}&srno=${data.srno}`
  );
};
// get state list
export const getStateListData = () => {
  return axiosInstance.get("/state");
};

// location wise sendcommand

export const locationWiseSendCommand = (data) => {
  return axiosInstanceNous.post("/nous/smartplug/send-command", data);
};
export const locationWiseCustomSendCommand = (data) => {
  return axiosInstanceNous.post("/nous/smartplug/send-command/custom", data);
};

// Login Api
export const loginApi = (data) => {
  return axiosInstance.post("admin/login", data);
};

// reset password
export const resetPasswordApi = (token, id, type, password) => {
  const resetPassword = {
    password: password,
  };

  return axiosInstance.put(
    `admin/reset-password?token=${token}&id=${id}&type=${type}`,
    resetPassword
  );
};

//  forgot password email submit API
export const forgotPasswordAPI = (email) => {
  return axiosInstance.put("admin/forgot-password", email);
};

//User Management List API

export const userListApi = (page) => {
  return axiosInstance.get(`user-management?page=${page}&limit=100`);
};

// user details view API
export const viewUserDetailsAPI = (id) => {
  return axiosInstance.get(`user-management/${id}`);
};
// logout user

export const logoutAPI = () => {
  return axiosInstance.get("admin/logout");
};

//Add User Management API
export const addUserApi = (userDetails) => {
  return axiosInstance.post("user-management", userDetails);
};

//Role list api

export const roleListApi = () => {
  return axiosInstance.get("role");
};

// get user profile detail
export const getprofileDetail = () => {
  return axiosInstance.get("admin/profile");
};

// edit user
export const editUserApi = (userId, editDetails) => {
  return axiosInstance.put(`user-management/${userId}`, editDetails);
};
//delete user
export const deleteUserData = (id) => {
  return axiosInstance.delete(`user-management/${id}`);
};

// get statusdeviceList based on model
export const getstatusListOn_modal = (data) => {
  return axiosInstanceNous.post("/device/dashboard/status-count/list", data);
};
// send notification offine device
export const notificationToOfflineDevices = (data) => {
  return axiosInstance.post("notification/send", data);
};
// verify token
export const verifyResetToken = (token) => {
  return axiosInstance.get(`admin/verify-resetToken?token=${token}`);
};
 //  | Device setting parameter API
export const getSettingParameterAPI = (data) => {

  return axiosInstanceNous.get(`device/data/list-label/${data.type}`);
};
 // plug | post offlinealertTime or notification interval

 export const submitOfflineAlertOrNotificationInterval =(alertOrNotificationValue)=>{

  return axiosInstanceNous.post ("/device/configuration",alertOrNotificationValue)
 }
  // plug | get offlinealertTime or notification interval value
 export const getvalueOfOfflineAlertAndNotification =()=>{

  return axiosInstanceNous.get ("/device/configuration?type=smartplug")
 }

 //  get model from if all selcted for all devices 
 export const getModelFromAPI =(data)=>{
  
  return axiosInstanceNous.get (`/device/get-model/${data.type}/${data.serialNumber}`)
 }