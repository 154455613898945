import React from "react";
import { StabilizerUserDeviceListPageContextProvider } from "./StabilizerUserDeviceListView";
import StabilizerUserDeviceListPage from "./views/StabilizerUserDeviceList";
const StabilizerUserDeviceList =()=>{
    return(
    <StabilizerUserDeviceListPageContextProvider>
        <StabilizerUserDeviceListPage/>
    </StabilizerUserDeviceListPageContextProvider>
    )
}
export default StabilizerUserDeviceList