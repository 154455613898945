import React, { createContext, useReducer } from "react";
import {
  USER_ROLE_ID,
  PRODUCT_TYPE,
  SIDEMENU,
  UPDATE_ALL,
  DEVICE_STATUS,
} from "./type";
import { useEffect } from "react";

let initialState = {
  productName: "",
  productType: "",
  UserRoleId: "",
  deviceStatus: "",
};

//this prevents data loss on page refresh.
if (sessionStorage.getItem("nousPageRefresh")) {
  const newState = JSON?.parse(sessionStorage?.getItem("nousPageState"));
  if (newState && Object.keys(newState)?.length) {
    initialState = newState;
    sessionStorage.removeItem("nousPageState");
  }
  sessionStorage.removeItem("nousPageRefresh");
}

export const reducer = (state, action) => {
  switch (action.type) {
    case SIDEMENU:
     
      return {
        ...state,
        productName: action.payload.productName,
      };
    case PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload.productType,
      };
    case USER_ROLE_ID:
      return {
        ...state,
        UserRoleId: action.payload.UserRoleId,
      };

    case UPDATE_ALL:
      return {
        ...action.payload.newState,
      };
    case DEVICE_STATUS:
      return {
        ...state,
        deviceStatus: action.payload.deviceStatus,
      };

    default:
      break;
  }
};

export const GlobalStateContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeSideMenu = (data) => {
    dispatch({ type: "SIDEMENU", payload: { productName: data } });
  };

  const changeSideMenuBasedOnSession = () => {

    if (!state?.productName) {
      const sideMenuState = sessionStorage.getItem("productName");
      if (sideMenuState) {
        dispatch({ type: "SIDEMENU", payload: { productName: sideMenuState } });
      }
    }
  };
  //changeSideMenuBasedOnSession()

  const setProductType = (data) => {
 
    dispatch({ type: PRODUCT_TYPE, payload: { productType: data } });
  };

  //stores state to sessionStorage on refeshing a page , updates state once refresh is completed.
  //this prevents data loss on page refresh.
  useEffect(() => {
    const onRefresh = () => {
      sessionStorage.setItem("nousPageState", JSON.stringify(state));
      sessionStorage.setItem("nousPageRefresh", true);
    };
    window.addEventListener("beforeunload", onRefresh);

    return () => window.removeEventListener("beforeunload", onRefresh);
  }, [state]);

  const getUserRoleId = (RoleId) => {
    dispatch({ type: USER_ROLE_ID, payload: { UserRoleId: RoleId } });
  };
  const updateDeviceStatus = (data) => {
    dispatch({ type: DEVICE_STATUS, payload: { deviceStatus: data } });
  };
  return (
    <GlobalStateContext.Provider
      value={{
        state,
        dispatch,
        changeSideMenu,
        changeSideMenuBasedOnSession,
        setProductType,
        getUserRoleId,
        updateDeviceStatus,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
