import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import "./alertPopup.css";

const Alertpopup = ({
  showModal,
  setShowModal,
  buttonText,
  onClick,
  image,
  titleText,
  DetailText,
  modalType = false,
  alertButtonText,
  onclickModalContinue
}) => {
  const onclickModalCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="alert-component">
      <MDBModal
        show={showModal}
        setShow={setShowModal}
        backdrop="static"
        keyboard={false}
        tabIndex="-1"
      >
        <MDBModalDialog className="modal-width" centered>
          <MDBModalContent>
            <MDBModalBody className="alert-body">
              <img src={image} alt="Alert-image" />
              <p>{titleText}</p>
              <div className="detail-text">{DetailText}</div>
            </MDBModalBody>

            {/* <MDBModalFooter
              className={modalType === "success" ? "alert-footer" : ""}
            >
              {modalType === "success" ? (
                <ButtonComponent
                  text="Continue"
                  onClick={onclickModalCancel}
                  customClass="continue-button-width"
                  coloredButton={true}
                />
              ) : (
                <>
                  <ButtonComponent
                    text="Cancel"
                    onClick={onclickModalCancel}
                    customClass="button-width"
                  />

                  <ButtonComponent
                    text={buttonText}
                    coloredButton={true}
                    onClick={onClick}
                    customClass="button-width margin-left"
                  />
                </>
              )}
            </MDBModalFooter> */}
            <MDBModalFooter className={modalType ? "alert-footer" : ""}>
              {modalType == "successForm" ? (
                <ButtonComponent
                  text={alertButtonText}
                  onClick={onclickModalContinue}
                  customClass="continue-button-width"
                  coloredButton={true}
                />
              ) : modalType ? (
                <ButtonComponent
                  text={alertButtonText}
                  onClick={onclickModalCancel}
                  customClass="continue-button-width"
                  coloredButton={true}
                  
                />
              ) : (
                <>
                  <ButtonComponent
                    text="Cancel"
                    onClick={onclickModalCancel}
                    customClass="button-width"
                  />

                  <ButtonComponent
                    text={buttonText}
                    coloredButton={true}
                    onClick={onClick}
                    customClass="button-width margin-left"
                  />
                </>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default Alertpopup;
