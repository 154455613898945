import React, { createContext, useState } from "react";

import { useNavigate, useLocation } from "react-router";
import RoutConstants from "../../../constants/routeConstants.json";
export const RetrofitUserDeviceListPageContext = createContext({});
export const RetrofitUserDeviceListPageContextProvider = ({ children }) => {
  const [count, setCount] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const deviceList = location?.state?.result;
  const catogoryId = localStorage.getItem("dashboard_Id");
  
  const navigateToDeviceDashboard = (modelName) => {

      navigate({
        pathname: RoutConstants.RETROFIT_DEVICE_DATA,
        search: "?retrofit=singleRetrofit",
      });
   
   
 
    localStorage.setItem("model", modelName);
  };
  const providerValue = {
    count,
    deviceList,
    navigateToDeviceDashboard,
  };
  return (
    <RetrofitUserDeviceListPageContext.Provider value={providerValue}>
      {children}
    </RetrofitUserDeviceListPageContext.Provider>
  );
};
