import axios from "axios";
import { getCookie } from "../utils/service";

const axiosInstance = axios.create({
  baseURL :"https://smart20.vguard.in/v1/nous/"    // production url
//  baseURL: "https://nousdashboard.codelynks.in/stage/",
  //baseURL: "https://nousdashboard.codelynks.in/dev/",
});

const axiosInstanceNous = axios.create({
  baseURL:'https://nous20.vguard.in/'  // production url
 // baseURL: "https://nous.codelynks.in",
});

axiosInstance.interceptors.request.use((req) => {
  const accessToken = getCookie("x-access-token");
  req.headers.Authorization = accessToken ? `${accessToken}` : "";
  req.headers["Content-Type"] = "application/json";
  return req;
});
axiosInstanceNous.interceptors.request.use((req) => {
  const accessToken = getCookie("x-access-token");
  req.headers.Authorization = accessToken ? `${accessToken}` : "";
  req.headers["Content-Type"] = "application/json";
  return req;
});
export { axiosInstance, axiosInstanceNous };
