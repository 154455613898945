import React from 'react'
import { DeviceDataContextProvider } from './DeviceDataView'
import DeviceData from './views/DeviceData'

const DeviceDataPage = () => {
  return (
   <DeviceDataContextProvider>
    <DeviceData/>
   </DeviceDataContextProvider>
  )
}

export default DeviceDataPage
