import React from 'react'
import { FanDeviceDataContextProvider } from './FanDeviceDataView'
import FanDeviceData from './views/FanDeviceData'

const FanDeviceDataPage = () => {
  return (
   <FanDeviceDataContextProvider>
    <FanDeviceData/>
   </FanDeviceDataContextProvider>
  )
}

export default FanDeviceDataPage
