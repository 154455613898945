import React from "react";
import { StabilizerDashBoardContextProvider } from "./StabilizerDashboardView";
import StabilizerDashboardPage from "./views/StabilizerDasboard";

const StabilizerDashBoard = () => {
  return (
    <StabilizerDashBoardContextProvider>
      <StabilizerDashboardPage />
    </StabilizerDashBoardContextProvider>
  );
};

export default StabilizerDashBoard;