import React from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import "./alarmCondtions.css";
const AlarmCondtionsModal = ({ showModal, setShowModal, title, alarmData }) => {
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="alarm-modal">
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        onClickCancel={toggleModal}
        title={title}
        customWidth="modal-width"
        submitButtonDisable={true}
        cancelButtonText={"Close"}
        alarmStatus={true}
        cancelButtoncustomClass={"close-button"}
      >
        {alarmData?.map((item) => (
          <div className="alarm-modal-container mt-4">
            <div className="d-flex">
              <div className=" time-label">{item}</div>
            </div>
          </div>
        ))}
      </ModalComponent>
    </div>
  );
};

export default AlarmCondtionsModal;
