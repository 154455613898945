import React, { createContext, useState, useEffect } from "react";
import ContentFile from "../../constants/ContentFile.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPasswordApi, verifyResetToken } from "../../apis/Api";
import ContentData from "../../constants/ContentFile.json";
import RouteConstants from "../../constants/routeConstants.json";
import {
  capitalLetterValidate,
  lowercaseLetterValidate,
  singleNumber,
  miniCharacters,
  specialCharacter,
  spaceCheck,
  nonEmpty,
} from "../../utils/service";

export const ResetPasswordContext = createContext({});
export const ResetPasswordContextProvider = ({ children }) => {
  const PageContent = ContentFile.resetPassword;

  const [resetError, setResetError] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const [resetPassword, setResetPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [successModal, setSuccessModal] = useState(false);
  const [successMessage, SetSuccessMessage] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [ErrorMessage, setErroMessage] = useState("");

  const [spinner, setSpinner] = useState(false);

  const [token, setToken] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState();
  const [emptyNewPassword, setEmptyNewPassword] = useState(false);
  const [passWordMisMatch, setPasswordMisMatch] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [invalidResetPassword, setInvalidResetPassword] = useState(false);
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
  const [invalidPasswordMissMatch, setinvalidPasswordMissMatch] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState();
  // useNavigate
  const navigate = useNavigate();

  // Get token from url
  const [params] = useSearchParams();

  useEffect(() => {
    // Token value set to state

    const tokenValue = params.get("token");
    const id = params.get("id");
    const typeValue = params.get("type");
    setToken(tokenValue);
    setId(id);
    setType(typeValue);
  }, []);

  // reset password input onchange
  const resetPasswordChange = (e) => {
    setResetPassword(e.target.value);
    checkResetPassword(e.target.value);
  };

  //reset password validation
  const checkResetPassword = (password) => {
    if (password === "") {
      setResetError("Password required");
      return false;
    } else if (spaceCheck(password)) {
      setResetError("Blank passwords shall not be permitted");
    } else if (!capitalLetterValidate(password)) {
      setResetError("At least one capital letter required");
      return false;
    } else if (!lowercaseLetterValidate(password)) {
      setResetError("At least one small letter required");
      return false;
    } else if (!singleNumber(password)) {
      setResetError("At least one number required");
      return false;
    } else if (!specialCharacter(password)) {
      setResetError(
        "At least one special character required, ~, @,#,$, %,^, &,*,(,)"
      );
      return false;
    } else if (!miniCharacters(password)) {
      setResetError("Minimum of 8 characters required");
      return false;
    } else {
      setResetError("");
      return true;
    }
  };
  // Confirm password input onchange
  const confirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkConfirmPassword(e.target.value);
  };

  // Confirm password validation
  const checkConfirmPassword = (password) => {
    if (password === "") {
      setConfirmError(PageContent.confirmValidation);
      return false;
    } else if (password !== resetPassword) {
      setConfirmError(PageContent.newPasswordMtach);
      return false;
    } else {
      setConfirmError("");
      return true;
    }
  };

  // Submit function
  const onFromSubmit = async (e) => {
    e.preventDefault();
    const validResetPassword = checkResetPassword(resetPassword);
    const validConfirmPassword = checkConfirmPassword(confirmPassword);

    if (validResetPassword && validConfirmPassword) {
      if (resetPassword === confirmPassword) {
        setIsLoading(true);
        try {
          const response = await verifyResetToken(
            token
          );
          if (response.data.status === 200) {
            try {
              const submitPassword = await resetPasswordApi(
                token,
                id,
                type,
                confirmPassword
              );
              setIsLoading(false);
              if (submitPassword.data.status === 200) {
                setResponseStatus(submitPassword.data.status);
                setErrorAlertShow(true);
                setErrorAlertMessage(submitPassword.data.message);
                setPasswordMisMatch(false);
              }
              
            } catch (error) {
              setIsLoading(false);
              if (
                error.response.data.status === 401 ||
                error.response.data.status === 400
              ) {
                const errorMessage = error?.response?.data?.message;
                setResponseStatus(error.response.data.status);
                setErrorAlertMessage(errorMessage);
                setErrorAlertShow(true);
              } else {
                setResponseStatus(error.response.data.status);
                setIsLoading(false);
                setErrorAlertMessage("Something went wrong");
                setErrorAlertShow(true);
              }
            }
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          if (
            error.response.data.status === 401 ||
            error.response.data.status === 400
          ) {
            const errorMessage = error?.response?.data?.message;
            setResponseStatus(error.response.data.status);
            setErrorAlertMessage(errorMessage);
            setErrorAlertShow(true);
          } else {
            setResponseStatus(error.response.data.status);
            setIsLoading(false);
            setErrorAlertMessage("Something went wrong");
            setErrorAlertShow(true);
          }
        }
      } else {
        setIsLoading(false);
        setPasswordMisMatch(true);
        setinvalidPasswordMissMatch(PageContent.newPasswordMtach);
      }
    }
  };

  // Success Modal close
  const handleSucessClose = () => {
    setSuccessModal(false);
    navigate(RouteConstants.LOGIN);
  };

  // Error Modal close
  const handleErrorClose = () => {
    setErrorModal(false);
  };

  const providerValue = {
    PageContent,
    resetPasswordChange,
    confirmPasswordChange,
    onFromSubmit,
    resetError,
    confirmError,
    successModal,
    confirmPassword,
    errorModal,
    handleSucessClose,
    handleErrorClose,
    setErrorModal,
    resetPassword,
    successMessage,
    ErrorMessage,
    spinner,
    passwordType,
    setPasswordType,
    confirmPasswordType,
    setConfirmPasswordType,
    invalidResetPassword,
    setInvalidResetPassword,
    invalidConfirmPassword,
    setInvalidConfirmPassword,
    invalidPasswordMissMatch,
    setinvalidPasswordMissMatch,
    passWordMisMatch,
    isLoading,
    setIsLoading,
    errorAlertShow,
    setErrorAlertShow,
    errorAlertMessage,
    setErrorAlertMessage,
    responseStatus,
  };

  return (
    <ResetPasswordContext.Provider value={providerValue}>
      {children}
    </ResetPasswordContext.Provider>
  );
};
