import React, { useContext } from "react";
import { NotifyConfigurationContext } from "../NotifyConfigurationView";
import "./notifyConfiguration.css";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import InputComponent from "../../../components/InputComponent/InputComponent";
import infoIcon from "../../../assets/images/icons/info-button.svg";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import DropDownItem from "../../../components/DropDownComponent/DropDown";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import SuccessImage from "../../../assets/images/Success.svg";
import errorImage from "../../../assets/images/Disable.svg";
const NotifyConfiguration = () => {
  const {
    notificationInteval, //list of notification interval
    notificationIntervalTimeValue, // usestate value
    setNotificationIntervalTime, // function to set notification interval
    onNotificationIntervalChange, // function to change the notification
    alertInfoModalVisible, //   usestate value to visible  information on alerttime interval
    setAlertInfoModalVisible, // function  to set information on alerttime interval
    alertInfomationModalCancel, // function to cancel modal alertinterval
    showModalInfoAlertTimeInterval, // function show alert modal
    notificationInfoModalVisible, //usestate value to visible  information on notification interval
    setNotificationInfoModalVisible, // function  to set information on notification interval
    notificationInfomationModalCancel, // function to cancel modal alertinterval
    showModalNotificationInterval, // function show notification  modal
    offlineAlertValue,
    setOfflineAlertValue,
    submitAlertAndNotificationIntervalValue,
    showSuccesAlert, // to show succes alert
    setShowSuccesAlert, // function to show sucess alert
    errorAlertShow, // to show error alert
    setErrorAlertShow, // function to show error alert
    alertMessageShow, // show succes or failed msg
    setAlertMessageShow, //function to show message alert
    showSuccesIcon ,setShowSuccesIcon,
    offlineAlertRequired,
    cancelCLick
  } = useContext(NotifyConfigurationContext);
  return (
    <div className="plug-notify-configuration">
      <div className="notify-heading">Offline Alert</div>
      <div className="notify-main-div">
        <ThemeCard>
          <div className="input-Box-container">
            <MDBRow>
              <MDBCol size="md-4">
                <div className="notify-alert-box">
                  <label className="notify-label">Offline Alert</label>
                  <div className="notification-icon">
                    <img
                      className="notifyIcon-style"
                      src={infoIcon}
                      onClick={showModalInfoAlertTimeInterval}
                    />
                  </div>
                </div>
<div className="inputbox-alert-notify">


                <InputComponent 
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onChange={(e) => {
                    if (/^\d{0,2}$/.test(e.target.value)) {
                      setOfflineAlertValue(e.target.value);
                    }
                  }}
                  type="number"
                  value={offlineAlertValue}
                  maxLength={2}
                  customClass={
                    offlineAlertRequired ? "error-input-field" : ""
                  }
                ></InputComponent>
                </div>
              </MDBCol>
              <MDBCol size="md-4">
                <div className="notify-alert-box">
                  <label className="notify-label">Notification Interval</label>
                  <div className="notification-icon">
                    <img
                      className="notifyIcon-style"
                      src={infoIcon}
                      onClick={showModalNotificationInterval}
                    />
                  </div>
                </div>
                <DropDownItem
                  list={notificationInteval}
                  placeHolderValue={"Select Notification Interval"}
                  onSelect={(value) => {
                    onNotificationIntervalChange(value);
                  }}
                  selectedValue={notificationIntervalTimeValue}
                />
              </MDBCol>
            </MDBRow>
          </div>
        </ThemeCard>
        <div className="button-container">
          <ButtonComponent
            customClass={"notify-submit-button"}
            coloredButton={true}
            text={"Submit"}
            onClick={() => {
              submitAlertAndNotificationIntervalValue(
                offlineAlertValue,
                notificationIntervalTimeValue
              );
            }}
          />
          <ButtonComponent
            customClass={"cancel-button"}
            coloredButton={true}
            text={"Cancel"}
            onClick={cancelCLick}
          />
        </div>
      </div>
      <div className="badge-modal">
        <ModalComponent
          showModal={alertInfoModalVisible}
          setShowModal={setAlertInfoModalVisible}
          cancelButtonText={"Cancel"}
          onClickCancel={alertInfomationModalCancel}
          title={"Offline Alert"}
          customWidth="command-badge-modal-width"
        >
          <div className="calculation-description">The duration for which the device remains offline before triggering a notification.</div>
        </ModalComponent>
      </div>
      <div className="badge-modal">
        <ModalComponent
          showModal={notificationInfoModalVisible}
          setShowModal={setNotificationInfoModalVisible}
          cancelButtonText={"Cancel"}
          onClickCancel={notificationInfomationModalCancel}
          title={"Notification Interval"}
          customWidth="command-badge-modal-width"
        >
          <div className="calculation-description">The time frame within which a notification is sent to users.</div>
        </ModalComponent>

      </div>
      {showSuccesAlert && (
          <Alertpopup
          image={SuccessImage}
          setShowModal={setShowSuccesAlert}
          showModal={showSuccesAlert}
          titleText="Success"
          DetailText={alertMessageShow}
          modalType="successForm"
          alertButtonText="Continue"
          onclickModalContinue={() => {
            setShowSuccesAlert(false);
          }}
        >
          
        </Alertpopup>
      )}
        {errorAlertShow && (
          <Alertpopup
            showModal={errorAlertShow}
            setShowModal={setErrorAlertShow}
            modalType="error-modal"
            titleText={"Error"}
            DetailText={alertMessageShow}
            image={errorImage}
            alertButtonText={"Close"}
          ></Alertpopup>
        )}
    </div>
  );
};
export default NotifyConfiguration;
