import React, { useContext, useState } from "react";
import Logo from "../../../assets/images/VG-logo.svg";
import "./resetPassword.css";
import InputComponent from "../../../components/InputComponent/InputComponent";
import { ResetPasswordContext } from "../ResetPasswordView";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import EyeVisible from "../../../assets/images/icons/Password-show.svg";
import EyeInVisible from "../../../assets/images/icons/Password-hide.svg";

import Loader from "../../../components/LoaderComponent/LoaderComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import Error from "../../../assets/images/icons/Error.svg";
import SuccessImage from "../../../assets/images/Success.svg";
const ResetPasswordPage = () => {
  const {
    PageContent,
    resetPasswordChange,
    confirmPasswordChange,
    onFromSubmit,
    resetError,
    confirmError,
    confirmPassword,
    successModal,
    errorModal,
    handleSucessClose,
    handleErrorClose,
    setErrorModal,
    successMessage,
    resetPassword,
    ErrorMessage,
    spinner,
    passwordType,
    setPasswordType,
    confirmPasswordType,
    setConfirmPasswordType,
    invalidResetPassword,
    invalidConfirmPassword,
    passWordMisMatch,
    isLoading,
    errorAlertShow,
    setErrorAlertShow,
    errorAlertMessage,
    setErrorAlertMessage,
    responseStatus,
   
  } = useContext(ResetPasswordContext);
 
  return (
    <div className="reset-password-page">
      {isLoading ? <Loader /> : ""}
      <div className="auth-box col-lg-4 col-mg-6 col-sm-8 col-12">
        <div className="logo-div">
          <img src={Logo} alt="logo" />
        </div>
        {/* page title and sub-content */}
        <p className="mb-0 form-title">{PageContent.title}</p>

        <form className="mt-4" onSubmit={onFromSubmit}>
          <label className="input-label mt-3 mb-1">
            {PageContent.newPasswordLabel}
          </label>

          <div className="newpasswordInput">
            <InputComponent
              type={passwordType}
              value={resetPassword}
              className="input-item"
              placeholder={PageContent.newPasswordPlaceholder}
              onChange={resetPasswordChange}
            />
            <div className="eye-Icon-Vsisble-reset">
              {passwordType === "password" ? (
                <i className="bi bi-eye-slash">
                  <img
                    src={EyeInVisible}
                    onClick={() => {
                      setPasswordType("text");
                    }}
                  />
                </i>
              ) : (
                <i className="bi bi-eye-slash">
                  <img
                    src={EyeVisible}
                    onClick={() => {
                      setPasswordType("password");
                    }}
                  />
                </i>
              )}
            </div>
            {}
            {resetError ? (
              <div className="error-msg-reset"> {resetError}</div>
            ) : (
              ""
            )}
            <label className="input-label mt-4 mb-1">
              {PageContent.confirmPasswordLabel}
            </label>
          </div>
          <div className="newpasswordInput">
            <InputComponent
              type={confirmPasswordType}
              className="input-item"
              value={confirmPassword}
              placeholder={PageContent.confirmPasswordPlaceholder}
              onChange={confirmPasswordChange}
            />
            <div className="eye-Icon-Vsisble-reset">
              {confirmPasswordType === "password" ? (
                <i className="bi bi-eye-slash">
                  <img
                    src={EyeInVisible}
                    onClick={() => {
                      setConfirmPasswordType("text");
                    }}
                  />
                </i>
              ) : (
                <i className="bi bi-eye-slash">
                  <img
                    src={EyeVisible}
                    onClick={() => {
                      setConfirmPasswordType("password");
                    }}
                  />
                </i>
              )}
            </div>
            {confirmError ? (
              <div className="error-msg-reset"> {confirmError}</div>
            ) : (
              ""
            )}
            {passWordMisMatch ? (
              <div className="error-msg-reset"> {confirmError}</div>
            ) : (
              ""
            )}
          </div>
          <ButtonComponent
            text={"Submit"}
            coloredButton={true}
            customClass="button-login"
            buttonWidth="100%"
          />
        </form>
      </div>

      <Alertpopup
        image={responseStatus === 200 ? SuccessImage : Error}
        setShowModal={setErrorAlertShow}
        showModal={errorAlertShow}
        titleText={responseStatus === 200 ? "Success" : "Error"}
        DetailText={errorAlertMessage}
        modalType="successForm"
        alertButtonText="Continue"
        onclickModalContinue={()=>{
          
          setErrorAlertShow(false)
          handleSucessClose()
       }}
      ></Alertpopup>
      {/* sucessalert */}
    </div>
  );
};

export default ResetPasswordPage;
