import React, { useContext, useState } from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import InputComponent from "../../../components/InputComponent/InputComponent";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import { UserDetailsContext } from "../userDetailsView";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import AlertpopUp from "../../../components/AlertPopup/AlertPopup";
import ErrorIcon from "../../../assets/images/icons/Error.svg";

import "./userDetails.css";

const UserDetails = () => {
  const {
    showLoader,
    userDetails,
    roleName,
    onHandleCancel,
    showErrorAlert,
    setShowErrorAlert,
    ErrorAlertMessage,
  } = useContext(UserDetailsContext);
  return (
    <div className="user-details">
      {showLoader && <Loader />}
      <div className="page-title mb-4">User Details</div>
      <ThemeCard cardTitle="User Details">
        <MDBContainer fluid>
          <MDBRow>
            <MDBCol md={3} className="ps-0 mt-3">
              <label className="input-label">Name</label>
              <InputComponent
                value={userDetails?.name ? userDetails?.name : "-"}
                disabled={true}
              />
            </MDBCol>
            <MDBCol md={3} className="ps-0 mt-3">
              <label className="input-label">Email</label>
              <InputComponent
                value={userDetails?.email ? userDetails?.email : "-"}
                disabled={true}
              />
            </MDBCol>
            <MDBCol md={3} className="ps-0 mt-3">
              <label className="input-label">Role</label>
              <InputComponent
                value={roleName?.roleName ? roleName?.roleName : "-"}
                disabled={true}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </ThemeCard>
      <div className="button-align">
        <ButtonComponent text="Cancel" onClick={onHandleCancel} />
      </div>

      <AlertpopUp
        showModal={showErrorAlert}
        setShowModal={setShowErrorAlert}
        image={ErrorIcon}
        titleText="Error"
        DetailText={ErrorAlertMessage}
        modalType="Error"
        alertButtonText="Cancel"
      />
    </div>
  );
};

export default UserDetails;
