import React from 'react'
import { PlugDeviceLogContextProvider } from './PlugDeviceLogView'
import PlugDeviceLog from './views/PlugDeviceLog'

const PlugDeviceLogPage = () => {
  return (
    <PlugDeviceLogContextProvider>
      <PlugDeviceLog/>
    </PlugDeviceLogContextProvider>
  )}

export default PlugDeviceLogPage
