import React, { useContext, useEffect, useState } from "react";

// icons import -----------------------------------------------------
import ToggleArrow from "../../assets/images/icons/Toggle-arrow.svg"; // sidemenu toggle icon
import Dashboard from "../../assets/images/icons/Dashboard.svg";
import DeviceControl from "../../assets/images/icons/Device-control.svg";
import Trends from "../../assets/images/icons/Trends.svg";
import Data from "../../assets/images/icons/Data.svg";
import Alert from "../../assets/images/icons/Notification.svg";
import DeviceConnectivity from "../../assets/images/icons/deviceConnectivity.svg";
import DevicePower from "../../assets/images/icons/DevicePower.svg";
import SendCommand from "../../assets/images/icons/SendCommand.svg";
import UserManagement from "../../assets/images/icons/usermanagment.svg";
// ---------------------------------------------------------------------
import ContentFile from "../../constants/ContentFile.json";
import "./sidemenu.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GlobalStateContext } from "../../store/store";
import routeConstant from "../../constants/routeConstants.json";
import { getCookie } from "../../utils/service";
import packageJson from "../../../package.json";
import FanDashboard from "../../assets/images/icons/SendCommand.svg";
import WaterHeaterDashboard from "../../assets/images/icons/SendCommand.svg";
import StabilizerDashboard from "../../assets/images/icons/SendCommand.svg";
import Retrofitashboard from  "../../assets/images/icons/SendCommand.svg" 
import DupsDashboard from "../../assets/images/icons/SendCommand.svg" 
const Sidemenu = ({ sidemenuToggle }) => {
  const [collapseActive, setCollapseActive] = useState(false);
  const { state, changeSideMenu, changeSideMenuBasedOnSession } =
    useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState();

  const [searchParams] = useSearchParams();

  const allowLogin = getCookie("x-access-token");
  const userRoleId = state.UserRoleId;
  
  useEffect(() => {
    changeSideMenuBasedOnSession();
    !allowLogin && navigate(routeConstant?.LOGIN);
  }, []);
  // change the sidebar based on brower forward and backward click
  useEffect(() => {

    if (searchParams.get("plug") === "Plug") {
  
      if (userRoleId === 3) {
        const filteredMenuList = ContentFile.sideMenuList.filter(
          (menuItem) =>
            menuItem.name !== "Device Control" &&
            menuItem.name !== "Dashboard User Management"
        );
        setMenuList(filteredMenuList);
      } else if (userRoleId === 2) {
   
        const filteredMenuList = ContentFile.sideMenuList.filter(
          (menuItem) => menuItem.name !== "Dashboard User Management"
        );
      
        setMenuList(filteredMenuList);
      } else {
        setMenuList(ContentFile.sideMenuList);
      }
    } else if (searchParams.get("plug") === "singlePlug") {
      if (userRoleId === 3) {
      
        const filteredMenuList = ContentFile.plugSidemenu.filter(
          (menuItem) => menuItem.name !== "Send Command"
        );
        setMenuList(filteredMenuList);
      } else {
        setMenuList(ContentFile.plugSidemenu);
      }
    }
    // fan
     if(searchParams.get("fan") === "Fan"){
      
      if (userRoleId === 2){
      
        const filteredMenuList = ContentFile.FansidemenuList.filter(
          (menuItem) => menuItem.name !== "Send Command"
        );
       
        setMenuList(filteredMenuList);
        } else if (userRoleId === 1) {
          
          setMenuList(ContentFile.FansidemenuList);
         }else  if  (userRoleId === 3){
          setMenuList(ContentFile.FansidemenuList);
         }
         else{
          setMenuList(ContentFile.FanSideMenuInsideData);
         }
    }else if (searchParams.get("fan") === "singleFan") {
     
      if (userRoleId === 3) {

        // const filteredMenuList = ContentFile.FansidemenuList.filter(
        //   (menuItem) => menuItem.name !== "Send Command"
        // );
        setMenuList(ContentFile.FanSideMenuInsideData);
      } else {
        setMenuList(ContentFile.FanSideMenuInsideData);
      }
    }
    // water heater
    if(searchParams.get("waterheater") === "waterheater"){
  
      if (userRoleId === 2){
  
        const filteredMenuList = ContentFile.WaterheatermenuList.filter(
          (menuItem) => menuItem.name !== "Send Command"
        );
       
        setMenuList(filteredMenuList);
        } else if(userRoleId === 1) {
         
          setMenuList(ContentFile.WaterheatermenuList);
         }
         else  if  (userRoleId === 3){
          setMenuList(ContentFile.WaterheatermenuList);
         }
         else{
          setMenuList(ContentFile.WaterheaterSideMenuInsideData);
         }
    }else if (searchParams.get("waterheater") === "singleWaterheater") {
     
      if (userRoleId === 3) {
 
      
        setMenuList(ContentFile.WaterheaterSideMenuInsideData);
      } else {

        setMenuList(ContentFile.WaterheaterSideMenuInsideData);
      }
    }
    // stabilizer
    if(searchParams.get("stabilizer") === "stabilizer"){
  
      if (userRoleId === 2){
  
        const filteredMenuList = ContentFile.StabilizermenuList.filter(
          (menuItem) => menuItem.name !== "Send Command"
        );
       
        setMenuList(filteredMenuList);
      } else if(userRoleId === 1) {
         
        setMenuList(ContentFile.StabilizermenuList);
       }
       else  if  (userRoleId === 3){
        setMenuList(ContentFile.StabilizermenuList);
       }
       else{
        setMenuList(ContentFile.StabilizerSideMenuInsideData);
       }
       
    }else if (searchParams.get("stabilizer") === "singleStabilizer") {
      if (userRoleId === 3) {
 
        // const filteredMenuList = ContentFile.plugSidemenu.filter(
        //   (menuItem) => menuItem.name !== "Send Command"
        // );
         setMenuList(ContentFile.StabilizerSideMenuInsideData);
      } else {
        setMenuList(ContentFile.StabilizerSideMenuInsideData);
      }
    }
    // retrofit 
    if(searchParams.get("retrofit") === "retrofit"){
  
      if (userRoleId === 2){
  
        const filteredMenuList = ContentFile.RetrofitmenuList.filter(
          (menuItem) => menuItem.name !== "Send Command"
        );
       
        setMenuList(filteredMenuList);
      } else if(userRoleId === 1) {
         
        setMenuList(ContentFile.RetrofitmenuList);
       }
       else  if  (userRoleId === 3){
        setMenuList(ContentFile.RetrofitmenuList);
       }
       else{
        setMenuList(ContentFile.RetrofitSideMenuInsideData);
       }
       
    }else if (searchParams.get("retrofit") === "singleRetrofit") {
      if (userRoleId === 3) {
 
        // const filteredMenuList = ContentFile.plugSidemenu.filter(
        //   (menuItem) => menuItem.name !== "Send Command"
        // );
        setMenuList(ContentFile.RetrofitSideMenuInsideData);
      } else {
        setMenuList(ContentFile.RetrofitSideMenuInsideData);
      }
    }
    // dups
    if(searchParams.get("Inverter") === "Inverter"){
  
      if (userRoleId === 2){
  
        const filteredMenuList = ContentFile.DupsmenuList.filter(
          (menuItem) => menuItem.name !== "Send Command"
        );
       
        setMenuList(filteredMenuList);
      } else if(userRoleId === 1) {
         
        setMenuList(ContentFile.DupsmenuList);
       }
       else  if  (userRoleId === 3){
        setMenuList(ContentFile.DupsmenuList);
       }
       else{
        setMenuList(ContentFile.DupsSideMenuInsideData);
       }
       
    }else if (searchParams.get("Inverter") === "singleInverter") {
      if (userRoleId === 3) {
 
        // const filteredMenuList = ContentFile.plugSidemenu.filter(
        //   (menuItem) => menuItem.name !== "Send Command"
        // );
        setMenuList(ContentFile.DupsSideMenuInsideData);
      } else {
        setMenuList(ContentFile.DupsSideMenuInsideData);
      }
    }
  }, [state.productName, window.location.href]);

  // const menuList = ContentFile.plugDashboardMenu;
  // const navigate = useNavigate();
  // icon listing
  const menuIconlist = [
    {
      icon: Dashboard,
      label: "Dashboard",
    },
    {
      icon: UserManagement,
      label: "Dashboard User Management",
    },
    {
      icon: DeviceControl,
      label: "Device Control",
    },
    {
      icon: Trends,
      label: "Trends",
    },
    {
      label: "Device Dashboard",
      icon: Dashboard,
    },
    {
      label: "Data",
      icon: Data,
    },
    {
      label: "Alerts",
      icon: Alert,
    },
    {
      label: "Device Connectivity",
      icon: DeviceConnectivity,
    },
    {
      label: "Device Power",
      icon: DevicePower,
    },
    {
      label: "Send Command",
      icon: SendCommand,
    },
    {
      label: "Dashboard",
      icon: FanDashboard,
    },
    {
      label: "Notification Configuration",
      icon: Alert,
    },
    {
      label: "Dashboard",
      icon: WaterHeaterDashboard,
    },
    {
      label: "Dashboard",
      icon: StabilizerDashboard,
    },
    {
      label: "Dashboard",
      icon: Retrofitashboard,
    },
    {
      label: "Dashboard",
      icon: DupsDashboard,
    },
  ];

  // Function to find icon fom the iconlist and return it
  const getIcon = (iconName) => {
    const iconImage = menuIconlist?.find((item) => item?.label === iconName);
    return iconImage.icon;
  };

  // side menu collapse function
  const sidemenuCollapse = (e) => {
    setCollapseActive(!collapseActive);
    sidemenuToggle(!collapseActive);
  };
  // sidemenu page navigation
  const navigateToPage = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const location = useLocation();

  // sidemenu page navigation
  return (
    <div className="sidemenu-page ">
      <div className={collapseActive ? "width-sm" : "width-lg"}></div>
      <nav id="sidebar" className={collapseActive ? "active" : ""}>
        <button type="button" onClick={sidemenuCollapse} className="navbar-btn">
          <img src={ToggleArrow} alt="toggle-arrow" />
        </button>
        <ul>
          {menuList?.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  navigateToPage(item?.path);
                }}
                className={
                  "/" + location.pathname.split("/")?.[1] ===
                  item?.path?.split("?")[0]
                    ? "active-sidebar"
                    : ""
                }
              >
                <img
                  src={getIcon(item?.name ? item?.name : "")}
                  alt="menu-icon"
                />
                <span>{item.name}</span>
              </li>
            );
          })}
        </ul>
        <div className={collapseActive ? "hide-version" : "version-box"}>
          <div>Current Version</div>
          <div className="version-number">{packageJson?.version}</div>
        </div>
      </nav>
    </div>
  );
};

export default Sidemenu;
