import React, { useState } from "react";
import "./chartComponent.css";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";

import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const MixedChart = ({
  data,
  clickLegend,
  legendMangement,
  stepSize,
  legendShow,
  customLegendClass,
  manageClickStatus = false,
  showSrollableBarChart,
}) => {
  const getUnitForLabel = (label) => {
    switch (label) {
      case "Energy":
        return " kWh";
      case "Voltage":
        return "V";
      case "Current":
        return "A";
      default:
        return ""; // Default case, no unit
    }
  };

  let tooltipLabel = "";
  let tooltipTittle = "";
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        grid: {
          drawOnChartArea: true,
          borderWidth: 10,
          drawTicks: false,
        },
        border: {
          dash: [10, 5],
        },

        ticks: {
          stepSize: stepSize,
          padding: 10,
          font: {
            size: 12,
            family: "'Inter', 'sans-serif'",
          },
          color: ["#4F4F4F"],
        },
      },
      x: {
        beginAtZero: false,
        grid: {
          borderWidth: 0,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          padding: 10,
          font: {
            size: 12,
            family: "'Inter', 'sans-serif'",
          },
          color: ["#4F4F4F"],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        backgroundColor: "#69b84f",
        // Set tooltip background color
        // bodyColor: "white",
        padding: {
          left: 20,
          right: 20,
          top: 10,
          bottom: 10,
        },
        showShadow: true,
        backgroundShadowColor: "black",
        shadowBlur: 3,
        shadowOffsetX: 3,
        shadowOffsetY: 10,

        displayColors: false,
        yAlign: "bottom",
        labelTextColor: "red",

        bodyFont: {
          // Set the font weight of the tooltip values (data)

          size: 12, // Customize the body font size
          weight: "bold", // Customize the font weight (normal, bold)
          family: "'Inter', 'sans-serif'",
        },

        callbacks: {
          title: function (context) {
            const datasetLabel = context.label;
            return "";
          },
          label: function (data) {
            const datasetLabel = data.dataset.label;
            const dataValue = data.parsed.y;

            if (data.label && datasetLabel) {
              tooltipLabel = `${datasetLabel} (${dataValue}${getUnitForLabel(
                datasetLabel
              )})`;
            } else {
              tooltipLabel = `${data.label} , ${dataValue}`;
            }

            return tooltipLabel;
          },
        },
      },

      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          boxHeight: 8,
          useBorderRadius: true,
          pointStyle: "circle",
          borderWidth: 0,
          fill: true,
        },
        position: "bottom",
        display: false,
      },

      datalabels: {
        display: false,
      },
    },
  };
  return (
    <div className="legend-container">
      <div
        style={{ height: "350px" }}
        className={showSrollableBarChart ? "chartAreaWrapper" : ""}
      >
        <Chart type="bar" data={data} options={options} />
      </div>

      {legendShow ? (
        <div className={`custom-legend ${customLegendClass}`}>
          {data.datasets.map((dataset, index) => (
            <button
              key={index}
              className={
                manageClickStatus
                  ? `legend-item-${legendMangement[index + 1]}`
                  : "legend-item"
              }
              onClick={() => {
                dataset.id && clickLegend(dataset.id);
              }}
            >
              <span
                className="legend-color"
                style={{ backgroundColor: dataset.backgroundColor }}
              ></span>
              {dataset.label}
            </button>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MixedChart;
