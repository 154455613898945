import React, { useContext } from "react";
import { ForgotPasswordContext } from "../FrogotPasswordView";
import Logo from "../../../assets/images/VG-logo.svg";
import InputComponent from "../../../components/InputComponent/InputComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import "./forgotPassword.css";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import SuccessIcon from "../../../assets/images/Success.svg";
import ErrorIcon from "../../../assets/images/icons/Error.svg";

export const Forgotpassword = () => {
  const {
    pageContent, // page static text
    onChangeEmailInput, // on change email inpput
    email, // state to store email
    emailvalidationMsg, // state to store email validation message
    onHandleBackToLogin, // function for redirect to login
    onHandleSubmitEmail, // on click submit email
    showLoader, // state to show loader
    SubmitSuccessModal, // submit success modal state
    setSubmitSuccessModal, // submit success modal set state
    modalSucsessMessage, // success modal message
    SubmitErrorModal, // submit error modal state
    setSubmitErrorModal, // submit error modal set state
    modalErrorMessage, //  modal error message
  } = useContext(ForgotPasswordContext);
  return (
    <>
      <div className="forgot-password-page container">
        {showLoader && <Loader />}
        {/* white box  div starts */}
        <div className="auth-box col-lg-5 col-mg-6 col-sm-8 col-12">
          {/* Logo div starts */}
          <div className="logo-div">
            <img src={Logo} alt="logo" />
          </div>
          {/* Logo div ends */}

          {/* page title and sub-content */}
          <p className="mb-0 form-title">{pageContent.pageTitle}</p>
          <p className="forgot-text mt-3">{pageContent.forgotNote}</p>

          {/* Form starts */}

          <form onSubmit={onHandleSubmitEmail}>
            <label className="input-label mt-3 mb-1">
              {pageContent.forgotEmailLabel}
            </label>

            <InputComponent
              placeholder={pageContent.forgotEmailPlaceholder}
              onChange={onChangeEmailInput}
              value={email}
              customClass="input-item"
            />
            <p className="mb-0 error-msg">
              {emailvalidationMsg ? emailvalidationMsg : ""}
            </p>
            <ButtonComponent
              coloredButton
              text="Submit"
              buttonWidth="100%"
              customClass="mt-4"
              type="submit"
            />
            <div className="text-center mt-4">
              <span className="back-login" onClick={onHandleBackToLogin}>
                {pageContent.backToLogin}
              </span>
            </div>
          </form>
          {/* Form ends */}
        </div>
        {/* white box  div ends */}

        {/* Success alert pop */}
        <Alertpopup
          showModal={SubmitSuccessModal}
          setShowModal={setSubmitSuccessModal}
          alertButtonText="Continue"
          image={SuccessIcon}
          titleText="Success"
          DetailText={modalSucsessMessage}
          modalType="success"
        />

        {/* Success alert pop */}
        <Alertpopup
          showModal={SubmitErrorModal}
          setShowModal={setSubmitErrorModal}
          alertButtonText="Cancel"
          image={ErrorIcon}
          titleText="Error"
          DetailText={modalErrorMessage}
          modalType="error"
        />
      </div>
    </>
  );
};
