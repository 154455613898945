import React, { useContext, useEffect } from "react";
import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import RouteConstants from "./constants/routeConstants.json";
import DeviceControl from "./containers/DeviceControlPage";
import PlugTrendsPage from "./containers/PlugTrendsPage";
import DashBoard from "./containers/DashBoard";
import HomePage from "./containers/HomePage";
import DeviceDataPage from "./containers/DeviceDataPage";

import PlugDashBoard from "./containers/PlugDasboard";
import DeviceConnectivityPage from "./containers/DeviceConnectivityPage";
import DeviceAlertPage from "./containers/DeviceAlertPage";
import SendCommand from "./containers/SendCommand";
import PlugPowerPage from "./containers/PlugPowerStatus";
import PlugDeviceLogPage from "./containers/PlugDeviceLogs";
import UserDeviceListPage from "./containers/UserDeviceList";
import CreateUserManagement from "./containers/CreateUserManagment";
import { GlobalProvider, GlobalStateContext } from "./store/store";
import UserManagementPage from "./containers/UserManagement";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPasswordPage";
import ForgotPasswordPage from "./containers/ForgotPassword";
import UserDetailsPage from "./containers/UserDetails";
import RouteProtection from "./containers/RouteProtection";
import AuthRouteProtection from "./containers/AuthRouteProtection";
import PageNotFound from "./containers/PageNotFound/views/PageNotFound";
import StatusDeviceList from "./containers/StatusDeviceList";
import FanDashBoard from "./containers/Fan/FanDashboard";
import FanDeviceDataPage from "./containers/Fan/FanDeviceDataPage";
import FanUserDeviceList from "./containers/Fan/FanUserDeviceList";
import WaterHeaterDashBoard from "./containers/WaterHeater/WaterHeaterDashboard";
import NotifyConfigurationPage from "./containers/NotifyConfiguration";
import WaterHeaterDeviceDataPage from "./containers/WaterHeater/WaterHeaterDeviceDataPage";
import WaterHeaterUserDeviceList from "./containers/WaterHeater/WaterHeaterUserDeviceList";
import StabilizerDashBoard from "./containers/Stabilizer/StabilizerDashboard";
import StabilizerDeviceDataPage from "./containers/Stabilizer/StabilizerDeviceDataPage";
import StabilizerUserDeviceList from "./containers/Stabilizer/StabilizerUserDeviceList"
import RetrofitDashBoard from "./containers/Retrofit/RetrofitDashboard";
import RetrofitDeviceDataPage from "./containers/Retrofit/RetrofitDeviceDataPage";
import RetrofitUserDeviceList from "./containers/Retrofit/RetrofitUserDeviceList";
import DupsDashBoard from "./containers/DUPS/DupsDashboard";
import DupsDeviceDataPage from "./containers/DUPS/DupsDeviceDataPage";
import DupsUserDeviceList from "./containers/DUPS/DupsUserDeviceList";
const App = () => {
  return (
    <GlobalProvider>
      <HashRouter>
        <Routes>
          <Route element={<AuthRouteProtection />}>
            <Route path={RouteConstants.LOGIN} element={<Login />} />
            <Route
              path={RouteConstants.RESET_PASSWORD}
              element={<ResetPassword />}
            />
            <Route path={RouteConstants.HOME} element={<HomePage />}></Route>
            <Route
              path={RouteConstants.FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            />

            <Route
              path={RouteConstants.PAGE_NOT_FOUND}
              element={<PageNotFound />}
            />
            <Route element={<RouteProtection />}>
              <Route element={<Layout />}>
                <Route
                  path={RouteConstants.DEVICE_CONTROL}
                  element={<DeviceControl />}
                ></Route>
                <Route
                  path={RouteConstants.DASHBOARD}
                  element={<DashBoard />}
                ></Route>
                <Route
                  path={RouteConstants.PLUG_DASHBOARD}
                  element={<PlugDashBoard />}
                ></Route>
                <Route
                  path={RouteConstants.DEVICE_DATA}
                  element={<DeviceDataPage />}
                ></Route>
                <Route
                  path={RouteConstants.DEVICE_CONNECTIVITY}
                  element={<DeviceConnectivityPage />}
                ></Route>
                <Route
                  path={RouteConstants.DEVICE_ALERT}
                  element={<DeviceAlertPage />}
                ></Route>
                <Route
                  path={RouteConstants.SEND_COMMAND}
                  element={<SendCommand />}
                ></Route>
                <Route
                  path={RouteConstants.PLUG_POWER_STATUS}
                  element={<PlugPowerPage />}
                ></Route>
                <Route
                  path={RouteConstants.PLUG_TRENDS}
                  element={<PlugTrendsPage />}
                ></Route>
                <Route
                  path={RouteConstants.DEVICE_LOGS}
                  element={<PlugDeviceLogPage />}
                ></Route>
                <Route
                  path={RouteConstants.USER_DEVICE_LIST}
                  element={<UserDeviceListPage />}
                ></Route>
                <Route
                  path={RouteConstants.USER_MANAGEMENT}
                  element={<UserManagementPage />}
                ></Route>
                <Route
                  path={RouteConstants.USER_MANAGEMENT_CREATE}
                  element={<CreateUserManagement />}
                ></Route>
                <Route
                  path={RouteConstants.USER_DETAILS}
                  element={<UserDetailsPage />}
                />
                <Route
                  path={RouteConstants.STATUS_DEVICE_LIST}
                  element={<StatusDeviceList />}
                />
                <Route
                  path={RouteConstants.FAN_DASHBOARD}
                  element={<FanDashBoard />}
                />
                <Route
                  path={RouteConstants.FAN_DEVICE_DATA}
                  element={<FanDeviceDataPage />}
                />
                <Route
                  path={RouteConstants.FAN_USER_DEVICE_LIST}
                  element={<FanUserDeviceList />}
                />
                <Route
                  path={RouteConstants.WATER_HEATER_DASHBOARD}
                  element={<WaterHeaterDashBoard />}
                />
                <Route
                  path={RouteConstants.NOTIFY_CONFIGURATION}
                  element={<NotifyConfigurationPage />}
                />
                <Route
                  path={RouteConstants.WATER_HEATER_DEVICE_DATA}
                  element={<WaterHeaterDeviceDataPage />}
                />
                <Route
                  path={RouteConstants.WATER_HEATER_USER_DEVICE_LIST}
                  element={<WaterHeaterUserDeviceList />}
                />
                <Route
                  path={RouteConstants.STABILIZER_DASHBOARD}
                  element={<StabilizerDashBoard />}
                />
                 <Route
                  path={RouteConstants.STABILIZER_DEVICE_DATA}
                  element={<StabilizerDeviceDataPage />}
                />
                 <Route
                  path={RouteConstants.STABILIZER_USER_DEVICE_LIST}
                  element={<StabilizerUserDeviceList />}
                />
                 <Route
                  path={RouteConstants.RETROFIT_DASHBOARD}
                  element={<RetrofitDashBoard/>}
                />
                    <Route
                  path={RouteConstants.RETROFIT_DEVICE_DATA}
                  element={<RetrofitDeviceDataPage/>}
                />
                 <Route
                  path={RouteConstants.RETROFIT_USER_DEVICE_LIST}
                  element={<RetrofitUserDeviceList/>}
                />
                  <Route
                  path={RouteConstants.DUPS_DASHBOARD}
                  element={<DupsDashBoard/>}
                />
                  <Route
                  path={RouteConstants.DUPS_DEVICE_DATA}
                  element={<DupsDeviceDataPage/>}
                />
                <Route
                  path={RouteConstants.DUPS_USER_DEVICE_LIST}
                  element={<DupsUserDeviceList/>}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </HashRouter>
    </GlobalProvider>
  );
};

export default App;
