import React from "react";
import { UserDeviceListPageContextProvider } from "./UserDeviceListView";
import UserDeviceListPage from "./views/UserDeviceList";
const UserDeviceList =()=>{
    return(
    <UserDeviceListPageContextProvider>
        <UserDeviceListPage/>
    </UserDeviceListPageContextProvider>
    )
}
export default UserDeviceList