import React from 'react'
import { StatusDeviceListContextProvider } from "./StatusDeviceListView";
import StatusDeviceListPage from "./views/StatusDeviceList";

const StatusDeviceList =()=>{
    return (
        <StatusDeviceListContextProvider>
          <StatusDeviceListPage />
        </StatusDeviceListContextProvider>
      );
}
export default StatusDeviceList