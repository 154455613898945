import React, { useState } from "react";
import Topbar from "../Topbar/Topbar";
import Sidemenu from "../Sidemenu/Sidemenu";
import { Outlet } from "react-router-dom";

import "./layout.css";
const Layout = () => {
  const [layoutManagement, setLayoutManagement] = useState(false);

  const toggleSidemenu = (value) => {
    setLayoutManagement(value);
  };
  return (
    <div className="layout-page">
      <Topbar />
      <div className="wrapper">
        <Sidemenu sidemenuToggle={toggleSidemenu} />
        <div
          id="content"
          className={layoutManagement === true ? "content-sm" : "content-lg"}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
