import React, { createContext, useEffect, useState, useContext } from "react";
import {
  submitOfflineAlertOrNotificationInterval,
  getvalueOfOfflineAlertAndNotification,
} from "../../apis/Api";
import { useNavigate } from "react-router";

export const NotifyConfigurationContext = createContext({});
export const NotifyConfigurationContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [offlineAlertValue, setOfflineAlertValue] = useState("");
  const [notificationIntervalTimeValue, setNotificationIntervalTimeValue] =
    useState("");
  const [alertInfoModalVisible, setAlertInfoModalVisible] = useState(false);
  const [notificationInfoModalVisible, setNotificationInfoModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccesAlert, setShowSuccesAlert] = useState(false);
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [alertMessageShow, setAlertMessageShow] = useState(false);
  const [showSuccesIcon, setShowSuccesIcon] = useState(false);
  const [offlineAlertRequired, setOfflineAlertRequired] = useState(false);
  // notification interval list
  const notificationInteval = [
    { id: 1, name: "6 Hours" },
    { id: 2, name: "12 Hours" },
    { id: 3, name: "24 Hours" },
  ];
  // function to change the notification
  const onNotificationIntervalChange = (value) => {
    setNotificationIntervalTimeValue(value?.name);
  };
  //
  const showModalInfoAlertTimeInterval = (time) => {
    setAlertInfoModalVisible(true);
  };
  // cancel  modal
  const alertInfomationModalCancel = () => {
    setAlertInfoModalVisible(false);
  };
  const showModalNotificationInterval = (time) => {
    setNotificationInfoModalVisible(true);
  };

  const notificationInfomationModalCancel = () => {
    setNotificationInfoModalVisible(false);
  };

  const submitAlertAndNotificationIntervalValue = async (
    offlineAlertValue,
    notificationIntervalTimeValue
  ) => {
    setLoading(true);
    if (offlineAlertValue === "") {
      setOfflineAlertRequired(true);
      return;
    } else {
      const alertOrNotificationValue = {
        offlineThreshold: offlineAlertValue,
        fcmThreshold: notificationIntervalTimeValue.substring(0, 2).trim(),
        type: "smartplug",
      };

      try {
        const response = await submitOfflineAlertOrNotificationInterval(
          alertOrNotificationValue
        );
        if (response.data.status === 200) {
          setShowSuccesAlert(true);
          setAlertMessageShow(response.data.message);
          setLoading(false);
          setOfflineAlertRequired(false);
        }
      } catch (error) {
        setLoading(false);
        if (
          error?.response?.data?.status === 400 ||
          error?.response?.data?.status === 401
        ) {
          setLoading(false);
          const errorMessage = error?.response?.data?.message;
          setAlertMessageShow(errorMessage);
          setErrorAlertShow(true);
        } else {
          setLoading(false);
          setAlertMessageShow("Something went wrong...");
          setErrorAlertShow(true);
        }
      }
    }
  };
  const getAlertAndNotificationIntervalValue = async () => {
    setLoading(true);

    try {
      const response = await getvalueOfOfflineAlertAndNotification();
      if (response.data.status === 200) {
        setOfflineAlertValue(response.data.data.offlineThreshold);
        setNotificationIntervalTimeValue(response.data.data.fcmThreshold);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        setLoading(false);
        const errorMessage = error?.response?.data?.message;
        setAlertMessageShow(errorMessage);
        setErrorAlertShow(true);
      } else {
        setLoading(false);
        setAlertMessageShow("Something went wrong...");
        setErrorAlertShow(true);
      }
    }
  };

  // on cancel click 
 const  cancelCLick =()=>{
  navigate(-1)
 }

  useEffect(() => {

    getAlertAndNotificationIntervalValue();
  }, []);
  const providerValue = {
    notificationInteval, // list of notification interval
    notificationIntervalTimeValue, // usestate value
    setNotificationIntervalTimeValue, // function to set notification interval
    onNotificationIntervalChange, // function to change the notification
    alertInfoModalVisible, //   usestate value to visible  information on alerttime interval
    setAlertInfoModalVisible, // function  to set information on alerttime interval
    alertInfomationModalCancel, // function to cancel modal alertinterval
    showModalInfoAlertTimeInterval, // function show alert modal
    notificationInfoModalVisible, //usestate value to visible  information on notification interval
    setNotificationInfoModalVisible, // function  to set information on notification interval
    notificationInfomationModalCancel, // function to cancel modal alertinterval
    showModalNotificationInterval, // function show notification  modal
    offlineAlertValue, //oofline value
    setOfflineAlertValue, //function to set offlinealert value
    submitAlertAndNotificationIntervalValue, // function to set value of offline alert and notificataion interval
    showSuccesAlert, // to show succes alert
    setShowSuccesAlert, // function to show sucess alert
    errorAlertShow, // to show error alert
    setErrorAlertShow, // function to show error alert
    alertMessageShow, // show succes or failed msg
    setAlertMessageShow, //function to show message alert
    showSuccesIcon,
    setShowSuccesIcon,
    getAlertAndNotificationIntervalValue, // get  function on load
    setOfflineAlertRequired,
    offlineAlertRequired,  // validation feild for offline alert is empty
    cancelCLick // cancel clik
  };
  return (
    <NotifyConfigurationContext.Provider value={providerValue}>
      {children}
    </NotifyConfigurationContext.Provider>
  );
};
