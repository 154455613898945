import React from "react";
import { FanDashBoardContextProvider } from "./FanDashboardView";
import FanDashboardPage from "./views/FanDasboard";

const FanDashBoard = () => {
  return (
    <FanDashBoardContextProvider>
      <FanDashboardPage />
    </FanDashBoardContextProvider>
  );
};

export default FanDashBoard;