import React from "react";
import { DashBoardContextProvider } from "./DashBoardView";
import DashBoardPage from "./views/DashBoard";

const DashBoard = () => {
  return (
    <DashBoardContextProvider>
      <DashBoardPage />
    </DashBoardContextProvider>
  );
};

export default DashBoard;
