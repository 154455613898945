import React from "react";

import "./pageNotFound.css";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import IconImage from "../../../assets/images/page-not-found.svg";
import { useNavigate } from "react-router-dom";
import contentFile from "../../../constants/ContentFile.json";
const PageNotFound = () => {
  const navigate = useNavigate();
  const onHandleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="page-not-found-page">
      <div className="auth-box col-lg-4 col-mg-6 col-sm-8 col-12">
        <div className="logo-div">
          <img src={IconImage} alt="logo" />
        </div>
        <h3 className="title">{contentFile.pageNotFound.pageNotFound}</h3>
        <p>{contentFile.pageNotFound.pageNotFoundDetailedText}</p>
        <ButtonComponent
          coloredButton
          text="Go Back"
          buttonWidth="100%"
          customClass="mt-4"
          onClick={onHandleGoBack}
        />
      </div>
    </div>
  );
};

export default PageNotFound;
