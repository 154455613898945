import React from "react";
import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Col, Row, Table } from "react-bootstrap";
import "./DataTable.css";
import "bootstrap/dist/css/bootstrap.min.css";
const DataTable = ({
  tableData,
  header,
  isPagination = true,
  onPageChange,
  currentPage,
  pageCount,
  dataPerPage,
}) => {
  return (
    <div className="table-container">
      <DatatableWrapper
        body={tableData?.length > 0 ? tableData : []}
        headers={header?.length > 0 ? header : []}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: dataPerPage ? dataPerPage : 10,
          },
        }}
      >
        <Table
          className={`command-logs-table ${
            tableData?.length === 0 ? "text-center-align" : ""
          }`}
        >
          <TableHeader />
          <TableBody />
        </Table>
        <Row>
          <Col lg={12} className="pagination-container"></Col>
        </Row>
      </DatatableWrapper>
    </div>
  );
};
export default DataTable;
