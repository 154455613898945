import React from "react";

import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import "./dropDown.css";

const DropDownItem = ({
  list,
  placeHolderValue,
  onSelect,
  selectedValue,
  customClass,
  disabled,
}) => {
  return (
    <div className={`custom-dropdown ${customClass}`}>
      <MDBDropdown>
        <MDBDropdownToggle className="dropDown-toggle" disabled={disabled}>
          {selectedValue ? selectedValue : placeHolderValue}
        </MDBDropdownToggle>
        <MDBDropdownMenu className="menuItem">
          {list?.map((item) => (
            <MDBDropdownItem
              className="display-dropdown-item"
              href="#/action-1"
              key={item?.id}
              onClick={() => onSelect(item)}
            >
              {item.name}
            </MDBDropdownItem>
          ))}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
};

export default DropDownItem;
