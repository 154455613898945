import React, { useContext } from "react";
import "./statusDeviceList.css";
import { StatusDeviceListContext } from "../StatusDeviceListView";
import RoundedDropDownItem from "../../../components/RoundedDropDownComponent/RoundedDropDown";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import DataTable from "../../../components/DataTableComponent/DataTable";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import Error from "../../../assets/images/icons/Error.svg";
import { Pagination } from "../../../components/Pagination/Pagination";
import SuccessImage from "../../../assets/images/Success.svg";
import AlarmCondtionsModal from "../../../components/AlarmConditionsModal/AlarmCondtions";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import RefreshIcon from "../../../assets/images/icons/Refresh.svg";
import downloadcsvIcon from "../../../assets/images/icons/downloadcsv.svg";
import DatePickerModal from "../../../components/DatePickerModal/DatePickerModal";
import errorImage from "../../../assets/images/Disable.svg";
const StatusDeviceListPage = () => {
  const {
    isLoading,
    handleModelSelect,
    modalPlugList, // list to show model list
    selectedValue, // selected value from drop down
    modelRequired, // validation feild for model
    setModelRequired,
    stateList, // list of state
    selectedState, // selected state value from drop down
    setSelectedState,
    locationList, // location list
    onSelectState, // function to selecte location bsed on state selected
    stateName, // variable to show selected state name
    setStateName, // set methos to set selected state name
    header, // heade for list
    statusDeviceList, // data for show the list of status dvice
    deviceStatus, // show status online , offline ,error
    locationName, // variable to show selected location name
    handlestateSelect, // function set selcted state name
    handlelocationSelect, //function set selcted location
    setErrorAlertShow,
    showErrorMessage,
    errorAlertShow,
    errorAlertMessage,
    onHandlePagination,
    pageState,
    setShowErrorAlert,
    setDeviceStatus,
    showErrorAlert,
    setShowAlarmModal,
    showAlarmModal,
    alarmData,
    exportDataCsv,
    isReportGenerated,
    exportDataStarted,refreshClick,ondownloadClick,
    showSuccesIcon,
    setShowSuccesIcon
  } = useContext(StatusDeviceListContext);
  const date = new Date();

  return (
    <div className="status-device-list-page">
      {isLoading ? <Loader /> : ""}
      <MDBContainer fluid>
        <ThemeCard customClass="title-none">
          <div className="model-drop-box">
            <MDBRow>
              <MDBCol size="md-4">
                <RoundedDropDownItem
                  list={modalPlugList}
                  selectedValue={selectedValue}
                  // onSelect={handleSelect}
                  onSelect={(id, name, model) => {
                    handleModelSelect(id, name, model);
                  }}
                  customClass={
                    modelRequired === true ? "error-input-field" : ""
                  }
                  PlaceHolderValue="Select a model"
                />
              </MDBCol>
              {/* <MDBCol size="md-4">
                <RoundedDropDownItem
                  list={stateList}
                  selectedValue={stateName}
                  onSelect={(id, name) => {
                    handlestateSelect(id, name, );
                    onSelectState(id,name)
                  }}
                  customClass={
                    modelRequired === true ? "error-input-field" : ""
                  }
                  PlaceHolderValue="Select State"
                />
              </MDBCol> */}
              {/* <MDBCol size="md-4">
                <RoundedDropDownItem
                  list={locationList}
                  selectedValue={`${
                    locationName
                      ? locationName
                      : "Select Location"
                  } `}
                 
                  onSelect={(id, name) => {
                    handlelocationSelect(id, name);
                  }}
                  customClass={
                    modelRequired === true ? "error-input-field" : ""
                  }
                  PlaceHolderValue="Select a Location"
                />
              </MDBCol> */}
              {deviceStatus === "shutdown" ? (
                <MDBCol>
                  <div className="download-data">
                    {exportDataStarted && isReportGenerated === false ? (
                      <div className="refresh-button-label">
                        <div className="arrow_box">
                          <p className="refresh-text">
                            Report generation in progress. Click refresh button
                          </p>
                        </div>
                        <ButtonComponent
                          coloredButton={true}
                          customClass="settings-button"
                          buttonImage={RefreshIcon}
                          onClick={refreshClick}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {isReportGenerated && (
                      <ButtonComponent
                        text={"Download CSV"}
                        buttonImage={downloadcsvIcon}
                        buttonWidth="170px"
                        onClick={ondownloadClick}
                      />
                    )}
                    <ButtonComponent
                      coloredButton={true}
                      text={"Export as CSV"}
                      customClass={"export-graph-button"}
                      onClick={exportDataCsv}
                    />
                  </div>
                </MDBCol>
              ) : (
                ""
              )}
            </MDBRow>
          </div>
        </ThemeCard>
        <div className="status-List-device">
          <ThemeCard
            cardTitle={
              deviceStatus === "shutdown"
                ? "Offline" + " " + "Devices"
                : deviceStatus === "error"
                ? "Devices"
                : deviceStatus[0].toUpperCase() +
                  deviceStatus.substring(1) +
                  " " +
                  "Devices"
            }
          >
            <DataTable
              header={header}
              tableData={statusDeviceList}
              isPagination={true}
            />
          </ThemeCard>
          <Pagination
            onChnagePagination={(pageState) => onHandlePagination(pageState)}
            currentPageState={pageState}
            customClass="pagination-mt"
            dataTablevalue={statusDeviceList}
          
          />
        </div>
      </MDBContainer>
      {showSuccesIcon && (
          <Alertpopup
          image={SuccessImage}
          setShowModal={setShowSuccesIcon}
          showModal={showSuccesIcon}
          titleText="Success"
          DetailText={errorAlertMessage}
          modalType="successForm"
          alertButtonText="Continue"
          onclickModalContinue={() => {
            setShowSuccesIcon(false);
          }}
        >
          
        </Alertpopup>
      )}
 {errorAlertShow && (   <Alertpopup
        showModal={errorAlertShow}
        setShowModal={setErrorAlertShow}
        modalType="error-modal"
        titleText={"Error"}
        DetailText={errorAlertMessage}
        image={errorImage}
        alertButtonText={"Close"}
      ></Alertpopup>)}
         

      {/* alarm condtion modal */}
      <AlarmCondtionsModal
        showModal={showAlarmModal}
        setShowModal={setShowAlarmModal}
        title={"Alarm Conditions"}
        alarmData={alarmData}
      />
    </div>
  );
};
export default StatusDeviceListPage;
