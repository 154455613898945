import React from "react";
import { DeviceControlContextProvider } from "./DeviceControlView";
import DeviceControlPage from "./views/DeviceControlPage";

const DeviceControl = () => {
  return (
    <DeviceControlContextProvider>
        <DeviceControlPage/>
    </DeviceControlContextProvider>
  );
};

export default DeviceControl;
