import React from "react";
import { DupsUserDeviceListContextProvider } from "./DupsUserDeviceListView";
import DupsUserDeviceListPage from "./views/DupsUserDeviceList"
const DupsUserDeviceList =()=>{

    return(
    <DupsUserDeviceListContextProvider>
        <DupsUserDeviceListPage/>
    </DupsUserDeviceListContextProvider>
    )
}
export default DupsUserDeviceList