import React, { createContext, useState, useEffect } from "react";
import {
  downloadCsvData,
  exportCsvData,
  getModalPlugList,
  getStateListData,
  getstatusListOn_modal,
  notificationToOfflineDevices,
} from "../../apis/Api";
import viewUser from "../../assets/images/icons/viewUser.svg";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import RoutConstants from "../../constants/routeConstants.json";
import { useNavigate, useLocation } from "react-router-dom";
import dummyDeviceListStatus from "../../constants/ContentFile.json";
import alarmIcon from "../../assets/images/icons/alarmIcon.svg";
export const StatusDeviceListContext = createContext({});
export const StatusDeviceListContextProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dummylist = dummyDeviceListStatus.dummyDeviceListStatus.data.rows;
  const statusDeviceDetail = location?.state;
  const catogoryId = localStorage.getItem("dashboard_Id");
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    statusDeviceDetail ? statusDeviceDetail.selectedModel : "All"
  );
  const [selectedItemId, setSelectedItemId] = useState(
    statusDeviceDetail ? statusDeviceDetail?.selectedModelId : ""
  );
  const [modalPlugList, setmodalPlugList] = useState([]);
  const [modelRequired, setModelRequired] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [stateName, setStateName] = useState("");
  const [locationName, setlocationName] = useState("");
  const [deviceStatus, setDeviceStatus] = useState(
    location?.state?.deviceStatus
  );
  const [statusDeviceList, setStatusDeviceList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [pageState, setpageState] = useState(null);
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [showAlarmModal, setShowAlarmModal] = useState(false); // alarm modal
  const [exportPdfModal, setExportPdfModal] = useState(false);
  const str = selectedValue;

  const words = selectedValue?.split(" ");
  const input_string = words?.[3];
  const result_string = input_string?.replace("(", "").replace(")", "");
  const [alarmData, setAlarmData] = useState();
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [exportDataStarted, setExportDataStarted] = useState(false);
  const [isUrl, setIsUrl] = useState("");
  const model = localStorage.getItem("model");
  const [showSuccesIcon ,setShowSuccesIcon] = useState(false)
  useEffect(() => {
    getListOfplugModal(); // get mode list
    getStateList(); // get state list
    getfilterdListByModeName();
  }, []);
  // api call
  const getListOfplugModal = async () => {
    setIsLoading(true);
    try {
      const response = await getModalPlugList(catogoryId);
      if (response.data.status == 200) {
        const modelList = response.data.data.models;
        const iterateModelArray = modelList?.map((item) => {
          return {
            id: item?.id,
            model: item?.name,
            name: `${item?.modelName} (${item?.name}) `,
          };
        });
        setmodalPlugList(iterateModelArray);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage = error?.response?.data?.message;
      setErrorAlertMessage(errorMessage);
      setErrorAlertShow(true);
    }
  };
  // api call state list
  const getStateList = async () => {
    setIsLoading(true);
    try {
      const response = await getStateListData();
      const stateArray = response?.data?.data?.map((state) => ({
        id: state.stateId,
        name: state.state,
      }));

      const listOfState = stateArray;

      if (response.data.status == 200) {
        setStateList(listOfState);
        setSelectedState(response.data.data);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const errorMessage = error?.response?.data?.message;
      setErrorAlertMessage(errorMessage);
      setErrorAlertShow(true);
    }
  };
  // dropdown select function
  const handlestateSelect = (id, name) => {
    setLocationList([]);
    setlocationName("");
    setStateName(name);
    setSelectedItemId(id);
  };
  const handlelocationSelect = (id, name) => {
    setlocationName(name);
    setSelectedLocationId(id);
  };
  const handleModelSelect = (id, name, model) => {
    localStorage.setItem("model", model);
    setpageState(null);
    setSelectedValue(name);
    setSelectedItemId(id);
    getstatusListOnmodal(catogoryId, id, null);
    setExportDataStarted(false)
    setIsReportGenerated(false)
  };

  // api call for device status list
  const getstatusListOnmodal = async (catogoryId, id, currentPageState) => {
    const data = {
      categoryId: catogoryId,
      // modelId: String(id),
      modelId: id,
      status: deviceStatus,
      pageState: currentPageState,
    };
    setIsLoading(true);
    try {
      const response = await getstatusListOn_modal(data);

      if (response.data.status == 200) {
        setStatusDeviceList(response?.data?.data?.result?.rows);
        setpageState(response?.data?.data?.result?.pageState);
      }
      setIsLoading(false);
    } catch (error) {
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        setIsLoading(false);
        const errorMessage = error?.response?.data?.message;
        setErrorAlertMessage(errorMessage);
        setErrorAlertShow(true);
      } else {
        setIsLoading(false);
        setErrorAlertMessage("Something went wrong...");
        setErrorAlertShow(true);
      }
    }
    // setIsLoading(false);
  };
  const onSelectState = (id, name) => {
    const selectedStateID = id;

    setStateName(name);
    // filter based on  district based on state selected
    const stateInfo = selectedState.find((state) => state.stateId === id);

    if (stateInfo) {
      const locationStateList = stateInfo.districts.map((district) => ({
        id: district.districtId,
        name: district.districtName,
      }));

      setLocationList(locationStateList);

      return locationStateList;
    } else {
      return []; // State with the given stateId not found
    }
  };

  let header = [
    {
      title: "Model",
      prop: "modelName",
      cell: (row) => (
        <div>
          <div>
            {row.modelName ? row.modelName : "---"}{" "}
            {row.model ? `(${row.model})` : ""}{" "}
          </div>
        </div>
      ),
    },

    {
      title: "Serial Number",
      prop: "serialNumber",
      cell: (row) => (
        <div>
          <div>{row.serialNumber ? row.serialNumber : "---"}</div>
        </div>
      ),
    },
    {
      title: "Registered User",
      prop: "registeredUser",

      cell: (row) => (
        <div>
          <div>{row.registeredUser ? row.registeredUser : "---"}</div>
          <div className="phonenumber-style-devicelist">
            {row.phoneNumber ? row.phoneNumber : "---"}
          </div>
        </div>
      ),
    },
    // {
    //   title: "Location",
    //   prop: "location",
    //   cell: (row) => (
    //     <div>
    //       <div>{row.location}</div>
    //       <div className="phonenumber-style-devicelist">{row.state}</div>
    //     </div>
    //   ),
    // },
    // write a condition to hide notfy button if device is on line or eror

    {
      title: "",
      prop: "",
      cell: (row) => (
        <div className="view-column-List">
          <div
            onClick={() => {
              navigateToDeviceDashboard(
                row.model,
                row.serialNumber,
                row.modelId
              );
            }}
          >
            <img src={viewUser} />
          </div>
        </div>
      ),
    },
  ];
  //  function insert the colum for notify only to offline case
  if (deviceStatus === "shutdown") {
    const content = {
      title: "",
      prop: "",
      cell: (row) => (
        <div>
          {row.notify === true ? (
            <ButtonComponent
              customClass={"notify-button"}
              coloredButton={true}
              text={"Notify"}
              onClick={() => {
                NotifyOfflineDevices(row.serialNumber);
              }}
            />
          ) : (
            ""
          )}
        </div>
      ),
    };
    // function to show elapsed time for shutdown devices
    const elapsedTime = {
      title: "Elapsed Time",
      prop: "",
      cell: (row) => <div>{row.elapsedTime}</div>,
    };
    header.splice(3, 0, elapsedTime);

    header.splice(4, 0, content);
  }
  // function to insert the column for alarm conditions
  if (deviceStatus === "error") {
    const content = {
      title: "Alarm Conditions",
      prop: "",
      cell: (row) => (
        <>
          <div className="alarm-status">
            {row?.alarmMessage?.split(",")[0]?.trim()}
            {row.alarmMessage?.split(",").length > 1 ? (
              <span
                onClick={() => {
                  openAlarmModal(row.serialNumber);
                }}
              >
                ...
                <img src={alarmIcon} />
              </span>
            ) : (
              ""
            )}
          </div>
        </>
      ),
    };
    header.splice(3, 0, content);
  }
  // naviagte to plugdashboard
  const navigateToDeviceDashboard = (modelName, serialNumber, modelId) => {
    navigate(
      {
        pathname: RoutConstants.PLUG_DASHBOARD,
        search: "?plug=singlePlug",
      },
      {
        state: {
          serialNumber: serialNumber,
        },
      },
      localStorage.setItem("model", modelName),
      localStorage.setItem("modelId", modelId)
    );
  };

  // filter list based on model selcted from dashboard
  const getfilterdListByModeName = () => {
    if (selectedValue === "All") {
      getstatusListOnmodal(catogoryId, selectedItemId, pageState);
    } else {
      getstatusListOnmodal(catogoryId, selectedItemId, pageState);
    }
  };

  // pagination fuction
  const onHandlePagination = (pageState) => {
    getstatusListOnmodal(catogoryId, selectedItemId, pageState);
  };
  // send notification to offline devices
  const NotifyOfflineDevices = async (serialNumber) => {
    const data = {
      serialNumber: serialNumber,
    };
    setIsLoading(true);
    try {
      const response = await notificationToOfflineDevices(data);

      if (response.data.status == 200) {
        setShowSuccesIcon(true)
        setErrorAlertMessage("Notification send successfully");
        //setErrorAlertShow(true);
      }
      setIsLoading(false);
    } catch (error) {
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        setIsLoading(false);
        const errorMessage = error?.response?.data?.message;
        setErrorAlertMessage(errorMessage);
        setErrorAlertShow(true);
      } else {
        setIsLoading(false);
        setErrorAlertMessage("Something went wrong...");
        setErrorAlertShow(true);
      }
    }
  };

  // show alarm modal
  const openAlarmModal = (serialNumber) => {
    const filterdAlarmData = statusDeviceList?.filter((item) => {
      return item.serialNumber === serialNumber;
    });
    setAlarmData(filterdAlarmData[0]?.alarmMessage?.split(","));
    setShowAlarmModal(true);
  };

  // export data as csv function
  const exportDataCsv = async () => {
    const data = {
      key: "Offline",
      model: model,
      type: "smartplug", //default value currenlty set to smartPlug
    };
    try {
      const response = await exportCsvData(data);
      setExportDataStarted(true);
      setIsReportGenerated(false);
      setIsLoading(false);
      setShowSuccesIcon(true);
      setErrorAlertMessage(
        "Data is exported successfully,Report generation in progress"
      );
    } catch (error) {
      setIsLoading(false);
      setErrorAlertShow(true);
      setErrorAlertMessage("Something went wrong ");
    }
  };
  // refresh click on for csv
  const refreshClick = () => {
    setIsUrl("");
    setIsLoading(true);
    if (isReportGenerated) {
      setIsReportGenerated(true);
    }
    downloadCsv();
  };
  // download csv
  const downloadCsv = async () => {
    const downLoadDataKey = {
      key: "Offline",
      srno: "",
    };
    setIsLoading(true);
    try {
      const response = await downloadCsvData(downLoadDataKey);
      setIsUrl(response.data.data.url);
      setIsReportGenerated(response.data.data.reportGenerated);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage("Something went wrong");
    }
  };
  // check downloAD
  const ondownloadClick = () => {
    if (isUrl) {
      setIsLoading(true);
      window.open(isUrl, "_self");
    }
    setIsLoading(false);
  };
  const providerValue = {
    modalPlugList, // list to show model list
    setmodalPlugList, // set method model list
    isLoading,
    setShowErrorAlert,
    setShowErrorMessage,
    selectedValue, // selected value from drop down
    showErrorAlert,
    showErrorMessage,
    modelRequired, // validation feild for model
    setModelRequired,
    handleModelSelect, // function to check model select from drop down
    stateList, // list of state
    selectedState, // selected state value from drop down
    setSelectedState,
    onSelectState, // function to selecte location bsed on state selected
    locationList, // location list
    stateName, // variable to show selected state name
    setStateName, // set methos to set selected state name
    header, // heade for list
    statusDeviceList, // data for show the list of status dvice
    deviceStatus, // show status online , offline ,error
    setDeviceStatus,
    locationName, // variable to show selected location name
    handlestateSelect, // function set selcted state name
    handlelocationSelect, //function set selcted location
    setStatusDeviceList, // method to set status of device list
    selectedLocationId,
    onHandlePagination,
    pageState,
    errorAlertMessage,
    errorAlertShow,
    setErrorAlertShow,
    setShowAlarmModal,
    showAlarmModal,
    alarmData,
    exportDataCsv,
    isReportGenerated,
    exportDataStarted,
    refreshClick,
    ondownloadClick,
    showSuccesIcon ,setShowSuccesIcon
  };
  return (
    <StatusDeviceListContext.Provider value={providerValue}>
      {children}
    </StatusDeviceListContext.Provider>
  );
};
