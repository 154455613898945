import React from "react";
import ResetPasswordPage from "./views/ResetPasswordPage";
import { ResetPasswordContextProvider } from "./ResetPasswordView";

const ResetPassword = () => {
  return (
    <ResetPasswordContextProvider>
      <ResetPasswordPage />
    </ResetPasswordContextProvider>
  );
};

export default ResetPassword;
