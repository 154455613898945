import React from "react";
import "./inputComponent.css";
const InputComponent = ({
  value,
  placeholder,
  backgroundColor,
  customClass,
  customImageClass,
  image,
  type,
  onChange,
  onKeyDown,
  roundedButton = false,
  disabled,
  imageOnClick,
  maxLength
}) => {
  return (
    <div className="input-component-main">
      <div className="input-container">
        {image ? (
          <img
            src={image}
            className={`input-image ${customImageClass}`}
            onClick={imageOnClick}
          />
        ) : (
          ""
        )}
        <input
          className={
            roundedButton
              ? `rounded-input-box ${customClass}`
              : `input-box ${customClass}`
          }
          placeholder={placeholder}
          style={{ background: backgroundColor }}
          value={value}
          type={type}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};

export default InputComponent;
