import React from "react";
import { UserDetailsContextProvider } from "./userDetailsView";
import UserDetails from "./views/userDetails";

const UserDetailsPage = () => {
  return (
    <UserDetailsContextProvider>
      <UserDetails />
    </UserDetailsContextProvider>
  );
};

export default UserDetailsPage;
