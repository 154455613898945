import React from "react";
import { DeviceAlertContextProvider } from "./DeviceAlertView";
import DeviceAlert from "./views/DeviceAlert";

const DeviceAlertPage = () => {
  return (
    <DeviceAlertContextProvider>
      <DeviceAlert />
    </DeviceAlertContextProvider>
  );
};

export default DeviceAlertPage;
