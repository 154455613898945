import React, { useContext } from "react";
import { LoginContext } from "../LoginView";
import Logo from "../../../assets/images/VG-logo.svg";
import { Row, Spinner } from "react-bootstrap";
import "./loginPage.css";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import EyeVisible from "../../../assets/images/icons/Password-show.svg";
import EyeInVisible from "../../../assets/images/icons/Password-hide.svg";
import { useEffect } from "react";
//import PopupAlertError from "../../../components/PopupAlertError/PopupAlertError";
import InputComponent from "../../../components/InputComponent/InputComponent";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import Error from "../../../assets/images/icons/Error.svg";
const LoginPage = () => {
  const {
    passwordType,
    toggleEyeIcon,
    pageContent,
    submitLogin,
    emailValidationMsg,
    email,
    errorAlertMessage,
    spinnerStatus,
    errorAlertShow,
    password,
    handleEmailChange,
    handlePasswordChange,
    passwordValidateMsg,
    navigatePage,
    setErrorAlertShow,
    setPassword,
    setPasswordType,
    isLoading
  } = useContext(LoginContext);

  useEffect(() => {
    localStorage.removeItem("value");
  }, []);

  return (
    // main div starts
    <div className="login-page container">
       {isLoading ? <Loader /> : ""}
      {/* white box starts */}
      <div className="auth-box col-lg-5 col-mg-6 col-sm-8 col-12">
        {/* logo div starts */}
        <div className="logo-div">
          <img src={Logo} alt="logo" />
        </div>
        {/* page title */}
        <p className="mb-0 form-title">{pageContent.pageTitle}</p>

        {/* form section starts */}
        <form onSubmit={submitLogin}>
          {/* Email Input starts*/}
          <label className="input-label mt-4 mb-1">
            {pageContent.emailLabel}
          </label>
          <InputComponent
                      placeholder={pageContent.emailPlaceholders}
                      value={email}
                      type="text"
                     onChange={handleEmailChange}
                     className="input-item"
                    //  customClass={addCommandRequried ? "error" : "valid"}
                    />
      
          <p className="mb-0 error-msg">
            {emailValidationMsg ? emailValidationMsg : ""}
          </p>
          {/* Email Input ends*/}

          {/* password input starts */}
          <label className="input-label mt-3 mb-1">
            {pageContent.passwordLabel}
          </label>
          <div className="passwordInput">
          <InputComponent
            placeholder={pageContent.passwordPlaceholder}
            type={passwordType}
            value={password}
            onChange={handlePasswordChange}
            className="input-item"
          />
          <div className="eye-Icon-Vsisble">
          {passwordType === "password" ? (
            <i className="bi bi-eye-slash">
              <img
                 src={EyeInVisible}
                onClick={() => {
                  setPasswordType("text");
                }}
              />
            </i>
          ) : (
            <i className="bi bi-eye-slash">
              <img
                 src={EyeVisible}
                onClick={() => {
                  setPasswordType("password");
                }}
              />
            </i>
          )}
          </div>
          </div>
          <p className="mb-0 error-msg">
            {passwordValidateMsg ? passwordValidateMsg : ""}
          </p>
          {/* password input ends */}

          {/* forgot password div starts */}
          <span className="forgot-password-txt mt-3" onClick={navigatePage}>
            {pageContent.forgotPassword}
          </span>
          {/* forgot password div ends */}

          {/* submit button */}

          <ButtonComponent
            text={"Login"}
            coloredButton={true}
            customClass="button-login"
            buttonWidth="100%"
          />
        </form>
        {/* form section ends */}
      </div>
      {/* white box ends */}
    
      <Alertpopup
        image={Error}
        setShowModal={setErrorAlertShow}
        showModal={errorAlertShow}
        titleText="Error"
        DetailText={errorAlertMessage}
        modalType="error-modal"
        alertButtonText="Continue"
      ></Alertpopup>
    </div>
    // main div end
  );
};

export default LoginPage;
