import React, { useEffect, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "./yearPicker.css";
const YearPicker = ({ selectedValue, renderFunction, getSelectedYear }) => {
  const [value, setValue] = useState();
  const currentYear = new Date().getFullYear().toString();

  function updateValue({ year, month }) {
    setValue(
      new DateObject(value).set({
        day: value.day > month.length ? month.length : value.day,
        month,
        year,
      })
    );
  }
  return (
    <DatePicker
      onlyYearPicker
      render={renderFunction}
      value={new Date(selectedValue, 1, 1)}
      onChange={(e) => {
        setValue(e);
        getSelectedYear(e?.year);
      }}
      highlightToday={false}
      maxDate={currentYear}
      hideYear={true}
    />
  );
};

export default YearPicker;
