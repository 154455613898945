import React, { useContext, useState } from "react";

import "./deviceControlPage.css";
import { DeviceControlContext } from "../DeviceControlView";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import CloseButton from "../../../assets/images/icons/Close-button.svg";
import AddButton from "../../../assets/images/icons/Add-icon.svg";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import InputComponent from "../../../components/InputComponent/InputComponent";
import Error from "../../../assets/images/icons/Error.svg";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBCol,
  MDBRow,
  MDBContainer,
} from "mdb-react-ui-kit";
import DropDownItem from "../../../components/DropDownComponent/DropDown";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import DataTable from "../../../components/DataTableComponent/DataTable";
import { Badge } from "react-bootstrap";
import DummyData from "../../../components/DataTableComponent/constant.json";
import TimeIntervalModal from "../../../components/TimeIntervalModal/TimeIntervalModal";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import ConfrimationImage from "../../../assets/images/Confirmation.svg";
import SuccessImage from "../../../assets/images/Success.svg";
import eyeIcon from "../../../assets/images/icons/eyeIcon.svg";
import Switch from "../../../components/ToggleComponent/Toggle";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import { useEffect } from "react";
import { Pagination } from "../../../components/Pagination/Pagination";
import moment from "moment";
import InprogressImage from "../../../assets/images/page-not-found.svg";

const DeviceControlPage = () => {
  const {
    pageContent, // page static contents
    handleClickActiveTab, // function to active tab
    tabData, // state to store tab data
    removeSelectedSerialNumber, // function to remove selected item
    serialNumberAddingModal, // serial number adding modal state
    setSerialNumberAddingModal, // serial number adding modal set state
    showSerialNumberAddingModal, //function to show serial number adding modal
    closeSerialNumberAddingModal, //function to close serial number adding modal
    onChnageSerialNumberInput, // serial number input onChnage fuction
    serialNumber, // serial number input value storing state
    onSubmitSerialNumberInput, // serial number modal submit function
    InputValidationError, // state to check input validation
    serialNumberErrorMsg, // state to show serial number input error message
    onSelectLocation, // location dropdown on select funtion
    locationCount, // number of location selected
    locationSelectedText, // location selected text
    colors, // color list for location
    newLocationList, // itrated location list
    removeSelectedLocation, // function to remove selected location
    locationDisable, // state to disable location dropdown
    locationErrorvalidationMsg, // state to store location validation message
    timeIntervalModal, // time interval modal state
    setTimeIntervalModal, // time interval modal set states
    showTimeIntervalModal, // function to show time interval modal
    pageCount, // number of pages
    currentPage, // current page in dataTable
    showTableModal, // function to show modal inside table badge
    tableBadgeModal, // table badge modal state
    setTableBadgeModal, // table badge modal set state
    badgeModalCancel, // badge modal cancel function
    tableBadgeRowId, // clicked row id
    showUpgardeFirmwareModal, // function to show upgrade firmware modal
    upgradeFirmwareModal, // upgrade firmware modal state
    setUpgradeFirmwareModal, // upgrade firmware modal set state
    cancelupdateFirmwaremodal, // cancel upgrade firmware modal
    pageDummyContent, // page dummy content
    onSelectVersion, // on select function for  version select
    selectVersion, // state that store selected version
    startCommadConfrimationModal, // function to show start command confirmation modal
    startCommadConfirmModal, // start command confirmation modal state
    setStartCommadConfirmModal, // start command confirmation modal setstate
    stopCommadConfrimModal, // stop command confirmation modal state
    setStopCommadConfirmModal, // stop command confirmation modal setstate
    stopCommadConfrimationModal, // function to show stop command  confirmation modal
    onChnageCommadInput, // command input onchange function
    commandInput, // command input state
    commandInputValidationError, // commad input validation error state
    onClickSubmitCommad, //  commad submit button onclick function
    updateFirwareSubmit, // upgrade firmware modal submit function
    updateFirwareDropdownValidationError, // frimware dropdown error validation state
    quickSendButtonDisable, // quick send  command buttons disable state
    onSubmmitStartCommand, // onclick start commad inside quick send
    serialNumberQuickSendData, // serial number command data
    onSubmmitStopCommand, // on submit stop command modal
    successModal, // success modal state
    setSuccessModal, // success modal setstate
    sucessModalText, // success modal text
    setChecked,
    checked, // button select all device toggle
    addSerialNumberButtonShow,
    onSubmitTimeInterval,
    isLoading,
    customCommandSendToApi,
    showErrorAlert,
    setShowErrorAlert,
    showErrorMessage,
    continueOnErrorClick,
    showError,
    setShowError,
    commandLogData,
    serialNumberCommandLogPagination,
    pageState,
    setCommandDetailShowModal,
    commandDetailShowModal,
    showCommandModal,
    tempSerailNumber,
    version,
    stateList,
    districtList,
    locationName,
    onStateSelction,
    stateName,
    setStateName,
    showDistrictList,
    setShowDistrictList,
    selectDeviceTabId,
    locationPageState,
    locationCommandLogPagination,
  } = useContext(DeviceControlContext);

  const header = [
    {
      title: "User",
      prop: "user",
    },
    {
      title: "Target Devices",
      prop: "target",
      cell: (row) => {
        const targetDeviceArray = row?.target?.split(",");
        const commandSendTime = new Date(row?.time).getTime();
        return targetDeviceArray?.length > 2 ? (
          <div className="table-container multipleCount">
            <div>
              {targetDeviceArray
                ? targetDeviceArray?.splice(0, 2).join(", ")
                : "-"}
            </div>
            <Badge
              className="table-container badge-block"
              onClick={() => {
                showTableModal(commandSendTime);
              }}
            >
              +{targetDeviceArray?.length}
            </Badge>
          </div>
        ) : (
          targetDeviceArray?.join(", ")
        );
      },
    },

    {
      title: "Command",
      prop: "command",
      cell: (row) => {
        const commandLength = row?.command?.length;
        const commandSendTime = new Date(row?.time).getTime();
        return commandLength > 50 ? (
          <div className="command-column-box">
            <div className="command-column">{row.command}</div>
            <div>
              <img
                src={eyeIcon}
                onClick={() => {
                  showCommandModal(commandSendTime);
                }}
              />
            </div>
          </div>
        ) : (
          row.command
        );
      },
    },
    {
      title: "Date & Time",
      prop: "time",
      cell: (row) => (
        <div>
          <div>{row.time ? moment(row?.time).format("DD/MM/YYYY") : "-"}</div>
          <div>{row.time ? moment(row?.time).format("HH:mm:ss") : "-"}</div>
        </div>
      ),
    },
  ];
  const showLocationContent = false;
  return (
    <div className="device-control-page">
      {isLoading ? <Loader /> : ""}
      <div className="toggle-Div">
        <span className="page-title mb-4">{pageContent.title}</span>
      </div>

      <ThemeCard cardTitle={pageContent.selectDevice}>
        {/* ----------------- Tab component start ------------------------------- */}
        <div className="select-tab">
          <MDBTabs>
            {tabData?.map((item, index) => {
              return (
                <>
                  <MDBTabsItem key={index}>
                    <MDBTabsLink
                      onClick={() => handleClickActiveTab(item?.tabId)}
                      active={item?.active}
                    >
                      {item?.tabTitle}
                    </MDBTabsLink>
                  </MDBTabsItem>
                </>
              );
            })}
          </MDBTabs>
          <MDBTabsContent>
            {tabData?.map((item, index) => {
              return (
                <MDBTabsPane show={item?.active} key={index}>
                  {/* --------------------------Location dropdown  start --------------- */}

                  {item?.tabId === 2 ? (
                    showLocationContent ? (
                      <>
                        <div className="location-div">
                          <MDBCol md="3" className="dropdown-margin-top">
                            <label className="location-label">State</label>
                            <DropDownItem
                              list={stateList}
                              placeHolderValue={"Select State"}
                              onSelect={(value) => {
                                onStateSelction(value, item?.tabId);
                              }}
                              selectedValue={stateName}
                              disabled={locationDisable}
                            />
                          </MDBCol>

                          {stateName === "" ? (
                            ""
                          ) : (
                            <MDBCol md="3" className="dropdown-margin-top">
                              <label className="location-label">Location</label>
                              <DropDownItem
                                list={newLocationList}
                                placeHolderValue={pageContent.selectLocation}
                                onSelect={(value) => {
                                  onSelectLocation(value, item?.tabId);
                                }}
                                selectedValue={`${
                                  locationCount
                                    ? locationCount
                                    : "Select Location"
                                }  ${
                                  locationSelectedText
                                    ? locationSelectedText
                                    : ""
                                }`}
                                disabled={locationDisable}
                              />
                              <span
                                className={
                                  locationErrorvalidationMsg === ""
                                    ? "display-none"
                                    : "error-text"
                                }
                              >
                                {locationErrorvalidationMsg
                                  ? locationErrorvalidationMsg
                                  : ""}
                              </span>
                            </MDBCol>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="inprogress-content">
                        <div>
                          <img src={InprogressImage} />
                        </div>
                        <div className="text">In-progress</div>
                      </div>
                    )
                  ) : (
                    ""
                  )}

                  {/* --------------------------Location dropdown end --------------- */}

                  <div className={item?.tabId === 1 ? "content-margin" : ""}>
                    <div
                      className={`data-container
                     ${
                       item?.tabId === 2 && item?.tabBody?.length !== 0
                         ? "top-border"
                         : ""
                     }`}
                    >
                      {item?.tabBody?.map((data, index) => {
                        return (
                          <div className="selected-value-box" key={index}>
                            {item?.tabId === 2 ? (
                              <span
                                className="color-dot"
                                style={{ background: colors[index] }}
                              ></span>
                            ) : (
                              ""
                            )}
                            {data}
                            <img
                              src={CloseButton}
                              alt="close-button"
                              onClick={() => {
                                if (item?.tabId === 1) {
                                  removeSelectedSerialNumber(
                                    item?.tabId,
                                    index
                                  );
                                } else if (item?.tabId === 2) {
                                  removeSelectedLocation(item?.tabId, index);
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                      {/* -------------------------- add serial number button start --------------- */}

                      {(item?.tabId === 1) & addSerialNumberButtonShow ? (
                        <button
                          className="add-button"
                          onClick={() => {
                            showSerialNumberAddingModal(item?.tabId);
                          }}
                        >
                          <img src={AddButton} alt="Add-button" />
                          <span className="button-text">
                            {item?.buttonName}
                          </span>
                        </button>
                      ) : (
                        ""
                      )}
                      {/* -------------------------- add serial number button end --------------- */}
                    </div>
                  </div>
                </MDBTabsPane>
              );
            })}
          </MDBTabsContent>
        </div>
        {/* ----------------- Tab component end ------------------------------- */}
      </ThemeCard>

      {/*  for displaying sendcommand box only for serial numbers */}
      {tabData.map((item, index) => {
        return item.tabId === 1 && item.active === true ? (
          <MDBRow>
            <MDBCol xxl={6} xl={7} lg={7} className="section-top-margin">
              <ThemeCard cardTitle={pageContent.quickSend.title}>
                <div className="quick-send-container">
                  <MDBContainer fluid>
                    <MDBRow>
                      <MDBCol
                        xxl={2}
                        xl={2}
                        lg={6}
                        md={6}
                        className="lg-padding-right"
                      >
                        <ButtonComponent
                          text={pageContent.quickSend.start}
                          buttonWidth="100%"
                          customClass="mt-3 button-text "
                          onClick={() => {
                            startCommadConfrimationModal("start");
                          }}
                          disabled={serialNumberQuickSendData.enable}
                        />
                      </MDBCol>
                      <MDBCol
                        xxl={2}
                        xl={2}
                        lg={6}
                        md={6}
                        className="lg-padding-right lg-padding-left  md-padding-left"
                      >
                        <ButtonComponent
                          text={pageContent.quickSend.stop}
                          buttonWidth="100%"
                          customClass="mt-3 button-text"
                          onClick={() => {
                            stopCommadConfrimationModal("stop");
                          }}
                          disabled={serialNumberQuickSendData.enable}
                        />
                      </MDBCol>
                      <MDBCol
                        xxl={4}
                        xl={4}
                        lg={6}
                        md={6}
                        className="lg-padding-right padding-left"
                      >
                        <ButtonComponent
                          text={pageContent.quickSend.timeInterval}
                          buttonWidth="100%"
                          customClass="mt-3 button-text"
                          onClick={() => {
                            showTimeIntervalModal("timeInterval");
                          }}
                          disabled={serialNumberQuickSendData.enable}
                        />
                      </MDBCol>
                      <MDBCol
                        xxl={4}
                        xl={4}
                        lg={6}
                        md={6}
                        className="lg-padding-right lg-padding-left md-padding-left"
                      >
                        <ButtonComponent
                          text={pageContent.quickSend.upgradeFirmware}
                          buttonWidth="100%"
                          customClass="mt-3 button-text"
                          onClick={() => {
                            showUpgardeFirmwareModal("updateFirmware");
                          }}
                          disabled={serialNumberQuickSendData.enable}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </ThemeCard>
            </MDBCol>

            <MDBCol xxl={6} xl={5} lg={5} className="section-top-margin">
              <ThemeCard cardTitle={pageContent.command.title}>
                <MDBContainer fluid>
                  <MDBRow>
                    <MDBCol
                      xxl={9}
                      xl={8}
                      lg={12}
                      md={8}
                      className="lg-padding-right mt-3"
                    >
                      <InputComponent
                        placeholder={pageContent.command.commandPlaceholder}
                        onChange={(e) => {
                          onChnageCommadInput(e);
                        }}
                        value={commandInput}
                        customClass={
                          commandInputValidationError === true ? "error" : ""
                        }
                        disabled={quickSendButtonDisable}
                      />
                    </MDBCol>
                    <MDBCol
                      xxl={3}
                      xl={4}
                      lg={5}
                      md={4}
                      className="lg-padding-right mt-3 md-padding-left"
                    >
                      <ButtonComponent
                        coloredButton={true}
                        text={pageContent.submitText}
                        onClick={onClickSubmitCommad}
                        disabled={quickSendButtonDisable}
                        buttonWidth="100%"
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                {/* <div className="command-container mt-3"></div> */}
              </ThemeCard>
            </MDBCol>
          </MDBRow>
        ) : (
          ""
        );
      })}
      {/* showing command logs */}
      {tabData.map((item, index) => {
        return item.tabId === 1 && item.active === true ? (
          <MDBRow className="section-top-margin">
            <MDBCol>
              <ThemeCard cardTitle={pageContent.commadLog} customClass="mb-2">
                <DataTable
                  header={header}
                  tableData={commandLogData}
                  isPagination={true}
                />
              </ThemeCard>
              {/* pagination */}
              {commandLogData ? (
                <div className="pagination-button">
                  {selectDeviceTabId === 1 && (
                    <Pagination
                      onChnagePagination={(pageState) =>
                        serialNumberCommandLogPagination(pageState)
                      }
                      currentPageState={pageState}
                      dataTablevalue={commandLogData}
                    />
                  )}
                  {selectDeviceTabId === 2 && (
                    <Pagination
                      onChnagePagination={(pageState) =>
                        locationCommandLogPagination(pageState)
                      }
                      currentPageState={locationPageState}
                      dataTablevalue={commandLogData}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </MDBCol>
          </MDBRow>
        ) : (
          ""
        );
      })}
      {/* ------------------- Modal component start --------------------------- */}
      <ModalComponent
        showModal={serialNumberAddingModal}
        setShowModal={setSerialNumberAddingModal}
        cancelButtonText={pageContent.cancelText}
        onClickCancel={closeSerialNumberAddingModal}
        customWidth="add-modal-width"
        title={pageContent.serialNumberModal.title}
        SubmitButtonText={pageContent.submitText}
        submitButtoncustomClass="modal-button-width"
        cancelButtoncustomClass="modal-button-width"
        onClickSubmit={() => onSubmitSerialNumberInput()}
      >
        <div className="mt-3">
          <label className="input-label">
            {pageContent.serialNumberModal.serialNumberLabel}
          </label>
          <InputComponent
            placeholder={pageContent.serialNumberModal.serialNumberPlaceholder}
            onChange={onChnageSerialNumberInput}
            value={serialNumber}
            customClass={InputValidationError === true ? "border-red" : ""}
          />
          <p className="error-text">
            {serialNumberErrorMsg ? serialNumberErrorMsg : ""}
          </p>
          <span className="notice-text">
            {pageContent.serialNumberModal.noticeText}
          </span>
        </div>
      </ModalComponent>
      {/* ------------------- Modal component end --------------------------- */}

      {/*------------------ Time interval modal component start----------------- */}
      <TimeIntervalModal
        title={"Time Interval"}
        showModal={timeIntervalModal}
        setShowModal={setTimeIntervalModal}
        onClickSubmit={(value) => {
          onSubmitTimeInterval(value);
        }}
      />
      {/*------------------ Time interval modal component end----------------- */}
      {/* -------------------- Table badge modal ----------------------- */}
      <div className="badge-modal">
        <ModalComponent
          showModal={tableBadgeModal}
          setShowModal={setTableBadgeModal}
          cancelButtonText={pageContent.cancelText}
          onClickCancel={badgeModalCancel}
          title={pageContent.tableBadgeModal.title}
          customWidth="badge-modal-width"
        >
          {commandLogData?.map((item) => {
            const commandSendTime = new Date(item?.time).getTime();
            const targetDevice = item?.target?.split(",");
            if (tableBadgeRowId === commandSendTime) {
              return (
                <div className="listing-box">
                  {targetDevice.map((list) => {
                    return (
                      <div className="modal-serial-number-box">{list}</div>
                    );
                  })}
                </div>
              );
            }
          })}
        </ModalComponent>
      </div>
      {/* -------------------- Table badge modal ----------------------- */}

      {/* -------------------command modal---------------------- */}
      <div className="badge-modal">
        <ModalComponent
          showModal={commandDetailShowModal}
          setShowModal={setCommandDetailShowModal}
          cancelButtonText={pageContent.cancelText}
          onClickCancel={badgeModalCancel}
          title={"Command"}
          customWidth="command-badge-modal-width"
        >
          {commandLogData?.map((item) => {
            const commandSendTime = new Date(item?.time).getTime();
            if (tableBadgeRowId === commandSendTime) {
              return (
                <div className="command-listing-box">
                  <div className="item-command"> {item.command}</div>
                </div>
              );
            }
          })}
        </ModalComponent>
      </div>
      {/* -------------------command modal----------------------- */}

      {/* ------------- Upgrade firmware modal -------------------------------- */}

      <ModalComponent
        showModal={upgradeFirmwareModal}
        setShowModal={setUpgradeFirmwareModal}
        title={pageContent.firmwareModal.title}
        cancelButtonText={pageContent.cancelText}
        SubmitButtonText={pageContent.submitText}
        onClickCancel={cancelupdateFirmwaremodal}
        onClickSubmit={updateFirwareSubmit}
        customWidth="firmware-modal-width"
      >
        <div className="firware-detail-box">
          <div className="detail-text">
            <span>{pageContent.frimwareModal.totalDevices}</span>
            <p>{tempSerailNumber?.length}</p>
          </div>

          {/* <div className="detail-text">
            <span>{pageContent.frimwareModal.currentVersion}</span>
            <p>{pageContent.frimwareModal.currentVersionNumber}</p>
          </div> */}
        </div>
        <label className="input-label">
          {pageContent.frimwareModal.targetVersionLabel}
        </label>
        <DropDownItem
          placeHolderValue={pageContent.frimwareModal.selectPlaceholder}
          list={version}
          selectedValue={selectVersion}
          onSelect={(value) => {
            onSelectVersion(value);
          }}
          customClass={
            updateFirwareDropdownValidationError === true ? "drop-error" : ""
          }
        />
      </ModalComponent>
      {/* ------------- Upgrade firmware modal -------------------------------- */}

      {/*-------------------- start command alert------------------------------------*/}
      <Alertpopup
        showModal={startCommadConfirmModal}
        setShowModal={setStartCommadConfirmModal}
        image={ConfrimationImage}
        buttonText={pageContent.startCommadAlertPopup.start}
        titleText={pageContent.startCommadAlertPopup.title}
        DetailText={pageContent.startCommadAlertPopup.detailText}
        onClick={onSubmmitStartCommand}
      />

      {/*-------------------- start command alert------------------------------------*/}

      {/*-------------------- start command alert------------------------------------*/}

      <Alertpopup
        showModal={stopCommadConfrimModal}
        setShowModal={setStopCommadConfirmModal}
        image={ConfrimationImage}
        buttonText={pageContent.stopCommadAlertPopup.stop}
        titleText={pageContent.stopCommadAlertPopup.title}
        DetailText={pageContent.stopCommadAlertPopup.detailText}
        onClick={onSubmmitStopCommand}
      />

      {/*-------------------- start command alert------------------------------------*/}

      {/* ---------- success Alert --------------------------------------- */}

      <Alertpopup
        modalType={pageContent.successmodal.type}
        showModal={successModal}
        setShowModal={setSuccessModal}
        image={SuccessImage}
        titleText={pageContent.successmodal.title}
        DetailText={sucessModalText}
        alertButtonText={"Continue"}
      />
      {/* ---------- success Alert --------------------------------------- */}
      {/* --- error alrt--- */}

      <Alertpopup
        image={Error}
        showModal={showError}
        setShowModal={setShowError}
        titleText="Error"
        DetailText={showErrorMessage}
        modalType="error-modal"
        alertButtonText={"Continue"}
      />
    </div>
  );
};

export default DeviceControlPage;
