import { createContext, useEffect, useState } from "react";
import { getStateListData, plugTrends } from "../../apis/Api";
import ContentFile from "../../constants/ContentFile.json";
import DummyContent from "../../constants/DummyContent.json";
import { checkCommaSeparatedNumber } from "../../utils/service";
import moment from "moment";
import { GlobalStateContext } from "../../store/store";
import { useContext } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
export const PlugTrendsContext = createContext({});
export const PlugTrendsContextProvider = ({ children }) => {
  const { state } = useContext(GlobalStateContext);
  const [dataSets, setDataSets] = useState({});
  const [dataSetLocation, setDataSetLocation] = useState({});

  const [labels, setLabels] = useState([]);
  const [labelsForLocation, setLabelsForLocation] = useState([]);
  const colors = ContentFile.colorsList; // color list for location
  const pageContent = ContentFile.trendsPage; // page static contents
  const pageDummyContent = DummyContent.trendsPage; // page dummy content
  const [tabData, setTabData] = useState(pageContent?.tabContent); // tab data storing state
  const [serialNumberAddingModal, setSerialNumberAddingModal] = useState(false); // state for serial number adding modal show and hide
  const [serialNumber, setSerialNumber] = useState(""); // state for serial number input
  const [InputValidationError, setInputvalidationError] = useState(false); // state to check input validation
  const [serialNumberTabId, setsSerialNumberTabId] = useState(); // store serial number tab id, on clicking button for show modal
  const [serialNumberErrorMsg, setSerialNumberErrorMsg] = useState(""); // state to show serial number input error message
  const [selectedLocation, setSelectedLocation] = useState([]); // sate to store selected location
  const [locationName, setLocationName] = useState(); //state to store selected location name
  const [locationCount, setLocationCount] = useState(); // number of location selected
  const [locationSelectedText, setLocationSelectedText] = useState(); // state to change location to locations when more than one location selected
  const [locationTabId, setlocationTabId] = useState(); // state to store location tab id
  const [locationDisable, setLocationDisable] = useState(); // state to enable and disable location
  const [exportGraphModal, setExportGraphModal] = useState(false); // state for serial number adding modal show and hide
  const [csv, setCsv] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [stateList, setStateList] = useState([]); //state list
  const [stateAndDistrictList, setStateAndDistrictList] = useState([]); //state and district list
  const [selectedState, setSelectedState] = useState();
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [addSerialNumberButtonShow, setAddSerialNumberButtonShow] =
    useState(true);
  const [validateExportGraph, setValidateExportGraph] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("ll")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).subtract(1, "days").format("ll")
  );
  const [locationStartDate, setLocationStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("ll")
  );
  const [locationEndDate, setLocationEndDate] = useState(
    moment(new Date()).subtract(1, "days").format("ll")
  );
  const [serialNumberPrevValuesArray, setSerialNumberPrevValuesArray] =
    useState([]);
  const [serialNoPrevValueLength, setSerialNoPrevValueLength] = useState("");
  const [serialNoExistError, setSerialNoExistError] = useState(false);
  const [calculationModalShow, setCalculationModalShow] = useState(false);
  const [locationErrorvalidationMsg, setLocationErrorValidationMsg] =
    useState(); // state for location validation message
  const [clickedLegendId, setClickedLegendId] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [selectDeviceTabId, setSelectDeviceTabId] = useState(1); // selected by serial number and location tab id
  const [legends, setLegends] = useState([
    {
      name: "Energy",
      active: false,
      id: 1,
    },
    {
      name: "Voltage",
      active: true,
      id: 2,
    },
    {
      name: "Current",
      active: false,
      id: 3,
    },
  ]);
  const [year, setYear] = useState("");
  const [dateFormat, setDateFormat] = useState("");
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [serialResponse, SetSerialResponse] = useState();
  const [locationResponse, SetLocationResponse] = useState();

  const [serialnumberTempArray, setSerialnumberTempArray] = useState([]);
  const [locationTempArray, setLocationTempArray] = useState([]);

  const [serialnumberTempData, setSerialnumberTempData] = useState(
    pageContent?.tabContent
  );

  const serialnumberArray = tabData[0].tabBody;
  const tempserialnumberArray = serialnumberTempData[0].tabBody;



  // model from local storage1
  const model = localStorage.getItem("model");
  const categoryId = localStorage.getItem("dashboard_Id");
  // show and hide weekand year
  const [showFilteredCalender, setShowFilteredCalender] = useState(true);
  // api call for graph using serial numbers array
  useEffect(() => {
    //serialnumberArray to tempserialnumberArray
    trendsData(serialnumberTempData[0].tabBody, startDate, endDate, year, dateFormat);
  }, [year, dateFormat ,startDate,endDate, selectDeviceTabId]);
  // useEffect(() => {
   
  // }, [startDate, endDate]);
  // api- for location based trends graph
  useEffect(() => {
    trendsDataForLocation(
      tabData[1].tabBody,
      locationStartDate,
      locationEndDate,
      year
    );
  }, [year, dateFormat ,locationStartDate,locationEndDate,selectDeviceTabId]);
  // data for chart
  const serialNumberData = {
    labels,
    categoryPercentage: 0.6,
    datasets: dataSets?.[clickedLegendId] ? dataSets?.[clickedLegendId] : [],
  };
  // legend selection
  const legendClick = (legendId) => {
    // show scrollbar based on legend selection
    if (legendId !== 1) {
      setShowScrollbar(false);
    } else {
      if (dateFormat !== "WEEKLY") {
        setShowScrollbar(true);
      } else {
        setShowScrollbar(false);
      }
    }
    // -------------------------------------
    const updatedLegends = legends.map((item) => {
      if (item.id === legendId) {
        setClickedLegendId(item.id);

        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });

    setLegends(updatedLegends);
  };
  // converting date format
  function convertDateFormat(inputDate) {
    const date = inputDate.split("T")[0].replace(/-/g, "/");
    const parts = date.split("/"); // Split the date by forward slashes
    const month = new Date(`${parts[0]}/${parts[1]}/1`).toLocaleString(
      "default",
      { month: "short" }
    );
    const formattedDate = `${month} ${parseInt(parts[2], 10)}, ${parts[0]}`;

    return formattedDate;
  }
  // api call  -- graph for serial number
  const trendsData = async (
    targetDevices,
    startDate,
    endDate,
    year,
    dateFormat
  ) => {
    setIsLoading(true);
    let data = {
      filter: 1,
      targets: targetDevices,
      type: state?.productType,
      startDate: startDate,
      endDate: endDate,
      categoryId: categoryId,
      format: dateFormat,
      year: year,
    };
    try {
      const response = await plugTrends(data);
      if (response.data.status === 200) {
        const trendsDataItems = response?.data?.data;
        // data for exporting
        let serialExportDataArray = [];
        let exportableSerialData = {};
        trendsDataItems.map((item) => {
          exportableSerialData = {
            avgEnergy: item.avgEnergy,
            avgCurrent: item.avgCurrent,
            avgVoltage: item.avgVoltage,
            countOfOnlineDevices: item.countOfOnlineDevices,
            // new changes added 2  feild export data(averageActiveDevices,activeDevicesList)
            averageActiveDevices: item.averageActiveDevices,
            activeDevicesList: item.activeDevicesList.join(", "), //item.activeDevicesList,
            date: item.date,
            country: item.country,
            state: item.state,
            district: item.district,
          };
          if (item.srno) {
            exportableSerialData = {
              ...exportableSerialData,
              srno: item.srno,
            };
          }
          serialExportDataArray.push(exportableSerialData);
        });
        SetSerialResponse(serialExportDataArray);
        // getting empty data sets
        let trendsDataPresent = false;
        for (const item of trendsDataItems) {
          if (
            item?.energy.length === 0 &&
            item?.voltage.length === 0 &&
            item?.current.length === 0
          ) {
            trendsDataPresent = false;
          } else {
            trendsDataPresent = true;
            break;
          }
        }
        setEmptyGraphData(!trendsDataPresent);

        if (trendsDataItems == "") {
          // getting energy data sets
          setLabels([]);
        }

        let finalLabelArray = [];

        const energyDataSets = trendsDataItems?.map((item, index) => {
          const energies = item?.energy?.map((item) => {
            return Number(item.count);
          });
          const labelArray = item?.energy?.map((item) => {
            const formattedDate = convertDateFormat(item?.date);
            return formattedDate;
          });
          const labelArrayForYear = item?.energy?.map((item) => {
            const formattedDate = item?.date;
            return formattedDate;
          });

          if (finalLabelArray.length === 0) {
            // setting label array if label
            if (dateFormat === "WEEKLY") {
              finalLabelArray = labelArray;
            } else {
              finalLabelArray = labelArrayForYear;
            }
          }

          return {
            type: "bar",
            label: "Energy",
            backgroundColor: colors[index],
            barThickness: 10,
            data: energies,
            id: 1,
            borderWidth: 2,
            borderColor: "white",
            borderRadius: [5, 5, 5, 5],
          };
        });
        // for showing srcoll bars based on energy data and year selection
        if (dateFormat === "YEARLY") {
          if (clickedLegendId === 1) {
            setShowScrollbar(true);
          } else {
            setShowScrollbar(false);
          }
        } else {
          setShowScrollbar(false);
        }

        // getting voltage data sets
        const voltageDataSets = trendsDataItems?.map((item, index) => {
          const voltages = item?.voltage.map((item) => {
            return Number(item?.count);
          });
          const labelArray = item?.voltage?.map((item) => {
            const formattedDate = convertDateFormat(item.date);
            return formattedDate;
          });

          if (finalLabelArray.length === 0) {
            finalLabelArray = labelArray;
          }

          return {
            type: "line",
            label: `Voltage`,
            backgroundColor: colors[index],
            barThickness: 10,
            data: voltages,
            id: 1,
            borderWidth: 2,
            borderColor: colors[index],
            borderRadius: [5, 5, 5, 5],
            tension: 0.2,
          };
        });
        // getting current data sets
        const currentDataSets = trendsDataItems.map((item, index) => {
          const current = item?.current?.map((item) => {
            return Number(item?.count);
          });
          const labelArray = item.current.map((item) => {
            const formattedDate = convertDateFormat(item.date);
            return formattedDate;
          });

          if (finalLabelArray.length === 0) {
            finalLabelArray = labelArray;
          }

          return {
            type: "line",
            label: `Current`,
            backgroundColor: colors[index],
            barThickness: 10,
            data: current,
            id: 1,
            borderWidth: 2,
            borderColor: colors[index],
            borderRadius: [5, 5, 5, 5],
            tension: 0.2,
            // pointRadius: 0, // for hiding the point on line chart
          };
        });

        setDataSets({
          1: energyDataSets,
          2: voltageDataSets,
          3: currentDataSets,
        });

        setLabels(finalLabelArray); // lables array
      } else {
        setDataSets({
          1: [],
          2: [],
          3: [],
        });
        setLabels([]);
      }
    } catch (error) {}
    setIsLoading(false);
  };
  // function to active selected tab
  const handleClickActiveTab = (id) => {
   
    const tabData1 = [...tabData];
    const ActiveTabArray = tabData1?.map((item) => {
      const activeStatus = item?.tabId === id ? true : false;
      return { ...item, active: activeStatus };
    });
    setSelectDeviceTabId(id);
    setTabData(ActiveTabArray);
    
   
    // reset date when tab switching
    setStartDate(moment(new Date()).subtract(7, "days").format("ll"))
    setEndDate(moment(new Date()).subtract(1, "days").format("ll"))
    setLocationStartDate(moment(new Date()).subtract(7, "days").format("ll"))
    setLocationEndDate(moment(new Date()).subtract(1, "days").format("ll"))
    setEmptyGraphData(false);


  };
  // function to remove selected serial number
  const removeSelectedSerialNumber = (id, index) => {
    const tabData1 = [...tabData];
    // console.log("tabdata 1", tabData1)
    //tabdata1
    const selectedItemRemovedArray = serialnumberTempData.map((item) => {
      if (id === item.tabId) {
        item?.tabBody.splice(index, 1);
        return { ...item, tabBody: item?.tabBody };
      } else {
        return item;
      }
    });

    if (selectedItemRemovedArray[0].tabBody.length < 10) {
      setAddSerialNumberButtonShow(true);
    }

    // setTabData(selectedItemRemovedArray);
    // setTabData(updatedTabData)
    // Also update serialnumberTempArray after removal
    const updatedSerialnumberTempArray = [...serialnumberTempArray];
    updatedSerialnumberTempArray.splice(index, 1);

    setSerialnumberTempArray(updatedSerialnumberTempArray);

    setSerialnumberTempData(selectedItemRemovedArray);

    trendsData(
      updatedSerialnumberTempArray,
      // tabData[0].tabBody,
      startDate,
      endDate,
      year,
      dateFormat
    );
  };

  //function to show serial number adding modal
  const showSerialNumberAddingModal = (id) => {
    setSerialNumberAddingModal(true);
    setsSerialNumberTabId(id);
  };

  //function to close serial number adding modal
  const closeSerialNumberAddingModal = () => {
    setSerialNumberAddingModal(false);
    setInputvalidationError(false);
    setSerialNumberErrorMsg("");
    setAddSerialNumberButtonShow(true);
    setSerialNumber("");
  };

  // serial number input onChnage function
  const onChnageSerialNumberInput = (e) => {
    const inputValue = e.target?.value;
    if (serialNoExistError && inputValue?.length > serialNoPrevValueLength) {
      return false;
    }

    setSerialNoPrevValueLength(inputValue?.length);
    setSerialNoExistError(false);
    setSerialNumber(inputValue);
    validateserialNumberinput(inputValue, "onchange");
  };

  // serial number input onKeydown
  const onkeyDownSerialNumber = (e) => {
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  };

  // serial number input validation
  const validateserialNumberinput = (value, mode) => {
    let validationErrorStatus = false;
    if (value === "") {
      setInputvalidationError(true);
      setSerialNumberErrorMsg("");
      validationErrorStatus = true;
      return validationErrorStatus;
    }

    // splitting serial number array based on comma for 16 digits number
    if (value.includes(",")) {
      let splittedArray = value.split(",");
      for (const item of splittedArray) {
        if (item.length !== 16) {
          setInputvalidationError(true);
          validationErrorStatus = true;
          setSerialNumberErrorMsg("Please enter a valid device serial number");
          return validationErrorStatus;
        } else {
          setInputvalidationError(false);
          validationErrorStatus = false;
          setSerialNumberErrorMsg("");
        }
      }
    } else {
      if (value?.length !== 16) {
        setSerialNumberErrorMsg("Please enter a valid device serial number");
        setInputvalidationError(true);
        validationErrorStatus = true;
        return validationErrorStatus;
      } else {
        setSerialNumberErrorMsg("");
        setInputvalidationError(false);
        validationErrorStatus = false;
      }
    }

    // adding same serial number
    // change serialnumberArray to tempserialnumberArray its update state //
    if (tempserialnumberArray?.includes(value) == true) {
      // serialnumberArray
      setInputvalidationError(true);
      validationErrorStatus = true;
      setSerialNumberErrorMsg("This serial number is already added");
      return validationErrorStatus;
    }

    // checking if number is separated using morethan one comma
    if (!checkCommaSeparatedNumber(value)) {
      setInputvalidationError(true);
      validationErrorStatus = true;
      return validationErrorStatus;
    }

    // Validation to check how many serial numbers added
    let previousDataLength = 0;
    // tabdata
    serialnumberTempData?.forEach((item) => {
      if (serialNumberTabId === item?.tabId) {
        previousDataLength = item?.tabBody?.length; // existing serial number array length
      }
    });
    const splitedInputValue = value?.split(","); // split the values from input and return as array
    const arraylength = splitedInputValue?.length + previousDataLength; // check the length of array
    const serialNumberCount = 10;

    if (arraylength > serialNumberCount) {
      setInputvalidationError(true);
      validationErrorStatus = true;
      setSerialNumberErrorMsg("Only 10 serial numbers allowed");
      return validationErrorStatus;
    } else if (arraylength == serialNumberCount) {
      setAddSerialNumberButtonShow(false);

      validationErrorStatus = false;
      setSerialNumberErrorMsg("");
      setInputvalidationError(false);
    } else {
      setInputvalidationError(false);
      validationErrorStatus = false;
      setSerialNumberErrorMsg("");
    }

    // checking same serial number on seperated by commas
    if (mode === "onchange") {
      if (value.slice(-1) === ",") {
        const inputValuesArray1 = value.split(","); //spliting the values using comma
        const inputValuesArray = inputValuesArray1.slice(
          0,
          inputValuesArray1.length - 1
        ); // getting the value of last element of input array
        const currentValue = inputValuesArray[inputValuesArray.length - 1];
        const serialNumberPrevValuesArray1 = inputValuesArray.slice(
          0,
          inputValuesArray.length - 1
        ); // tabdata
        const newArray = tabData?.map((item) => {
          if (serialNumberTabId === item?.tabId) {
            return item?.tabBody;
          }
        });
        let combinedArray = newArray[0];
        if (serialNumberPrevValuesArray1?.length) {
          combinedArray = [...newArray[0], ...serialNumberPrevValuesArray1];
        }
        if (combinedArray.includes(currentValue)) {
          setSerialNoExistError(true);
          setInputvalidationError(true);
          validationErrorStatus = true;
          setSerialNumberErrorMsg("This serial number is already added");
        }
        if (combinedArray.length <= 9) {
          setAddSerialNumberButtonShow(true);
        }
      }
    } else if (mode === "onsubmit") {
      // check exisiting serial number on submiting
      const inputValuesArray = value?.split(",");

      const currentValue = inputValuesArray[inputValuesArray.length - 1];
      const serialNumberPrevValuesArray1 = inputValuesArray.slice(
        0,
        inputValuesArray.length - 1
      ); // change tabData to  serialnumberTempData because it store the serialnumber temp array value
      const newArray = serialnumberTempData?.map((item) => {
        if (serialNumberTabId === item?.tabId) {
          return item?.tabBody;
        }
      });

      let combinedArray = newArray[0];
      if (serialNumberPrevValuesArray1?.length) {
        combinedArray = [...newArray[0], ...serialNumberPrevValuesArray1];
      }

      if (combinedArray.includes(currentValue)) {
        setSerialNoExistError(true);
        setInputvalidationError(true);
        validationErrorStatus = true;
        setSerialNumberErrorMsg("This serial number is already added");
      }
      if (combinedArray.length < 9) {
        setAddSerialNumberButtonShow(true);
      }
    }

    return validationErrorStatus;
  };

  // serial number modal submit function
  const onSubmitSerialNumberInput = () => {
    //console.log("serialnumberTempData",serialnumberTempData)
    if (validateserialNumberinput(serialNumber, "onsubmit")) {
      // Handle validation failure case if needed
    } else {
      const SplitedSerialNumberArray = serialNumber.split(","); // split return array as string
      //change tabData to serialnumberTempData to identfy the tabb while switching
      const serialNumberAddedArray = serialnumberTempData?.map((item) => {
        if (serialNumberTabId === item?.tabId) {
          return {
            ...item,
            tabBody: [...item.tabBody, ...SplitedSerialNumberArray], // Add serial numbers to the correct tab
          };
        }
        return item;
      });

      setSerialnumberTempData(serialNumberAddedArray);

      // setTabData(serialNumberAddedArray);

      setSerialnumberTempArray([
        ...serialnumberTempArray,
        ...SplitedSerialNumberArray,
      ]);

      trendsData(
        //tabData[0].tabBody,
        serialNumberAddedArray[0].tabBody,
        startDate,
        endDate,
        year,
        dateFormat
      ); // API call for graph using serial numbers array

      setSerialNumberAddingModal(false);
      setSerialNumber(""); // Reset serial number input after submission
    }
  };
  // location dropdown on select funtion
  const onSelectLocation = (value, tabId) => {
    setlocationTabId(tabId);
    setLocationName(value?.name);
    //  filtering selected value and get selected location length
    let seletedLocationItem;
    if (selectedLocation?.includes(value?.id)) {
      seletedLocationItem = selectedLocation?.filter(
        (item) => item !== value?.id
      );
    } else {
      seletedLocationItem = [...selectedLocation, value?.name];
    }
    setSelectedLocation(seletedLocationItem);
    setLocationCount(seletedLocationItem.length);

    // change text according to number of location selected
    if (seletedLocationItem?.length === 1) {
      setLocationSelectedText("Location Selected");
    } else if (seletedLocationItem?.length > 1)
      setLocationSelectedText("Locations Selected");

    // Adding selected location to array
    const tabData1 = [...tabData];
    const locationUpdatedArray = tabData1?.map((item) => {
      if (tabId === item.tabId) {
        item?.tabBody.push(value?.name);

        return item;
      } else {
        return item;
      }
    });
    setTabData(locationUpdatedArray);

    // Add location to location temp array
    // if location count is more that 10 dropdown will be disable
    const locationValidationCount = 10;
    if (seletedLocationItem.length >= locationValidationCount) {
      setLocationDisable(true);
      setLocationErrorValidationMsg("Only 10 locations are allowed to select");
    } else {
      setLocationDisable(false);
      setLocationErrorValidationMsg("");
    }

    trendsDataForLocation(
      tabData[1].tabBody,
      locationStartDate,
      locationEndDate,
      year
    );
  };
  // function to remove selected item
  const removeSelectedLocation = (id, index) => {
    const locationValidationCount = 10;
    const tabData1 = [...tabData];
    const updatedTabData = tabData1?.map((item) => {
      if (id === item.tabId) {
        const updatedLocationArray = item?.tabBody;
        updatedLocationArray.splice(index, 1);
        setSelectedLocation(updatedLocationArray);
        setLocationCount(updatedLocationArray.length);
        if (updatedLocationArray.length >= locationValidationCount) {
          setLocationDisable(true);
          setLocationErrorValidationMsg(
            "Only 10 locations are allowed to select"
          );
        } else if (updatedLocationArray?.length === 1) {
          setLocationSelectedText("Location Selected");
        } else if (updatedLocationArray?.length === 0) {
          setLocationSelectedText();
        } else {
          setLocationDisable(false);
          setLocationErrorValidationMsg("");
        }
        return { ...item, tabBody: updatedLocationArray };
      } else {
        return item;
      }
    });
    setTabData(updatedTabData);
    trendsDataForLocation(
      tabData[1].tabBody,
      locationStartDate,
      locationEndDate,
      year
    );
  };

  // function to show export graph modal
  const showExportGraphModal = () => {
    setExportGraphModal(true);
  };
  //function to close export graph modal
  const closeExportmodal = () => {
    setExportGraphModal(false);
    setInputvalidationError(false);
    setSerialNumberErrorMsg("");
  };
  //export graph modal submit function
  const onExportGraph = () => {
    if ((csv == true) | (pdf == true)) {
      setExportGraphModal(false);
      setValidateExportGraph(false);
    } else {
      setValidateExportGraph(true);
    }
  };
  // date range picker selection for serial number
  const setSelectedDates = (startDate, endDate) => {
    // selecting data from calender
    setStartDate(startDate);
    setEndDate(endDate);
    serialnumberTempData.map((item) => {
      if (startDate != endDate) {
        //change tabData[0].tabBody to  serialnumberTempData[0].tabBody to identify the tab while switching for serial number
        trendsData(
          serialnumberTempData[0].tabBody,
          startDate,
          endDate,
          year,
          dateFormat
        ); // api call for specific date range
      }
    });
  };

  // ---------------------------------------LOCATION ------------------------
  // date range picker selection for location
  const setSelectedDatesLocation = (locationStartDate, locationEndDate) => {
    // selecting data from calender
    setLocationStartDate(locationStartDate);
    setLocationEndDate(locationEndDate);
    tabData.map((item) => {
      trendsDataForLocation(
        tabData[1].tabBody,
        locationStartDate,
        locationEndDate,
        year
      ); //api call for trends location
    });
  };
  useEffect(() => {
    getStateList();
  }, []);
  // api call- get state list
  const getStateList = async () => {
    try {
      const response = await getStateListData();
      const responseStateList = response.data.data;
      setStateAndDistrictList(responseStateList);
      const updatedStateListArray = responseStateList?.map((item) => {
        return {
          id: item?.stateId,
          name: item?.state,
        };
      });
      setStateList(updatedStateListArray);
    } catch {}
  };
  // selecting state on click
  const onSelectState = (value, id) => {
    setSelectedState(value.name);
    tabData?.map((item) => {
      item.tabBody = [];
    });
    trendsDataForLocation("", "", "", "");
    setLocationCount();
    setLocationSelectedText();
    setSelectedLocation([]);
    setLocationDisable(false);
    setLocationErrorValidationMsg("");
  };

  // filtering districts from api response
  const listDisctrict = [];
  const districtListArray = stateAndDistrictList?.filter((item) => {
    if (item?.state === selectedState) {
      item?.districts?.map((list) => {
        listDisctrict.push(list);
      });
      return listDisctrict;
    }
  });

  // updating district array key
  const updatedDistrictListArray = listDisctrict?.map((item) => {
    return {
      id: item?.districtId,
      name: item?.districtName,
    };
  });
  // Update location listing array by removing selected item
  const selectedLocationId = selectedLocation;
  const newLocationList = updatedDistrictListArray?.filter((item) => {
    return selectedLocationId.includes(item.name) === false;
  });
  // api- trends graph based on location
  const trendsDataForLocation = async (
    targetLocation,
    locationStartDate,
    locationEndDate,
    year
  ) => {
    setIsLoading(true);
    let data = {
      filter: 2,
      // targets: ["Ernakulam", "Idukki", "Kottayam"],
      targets: targetLocation,
      type: state?.productType,
      startDate: locationStartDate,
      endDate: locationEndDate,
      categoryId: categoryId,
      format: dateFormat,
      year: year,
    };
    try {
      const response = await plugTrends(data);
      if (response.data.status === 200) {
        const trendsDataItems = response?.data?.data;
        // data for exporting
        let locationExportDataArray = [];
        let exportableLcationData = {};
        trendsDataItems.map((item) => {
          exportableLcationData = {
            avgEnergy: item.avgEnergy,
            avgCurrent: item.avgCurrent,
            avgVoltage: item.avgVoltage,
            countOfOnlineDevices: item.countOfOnlineDevices,
            // new changes added 2  feild export data(averageActiveDevices,activeDevicesList)
            averageActiveDevices: item.averageActiveDevices,
            activeDevicesList: item.activeDevicesList.join(", "),
            date: item.date,
            country: item.country,
            state: item.state,
            district: item.district,
          };
          if (item.serialNumber != "") {
            exportableLcationData = {
              ...exportableLcationData,
              serialNumber: item.serialNumber,
            };
          }
          locationExportDataArray.push(exportableLcationData);
        });
        SetLocationResponse(locationExportDataArray);
        // getting empty data sets

        let trendsDataPresent = false;
        for (const item of trendsDataItems) {
          if (
            item?.energy.length === 0 &&
            item?.voltage.length === 0 &&
            item?.current.length === 0
          ) {
            trendsDataPresent = false;
          } else {
            trendsDataPresent = true;
            break;
          }
        }
        setEmptyGraphData(!trendsDataPresent);

        // getting energy data sets
        if (trendsDataItems == "") {
          setLabelsForLocation([]);
        }
        let finalLabelArray = [];

        const energyDataSets = trendsDataItems?.map((item, index) => {
          const energies = item?.energy?.map((item) => {
            return Number(item.count);
          });
          const labelArray = item?.energy?.map((item) => {
            const formattedDate = convertDateFormat(item?.date);
            return formattedDate;
          });
          const labelArrayForYear = item?.energy?.map((item) => {
            const formattedDate = item?.date;
            return formattedDate;
          });

          if (finalLabelArray.length === 0) {
            // setting label array if label
            if (dateFormat === "WEEKLY") {
              finalLabelArray = labelArray;
            } else {
              finalLabelArray = labelArrayForYear;
            }
          }

          return {
            type: "bar",
            label: "Energy",
            backgroundColor: colors[index],
            barThickness: 10,
            data: energies,
            id: 1,
            borderWidth: 2,
            borderColor: "white",
            borderRadius: [5, 5, 5, 5],
          };
        });
        // for showing srcoll bars based on energy data and year selection
        if (dateFormat === "YEARLY") {
          if (clickedLegendId === 1) {
            setShowScrollbar(true);
          } else {
            setShowScrollbar(false);
          }
        } else {
          setShowScrollbar(false);
        }
        // getting voltage data sets
        const voltageDataSets = trendsDataItems?.map((item, index) => {
          const voltages = item?.voltage.map((item) => {
            return Number(item?.count);
          });
          const labelArray = item?.voltage?.map((item) => {
            const formattedDate = convertDateFormat(item.date);
            return formattedDate;
          });

          if (finalLabelArray.length === 0) {
            finalLabelArray = labelArray;
          }

          return {
            type: "line",
            label: `Voltage`,
            backgroundColor: colors[index],
            barThickness: 10,
            data: voltages,
            id: 1,
            borderWidth: 2,
            borderColor: colors[index],
            borderRadius: [5, 5, 5, 5],
            tension: 0.2,
          };
        });
        // getting current data sets
        const currentDataSets = trendsDataItems.map((item, index) => {
          const current = item?.current?.map((item) => {
            return Number(item?.count);
          });
          const labelArray = item.current.map((item) => {
            const formattedDate = convertDateFormat(item.date);
            return formattedDate;
          });

          if (finalLabelArray.length === 0) {
            finalLabelArray = labelArray;
          }

          return {
            type: "line",
            label: `Current`,
            backgroundColor: colors[index],
            barThickness: 10,
            data: current,
            id: 1,
            borderWidth: 2,
            borderColor: colors[index],
            borderRadius: [5, 5, 5, 5],
            tension: 0.2,
            // pointRadius: 0, // for hiding the point on line chart
          };
        });

        setDataSetLocation({
          1: energyDataSets,
          2: voltageDataSets,
          3: currentDataSets,
        });

        setLabelsForLocation(finalLabelArray); // lables array
      } else {
        setDataSetLocation({
          1: [],
          2: [],
          3: [],
        });
        setLabelsForLocation([]);
      }
    } catch (error) {}
    setIsLoading(false);
  };
  // data for chart-location
  const locationData = {
    labels: labelsForLocation,
    categoryPercentage: 0.6,
    datasets: dataSetLocation?.[clickedLegendId]
      ? dataSetLocation?.[clickedLegendId]
      : [],
  };
  // year based selection-serial number based
  const updateSelectedYear = (selectedYear) => {
    setYear(selectedYear);
    // trendsData(serialnumberArray, startDate, endDate, selectedYear,dateFormat); // api call for graph using serial numbers array
  };
  // year based selection -location based
  const setSelectedYearForLocation = (selectedYear) => {
    setYear(selectedYear);
  };
  // year based selection -for serial number
  const setSelectedDateFormat = (format) => {
    setDateFormat(format);
  };
  // year based selection -for location
  const setSelectedDateFormatForLocation = (format) => {
    setDateFormat(format);
  };

  // ---------------------------graph export for serial number----------------------

  // Function to export data to Excel with three sheets
  const exportToExcel = (responseData) => {
    const workbook = XLSX.utils.book_new();
    const energySheet = XLSX.utils.json_to_sheet(responseData);
    XLSX.utils.book_append_sheet(workbook, energySheet, "Trends data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, "exportedData.xlsx");
  };
  // export graph for serial numbers
  const exportGraph = () => {
    exportToExcel(selectDeviceTabId === 1 ? serialResponse : locationResponse);
  };

  // calculation modal
  // show command details modal
  const showCalculationModal = (time) => {
    setCalculationModalShow(true);
  };

  // cancel table badge modal
  const badgeModalCancel = () => {
    setCalculationModalShow(false);
  };

  const providerValue = {
    locationData,
    serialNumberData,
    legends,
    legendClick,
    pageContent, // page static contents
    handleClickActiveTab, // function to active tab
    tabData, // state to store tab data
    removeSelectedSerialNumber, // function to remove selected item
    serialNumberAddingModal, // serial number adding modal state
    setSerialNumberAddingModal, // serial number adding modal set state
    showSerialNumberAddingModal, //function to show serial number adding modal
    closeSerialNumberAddingModal, //function to close serial number adding modal
    onChnageSerialNumberInput, // serial number input onChnage fuction
    serialNumber, // serial number input value storing state
    onkeyDownSerialNumber, // serial number input keydown function
    onSubmitSerialNumberInput, // serial number modal submit function
    InputValidationError, // state to checko input validation
    serialNumberErrorMsg, // state to show serial number input error message
    pageDummyContent, // location dummy list
    setSelectedLocation, // set state for selected location
    onSelectLocation, // location dropdown on select funtion
    locationName, // state that store selected location name
    locationCount, // number of location selected
    locationSelectedText, // location selected text
    colors, // color list for location
    newLocationList, // itrated location list
    removeSelectedLocation, // function to remove selected location
    locationDisable, // state to disable location dropdown
    locationErrorvalidationMsg, // state to store location validation message
    isLoading,
    showExportGraphModal,
    exportGraphModal,
    setExportGraphModal,
    closeExportmodal,
    onExportGraph,
    setPdf,
    setCsv,
    validateExportGraph,
    setValidateExportGraph,
    setSelectedDates,
    setSelectedDatesLocation,
    startDate,
    endDate,
    locationStartDate,
    locationEndDate,
    addSerialNumberButtonShow, //show add serial number button
    selectDeviceTabId,
    stateList,
    onSelectState,
    selectedState,
    updatedDistrictListArray,
    updateSelectedYear,
    setSelectedDateFormat,
    setSelectedYearForLocation,
    setSelectedDateFormatForLocation,
    showScrollbar,
    emptyGraphData,
    exportGraph,
    setCalculationModalShow,
    calculationModalShow,
    badgeModalCancel,
    showCalculationModal,
    showFilteredCalender,
    setShowFilteredCalender,
    serialnumberTempArray,
    setSerialnumberTempArray,
    locationTempArray,
    setLocationTempArray,
    serialnumberTempData,
    setSerialnumberTempData,
    
  };
  return (
    <PlugTrendsContext.Provider value={providerValue}>
      {children}
    </PlugTrendsContext.Provider>
  );
};
