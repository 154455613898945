import React from 'react'
import { PlugTrendsContextProvider } from './PlugTrendsView'
import PlugTrends from './views/PlugTrends'

const PlugTrendsPage = () => {
  return (
   <PlugTrendsContextProvider>
    <PlugTrends/>
   </PlugTrendsContextProvider>
  )
}

export default PlugTrendsPage;
