import React from "react";
import "./disabledBoxComponent.css";
const DisabledBoxComponent = ({ customClass, value, text }) => {
  return (
    <div className={`disabled-box ${customClass}`}>
      <span className="disabled-text">{text}</span>:
      <span className="disabled-value">{value}</span>
    </div>
  );
};

export default DisabledBoxComponent;
