import React, { useState, useEffect } from "react";

import LeftArrow from "../../assets/images/Left-arrow.png";
import RightArrow from "../../assets/images/right-Arrow.png";
import "./pagination.css";

export const Pagination = ({
  pageCount,
  handlePageChange,
  customClass,
  onChnagePagination,
  currentPageState,
  dataTablevalue,
}) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageStates, setPageStates] = useState([null]);

  useEffect(() => {
    const updatePageStates = [...pageStates];
    updatePageStates[currentPageNumber] = currentPageState;
    setPageStates(updatePageStates);
  }, [currentPageState]);

  const onHandleshowNextPage = (pageNo) => {
    setCurrentPageNumber(pageNo + 1);
    onChnagePagination(pageStates[pageNo]);
  };

  const onHandleshowPreviousPage = (pageNo) => {
    setCurrentPageNumber(pageNo - 1);
    onChnagePagination(pageStates[pageNo - 2]);
  };

  return (
    <div className={`pagination-component ${customClass}`}>
     
        <button
          className="left-arrow-botton"
          onClick={() => {
            onHandleshowPreviousPage(currentPageNumber);
          }}
         
           disabled={currentPageNumber === 1 ? "disabled" : ""}
        >
          <img src={LeftArrow} alt="left-arrow" />
        </button>
     

        <button
          className="right-arrow-botton"
          onClick={() => onHandleshowNextPage(currentPageNumber)}
          disabled={currentPageState === null || dataTablevalue.length < 10 ? "disabled"  :""}
         // disabled={currentPageState === null ? "disabled" : ""}
        >
          <img src={RightArrow} alt="left-arrow" />
        </button>
      
    </div>
  );
};
