import React, { createContext, useState, useEffect, useContext } from "react";
import {
  getDeviceAddedonChart,
  getModalPlugList,
  getCountOnline_offline_load,
  getCount_modalSelect,
  verifyModel,
  getModelFromAPI
} from "../../apis/Api";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { object } from "prop-types";
import { SIDEMENU } from "../../store/type";
import RouteConstant from "../../constants/routeConstants.json";
import { GlobalStateContext } from "../../store/store";

export const DashBoardPageContext = createContext({});
export const DashBoardContextProvider = ({ children }) => {
  const {
    state,
    changeSideMenu,
    changeSideMenuBasedOnSession,
    updateDeviceStatus,
  } = useContext(GlobalStateContext);
  // dummy  modal list
  const navigate = useNavigate();
  const location = useLocation();

  const [modalPlugList, setmodalPlugList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("All");
  const [selectedItemId, setSelectedItemId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [deviceAdded, setDevieAdded] = useState([]);
  const [countArray, setCountArray] = useState([]);
  const [onlineCount, setOnlineCount] = useState([]);
  const [offlineCount, setOfflineCount] = useState([]);
  const [errorCount, setErrorCount] = useState([]);
  const [serialNumber, setSerialNumber] = useState("");
  const [serialNumberRequired, setSerialNumberRequired] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const catogoryId = localStorage.getItem("dashboard_Id");
  const [countLoading, setCountLoading] = useState(true);
  const [modelRequired, setModelRequired] = useState(false);
  const [errorAlertShow, setErrorAlertShow] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");
  const [statusCode, setStatusCode] = useState()
  const [showGraphInfo,setShowGraphInfo,] = useState(false)
  // data for dispaly
  var date = new Date().toLocaleDateString();
  let previousDate = new Date(date);
  previousDate.setDate(previousDate.getDate() - 1);
  var currentDate = moment(previousDate).format("YYYY-MM-DD");
  useEffect(() => {
    getListOfplugModal();
    getDeviceAddedData();
    getCountOfOnline_OffLine_Error_onPageLoad();
    changeSideMenu("Plug");
  }, []);
  useEffect(() => {
    // Simulate a 1-second delay loder count on card
    const timeout = setTimeout(() => {
      setCountLoading(false);
    }, 1000);

    // Clear the timeout
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // api call
  const getListOfplugModal = async () => {
    setIsLoading(true);
    try {
      const response = await getModalPlugList(catogoryId);
      if (response.data.status == 200) {
        const modelList = response.data.data.models;
        const iterateModelArray = modelList?.map((item) => {
          return {
            id: item?.id,
            model: item?.name,
            name: `${item?.modelName} (${item?.name}) `,
          };
        });
        setmodalPlugList(iterateModelArray);
      }
      setIsLoading(false);
   
    } catch (error) {
     
      setIsLoading(false);
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        
        setStatusCode(error?.response?.data?.status)
        setIsLoading(false);
        const errorMessage = error?.response?.data?.message;
        setErrorAlertMessage(errorMessage);
        setErrorAlertShow(true);
      } else {
        setIsLoading(false);
        setErrorAlertMessage("Something went wrong...");
        setErrorAlertShow(true);
      }
      // setShowErrorAlert(true);
      // setShowErrorMessage(true);
    }
  };

  const getCountOfOnline_OffLine_Error_onPageLoad = async () => {
    setIsLoading(true);
    try {
      const response = await getCountOnline_offline_load(catogoryId);
      if (response.data.status == 200) {
        setOnlineCount(response.data.data.count.online);
        setOfflineCount(response.data.data.count.shutdown);
        setErrorCount(response.data.data.count.error);
      }
      setIsLoading(false);
    } catch (error) {
      
      setIsLoading(false);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
  };

  const getCountmodalSelect = async (catogoryId, id) => {
    setIsLoading(true);
    try {
      const response = await getCount_modalSelect(catogoryId, id);
      if (response.data.status == 200) {
        setOnlineCount(response.data.data.count.online);
        setOfflineCount(response.data.data.count.shutdown);
        setErrorCount(response.data.data.count.error);
      }
      setIsLoading(false);
    } catch (error) {
     
      setIsLoading(false);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
    // setIsLoading(false);
  };
  const getDeviceAddedData = async () => {
    setIsLoading(true);

    const data = {
      productId: selectedItemId ? selectedItemId : "",
      categoryId: catogoryId,
    };
    try {
      const response = await getDeviceAddedonChart(data);
      if (response.data.status == 200) {
        setDevieAdded(response.data.data.result);
      }
      setIsLoading(false);
    
    } catch (error) {
     
      setIsLoading(false);
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        setStatusCode(error?.response?.data?.status)
       
        setIsLoading(false);
        const errorMessage = error?.response?.data?.message;
        setErrorAlertMessage(errorMessage);
        setErrorAlertShow(true);
      } else {
        setIsLoading(false);
        setErrorAlertMessage("Something went wrong...");
        setErrorAlertShow(true);
      }
    }
    setIsLoading(false);
  };

  // dropdown select function
  const handleSelect = (id, name, model) => {
    setSelectedValue(name);
    setSelectedItemId(id);
    validateModelDropdown(id, name);
    getCountmodalSelect(catogoryId, id);
    localStorage.setItem("modelId",id)
    localStorage.setItem("model", model);
    getDeviceAddedDatamodelSelect(id, catogoryId);
  };
  /// device cahrt based on model selection
  const getDeviceAddedDatamodelSelect = async (selectedItemId, catogoryId) => {
    setIsLoading(true);
    const data = {
      productId: selectedItemId ? selectedItemId : "",
      categoryId: catogoryId,
    };
    try {
      const response = await getDeviceAddedonChart(data);
      if (response.data.status == 200) {
        setDevieAdded(response.data.data.result);
      }
      
      setIsLoading(false);
    } catch (error) {
      
      setIsLoading(false);
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        setStatusCode(error?.response?.data?.status)
        setIsLoading(false);
        const errorMessage = error?.response?.data?.message;
       
        setShowErrorAlert(true);
        setShowErrorMessage(errorMessage);
        setIsLoading(false);
        setShowErrorAlert(true);
      
      } else {
        setIsLoading(false);
        setShowErrorAlert(true);
        setShowErrorMessage("Something went wrong...");
        setShowErrorAlert(true);
       
      }
    }
    setIsLoading(false);
  };
  const validateModelDropdown = (id, name) => {
    let validationStatus = false;
    if (name === "All") {
      setModelRequired(false);
      validationStatus = false;
    } else if (id === "") {
      setModelRequired(true);
      validationStatus = true;
    } else {
      setModelRequired(false);
      validationStatus = false;
    }
    return validationStatus;
  };

  const countArraylist = deviceAdded.map((obj) => obj.count);

  // const monthList = deviceAdded.map((obj) =>
  //   moment(obj.month).format("MMM YYYY")
  // );

  let test = [];
  const monthList = deviceAdded.map((item) => {
    let month = item.month.slice(0, 3);
    test.push(month + " " + item.year);
  });
  const labels = test;
  const [legendMangement, setLegendManagement] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
  });

  const dataArray = countArraylist;

  // data  show bar chat
  const data1 = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "",
        backgroundColor: "#F4B351",
        data: legendMangement[3] ? dataArray : [],
        barThickness: 8,
        borderRadius: [5, 5, 5, 5],
        stack: "Stack 0",
        id: 3,
        borderColor: "black",
      },
    ],
  };

  const onClickSerialNumberSearch = (serialNumber) => {
    setIsLoading(false);
    localStorage.setItem("serialNo_NavBar", serialNumber);

    if (serialNumber === "" && selectedValue === null) {
      setSerialNumberRequired(true);
      setModelRequired(true);
      return true;
    } else if (serialNumber === "" && selectedValue !== null) {
      setModelRequired(false);
      setSerialNumberRequired(true);
      return true;
    } else if (selectedValue === null && serialNumber !== "") {
      setModelRequired(true);
      setSerialNumberRequired(false);

      return true;
    } else if (selectedValue === "All") {
      getmodelwithserialNumber()
      //setModelRequired(true);
      //setSerialNumberRequired(false);
    } else {
      setSerialNumberRequired(false);
      sessionStorage.setItem("productName", "singlePlug");
      changeSideMenu("singlePlug");
      verifyModelData();
      // navigate({
      //   pathname: RouteConstant.PLUG_DASHBOARD,
      //   state: {
      //     filter: 1,
      //     serialNumber: serialNumber,
      //     model: selectedValue,
      //     type: state?.productType,
      //     startDate: currentDate,
      //     endDate: currentDate,
      //   },
      //   search: "?plug=singlePlug",
      // });
      return false;
    }
  };
  // get model for serial number if all is selcted  api call from dropdownlist
  const getmodelwithserialNumber = async()=>{
    setIsLoading(true);
    const data = {
      type: "smartplug",
      serialNumber: serialNumber,
    };
    
    try {
      const response = await getModelFromAPI(data);
      if (response.data.status == 200) {
        //setModelValue(response.data.data)
        localStorage.setItem("model", response.data.data)
        // const das = localStorage.getItem("model")
      
     navigate({
      pathname: RouteConstant.PLUG_DASHBOARD,
      state: {
        filter: 1,
        serialNumber: serialNumber,
        model:  selectedValue,
        type: state?.productType,
        startDate: currentDate,
        endDate: currentDate,
      },
      search: "?plug=singlePlug",
    });
      } else if (response?.data?.status == 400) {
        setShowErrorAlert(true);
        setShowErrorMessage(response.data.data.error.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
    setIsLoading(false);
  }
  // model verification API
  const verifyModelData = async () => {
    setIsLoading(true);
    const data = {
      type: state?.productType,
      model: localStorage.getItem("model"),
      srno: serialNumber,
    };
    try {
      const response = await verifyModel(data);
      if (response.data.status == 200) {
        navigate({
          pathname: RouteConstant.PLUG_DASHBOARD,
          state: {
            filter: 1,
            serialNumber: serialNumber,
            model: selectedValue,
            type: state?.productType,
            startDate: currentDate,
            endDate: currentDate,
          },
          search: "?plug=singlePlug",
        });
      } else if (response?.data?.status == 400) {
        setShowErrorAlert(true);
        setShowErrorMessage(response.data.data.error.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
    setIsLoading(false);
  };

  //serail number textchange
  //navigateToStatusDeviceList
  const navigateToStatusDeviceList = (status) => {

    updateDeviceStatus(status);
    navigate(
      {
        pathname: RouteConstant.STATUS_DEVICE_LIST,

        search: "?plug=Plug",
      },
      {
        state: {
          deviceStatus: status,
          selectedModel: selectedValue,
          selectedModelId: selectedItemId,
        },
      }
    );
  };
  const navigateToLogin =()=>{

    //navigate(RouteConstant.LOGIN);
  }
  const showGraphInfoamtion =()=>{
    setShowGraphInfo(true)
  }
  const InfomationModalCancel =()=>{
    setShowGraphInfo(false)
  }
  const providerValue = {
    modalPlugList,
    setmodalPlugList,
    // onClickLegend,
    data1,
    handleSelect,
    setSelectedValue,
    setSelectedItemId,
    selectedValue,
    selectedItemId,
    onlineCount,
    offlineCount,
    errorCount,
    isLoading,
    onClickSerialNumberSearch, // serial number search bar onclick funtion
    serialNumber,
    setSerialNumber,
    serialNumberRequired,
    setSerialNumberRequired,
    countLoading,
    setCountLoading,
    showErrorAlert,
    setShowErrorAlert,
    showErrorMessage,
    modelRequired,
    setModelRequired,
    navigateToStatusDeviceList,
    errorAlertShow,
    setErrorAlertShow,
    errorAlertMessage,
    setErrorAlertMessage,
    navigateToLogin,
    statusCode,
    showGraphInfo,setShowGraphInfo,
    InfomationModalCancel,
    showGraphInfoamtion
  };
  return (
    <DashBoardPageContext.Provider value={providerValue}>
      {children}
    </DashBoardPageContext.Provider>
  );
};
