import React, { createContext, useContext, useState } from "react";
import { getPlugDeviceLog } from "../../apis/Api";
import { useEffect } from "react";
import { GlobalStateContext } from "../../store/store";

export const PlugDeviceLogContext = createContext({});
export const PlugDeviceLogContextProvider = ({ children }) => {
  const [deviceLogDataList, setDeviceLogDataList] = useState("");
  const [currentpageState, setCurrentPageState] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("");
  const { state } = useContext(GlobalStateContext);

  const serialNumber = localStorage.getItem("serialNo_NavBar");
  const deviceType = state.productType;
  const deviceModel = localStorage?.getItem("model");

  useEffect(() => {
    getDeviceLog(currentpageState, serialNumber, deviceType, deviceModel);
  }, []);

  const getDeviceLog = async (
    pageState,
    serialNumber,
    deviceType,
    deviceModel
  ) => {
    setShowLoader(true);
    const deviceData = {
      pageState: pageState,
      fetchSize: 10,
      srno: serialNumber,
      type: deviceType,
      model: deviceModel,
    };
    try {
      const response = await getPlugDeviceLog(deviceData);
      setShowLoader(false);
      if (response?.data?.status === 200) {
        setDeviceLogDataList(response?.data?.data?.rows);
        setCurrentPageState(response?.data?.data?.pageState);
      }
    } catch (error) {
      setErrorModal(true);
      setErrorModalMessage("Something went wrong");
    }
  };

  // pagination fuction
  const onHandlePagination = (pageState) => {
    getDeviceLog(pageState, serialNumber, deviceType, deviceModel);
  };

  const providerValue = {
    deviceLogDataList, // Device log data list
    currentpageState,
    onHandlePagination, // pagination function
    errorModal,
    showLoader,
    errorModalMessage,
    setErrorModal,
  };
  return (
    <PlugDeviceLogContext.Provider value={providerValue}>
      {children}
    </PlugDeviceLogContext.Provider>
  );
};
