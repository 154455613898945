import React from "react";
import { PlugPowerContextProvider } from "./PlugPowerView";
import PlugPower from "./views/PlugPower";

const PlugPowerPage = () => {
  return (
    <PlugPowerContextProvider>
      <PlugPower />
    </PlugPowerContextProvider>
  );
};

export default PlugPowerPage;
