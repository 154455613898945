import React from "react";
import { UserManagementContextProvider } from "./UserManagementView";
import UserManagement from "./views/UserManagement";

const UserManagementPage = () => {
  return (
    <UserManagementContextProvider>
      <UserManagement />
    </UserManagementContextProvider>
  );
};

export default UserManagementPage;
