import React from "react";
import { PlugDashBoardContextProvider } from "./plugdashboardview";
import PlugDashboardPage from "./views/PlugDashboardPage";
const PlugDashBoard = () => {
  return (
    <PlugDashBoardContextProvider>
      <PlugDashboardPage />
    </PlugDashBoardContextProvider>
  );
};

export default PlugDashBoard;
