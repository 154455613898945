import React from "react";
import NotifyConfiguration from "./views/NotifyConfiguration"; 
import { NotifyConfigurationContextProvider } from "./NotifyConfigurationView";
const NotifyConfigurationPage= () => {
    return (
     <NotifyConfigurationContextProvider>
      <NotifyConfiguration/>
     </NotifyConfigurationContextProvider>
    )
  }
  
  export default NotifyConfigurationPage