import html2canvas from "html2canvas";
import { createContext, useEffect } from "react";
import { GlobalStateContext } from "../../store/store";
import { useContext } from "react";
import { useState } from "react";
import { PlugConnectivityStatus } from "../../apis/Api";
import moment from "moment";
import { faL } from "@fortawesome/free-solid-svg-icons";

export const DeviceConnectivityContext = createContext({});
export const DeviceConnectivityContextProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("ll")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).subtract(1, "days").format("ll")
  );
  const [totalConnectivityStatus, setTotalConnectivityStatus] = useState("");
  const [connectivityGraphData, setConnectivityGraphData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [errorModal, setErrorModal] = useState(false); // api error alert
  const [errorModalMessage, setErrorModalMessage] = useState([]);
const [showGraphInfo,setShowGraphInfo,] = useState(false)
  const { state } = useContext(GlobalStateContext);
  const deviceType = state?.productType;
  const deviceModel = localStorage?.getItem("model");
  const serialNumber = localStorage?.getItem("serialNo_NavBar");

  // data getting dates
  const connectivityStatusDate = connectivityGraphData?.map((item) => {
    return item?.date;
  });

  // store device online, offline and unavailable data by mapping the API data
  const numberOfDeviceOnline = connectivityGraphData?.map((item) => {
    return item?.online;
  });
  const numberOfDeviceOffline = connectivityGraphData?.map((item) => {
    return item?.offline;
  });

  const numberOfDeviceUnavailable = connectivityGraphData?.map((item) => {
    return item?.error;
  });

  useEffect(() => {
    if (startDate && endDate) {
      getDeviceConectivityStatus(serialNumber, deviceType, deviceModel);
      setGraphDate();
    }
  }, [startDate, endDate]);

  const setGraphDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  // download graph as pdf
  const downloadFileHandler = async () => {
    const downloadDiv = document.getElementById("deviceConnectivityID");
    if (!downloadDiv) {
      return;
    }
    html2canvas(downloadDiv).then((canvas) => {
      const dataURL = canvas.toDataURL();
      const link = document.createElement("a");
      link.download = "device-connectivity.jpg";
      link.href = dataURL;
      link.click();
    });
  };

  const getDeviceConectivityStatus = async (
    serialNumber,
    deviceType,
    deviceModel
  ) => {
    setShowLoader(true);
    const data = {
      startDate: startDate,
      endDate: endDate,
      srno: serialNumber,
      type: deviceType,
      model: deviceModel,
    };
    try {
      const response = await PlugConnectivityStatus(data);
      setShowLoader(false);
      if (response?.data?.status === 200) {
        setConnectivityGraphData(response?.data?.data?.dates);
        setTotalConnectivityStatus(response?.data?.data?.time);
      }
    } catch (error) {
      if (error?.data?.status === 401) {
        setErrorModal(true);
        setErrorModalMessage(error?.response?.data?.message);
      } else {
        setErrorModal(true);
        // setErrorModalMessage(error?.response?.data?.message);
        setErrorModalMessage("Something went wrong");
      }
    }
  };

  // Date range

  // Graph integration
  const graphData = {
    labels: connectivityStatusDate,
    datasets: [
      {
        type: "bar",
        label: "Online",
        backgroundColor: "#F4B351",
        data: numberOfDeviceOnline,
        barThickness: 8,
        stack: "Stack 0",
        borderRadius: [5, 5, 5, 5],
      },
      {
        type: "bar",
        label: "Offline",
        backgroundColor: "#5A9EDD",
        data: numberOfDeviceOffline,
        borderRadius: [5, 5, 5, 5],
        barThickness: 8,
        stack: "Stack 0",
      },
      {
        type: "bar",
        label: "Data Unavailable",
        backgroundColor: "#767676",
        data: numberOfDeviceUnavailable,
        borderRadius: [5, 5, 5, 5],
        barThickness: 8,
        stack: "Stack 0",
      },
    ],
  };
const showGraphInfoamtion =()=>{
  setShowGraphInfo(true)
}
const InfomationModalCancel =()=>{
  setShowGraphInfo(false)
}
  const providerValue = {
    downloadFileHandler,
    totalConnectivityStatus,
    graphData,
    showLoader,
    startDate,
    endDate,
    setGraphDate,
    setErrorModal,
    errorModalMessage,
    errorModal,
    showGraphInfo,setShowGraphInfo,
    InfomationModalCancel,
    showGraphInfoamtion
  };
  return (
    <DeviceConnectivityContext.Provider value={providerValue}>
      {children}
    </DeviceConnectivityContext.Provider>
  );
};
