import React from "react";

import "./buttonComponent.css";
const ButtonComponent = ({
  buttonImage,
  customClass,
  onClick,
  type,
  text,
  coloredButton = false,
  buttonWidth,
  disabled
}) => {
  return (
    <div className="button-component">
      <button
        className={
          coloredButton
            ? `colored-button ${customClass}`
            : `outlined-button ${customClass}`
        }
        type={type}
        onClick={onClick}
        style={{ width: buttonWidth }}
        disabled={disabled}
      >
        {text}
        {buttonImage ? <img src={buttonImage} alt={"Submit"} /> : ""}
      </button>
    </div>
  );
};

export default ButtonComponent;
