import React from "react";
import "./CardStyle.css";
import CornerImage from "../../assets/images/cornerPattern.png";
const Card = ({ cardTypeText, countOnCard, cardColur, customClass ,onClick }) => {
  return (
    <div
      className={`card-types ${customClass}`}
      style={{ backgroundColor: cardColur }} 
      onClick={onClick}
    >
      <div className="card-text">{cardTypeText}</div>
      <div>
        <img src={CornerImage} alt="backgroundImage"  />
        <div className="countOn-TypeCard">{countOnCard}</div>
      </div>
    </div>
  );
};

export default Card;
