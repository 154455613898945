import React, { useContext, useEffect } from "react";
import "./createUserManagement.css";
import { CreateUserManagementContext } from "../CreateUserManagementView";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import SelectOption from "../../../components/SelectDropDown/SelectOption";
import InputComponent from "../../../components/InputComponent/InputComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import ErrorImage from "../../../assets/images/icons/Error.svg";
import SuccessImage from "../../../assets/images/Success.svg";
import DropDownItem from "../../../components/DropDownComponent/DropDown";
const CreateUserManagementPage = () => {
  const {
    name, //  state  show name
    email, //  state  show email
    roleList, // state show role list
    password, // state to show password
    confirmPassword, // state to show confirm password
    invalidName, // state to validate name
    invalidEmail, // state to validate email
    invalidPassword, // state to validate password
    invalidConfirmPassword, // state to validate confirm password
    onSubmitCreateUser, // submit click to crete or update user
    isEdit,
    validatedName,
    emailValidationMsg,
    passwordValidation,
    roleCategoryValidate,
    confirmPasswordValidation,
    handleName,
    handleEmailChange,
    handleRoleChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    roleListShown,
    userManagementList,
    successAlert,
    errorAlertShow,
    alertMessage,
    setErrorAlertShow,
    setSuccessAlert,
    spinnerStatus,
    statusCode,
    loginPage,
    roleName,
    role,
    updatedRoleList,
    onRoleChangeEdit,
  } = useContext(CreateUserManagementContext);
  useEffect(() => {
    roleListShown();
  }, []);

  return (
    <div className="create-user-page">
      {spinnerStatus && <Loader />}
      <div className="createuser-Text">
        {" "}
        {isEdit ? "Update User" : "Create User"}
      </div>
      <div>
        <div className="create-user-detail-div">
          <ThemeCard cardTitle="User Details">
            <MDBContainer>
              <MDBRow className="mb-3">
                <MDBCol size="3" className="dropDown-Role">
                  <label className="create-User-label">Name</label>
                  <InputComponent
                    placeholder="Enter Name"
                    type="text"
                    value={name}
                    onChange={handleName}
                    customClass={invalidName ? "error" : "valid"}
                  />
                  <p className="mb-0 error-msg">
                    {validatedName ? validatedName : ""}
                  </p>
                </MDBCol>
                <MDBCol size="3" className="dropDown-Role">
                  <label className="create-User-label">Email</label>
                  <InputComponent
                    placeholder="Enter Email"
                    value={email}
                    type="text"
                    onChange={handleEmailChange}
                    customClass={invalidEmail ? "error" : "valid"}
                    disabled={isEdit ? true : false}
                  />
                  <p className="mb-0 error-msg">
                    {emailValidationMsg ? emailValidationMsg : ""}
                  </p>
                </MDBCol>
                <MDBCol size="3" className="dropDown-Role">
                  <label className="create-User-label">Role</label>
                  {isEdit ? (
                    <DropDownItem
                      list={updatedRoleList}
                      placeHolderValue={"Select Role"}
                      onSelect={(value) => {
                        onRoleChangeEdit(value);
                      }}
                      selectedValue={roleName}
                    />
                  ) : (
                    <SelectOption
                      selectPlaceholder={"Select"}
                      values={roleList}
                      selectNum={"id"}
                      label={"roleName"}
                      handleSelectData={handleRoleChange}
                    />
                  )}

                  <p className="mb-0 error-msg">
                    {roleCategoryValidate ? roleCategoryValidate : ""}
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </ThemeCard>
        </div>
        {!isEdit ? (
          <div className="create-user-password-div">
            <ThemeCard cardTitle="Password">
              <MDBContainer>
                <MDBRow className="mb-3">
                  <MDBCol size="3" className="dropDown-Role">
                    <label className="create-User-label">Password</label>
                    <InputComponent
                      placeholder="Enter Password"
                      type="text"
                      value={password}
                      onChange={handlePasswordChange}
                      customClass={invalidPassword ? "error" : "valid"}
                    />
                    <p className="mb-0 error-msg">
                      {passwordValidation ? passwordValidation : ""}
                    </p>
                  </MDBCol>
                  <MDBCol size="3" className="dropDown-Role">
                    <label className="create-User-label">
                      Confirm Password
                    </label>
                    <InputComponent
                      placeholder="Enter Confirm Password"
                      value={confirmPassword}
                      type="text"
                      onChange={handleConfirmPasswordChange}
                      customClass={invalidConfirmPassword ? "error" : "valid"}
                    />
                    <p className="mb-0 error-msg">
                      {confirmPasswordValidation
                        ? confirmPasswordValidation
                        : ""}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </ThemeCard>
          </div>
        ) : (
          ""
        )}
        <div className="create-user-button-container">
          <ButtonComponent
            text={isEdit ? "Update" : "Submit"}
            coloredButton={true}
            customClass="ms-4"
            buttonWidth="110px"
            onClick={() => {
              onSubmitCreateUser();
            }}
          />
          <ButtonComponent
            text={"Cancel"}
            customClass="ms-4"
            buttonWidth="110px"
            onClick={() => {
              userManagementList();
            }}
          />
        </div>
      </div>
      {successAlert && (
        <Alertpopup
          image={SuccessImage}
          setShowModal={setSuccessAlert}
          showModal={successAlert}
          titleText="Success"
          DetailText={alertMessage}
          modalType="successForm"
          alertButtonText="Continue"
          onclickModalContinue={() => {
            userManagementList();
          }}
        />
      )}

      {errorAlertShow && (
        <Alertpopup
          image={ErrorImage}
          setShowModal={setErrorAlertShow}
          showModal={errorAlertShow}
          titleText="Error"
          DetailText={alertMessage}
          // modalType="error-modal"
          buttonText={statusCode == 401 ? "Login" : "Try Again"}
          onclickModalCancel={() => {
            setErrorAlertShow(false);
          }}
          onClick={() => {
            statusCode == 401 ? loginPage() : onSubmitCreateUser();
          }}
        />
      )}
    </div>
  );
};
export default CreateUserManagementPage;
