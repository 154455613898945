import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./selectOption.css";

const SelectItem = ({
  values,
  handleSelectData,
  selectNum,
  label,
  defaultValue,
  selectPlaceholder,
  selectedValue
}) => {
  return (
    <div className="selectData">
      <select value={defaultValue} onChange={handleSelectData}>
        <option  value="">{selectPlaceholder}</option>
        {values.map((item) => {
          return <option value={item[selectNum]}>{item[label]}</option>;
        })}
      </select>
    </div>
  );
};

export default SelectItem;
