import React from "react";
import { RetrofitDashBoardContextProvider } from "./RetrofitDashboardView"
import RetrofitDashboardPage from "./views/RetrofitDasboard";

const RetrofitDashBoard = () => {
  return (
    <RetrofitDashBoardContextProvider>
      <RetrofitDashboardPage />
    </RetrofitDashBoardContextProvider>
  );
};

export default RetrofitDashBoard;