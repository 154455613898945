import React from "react";
import { CreateUserManagementContextProvider } from "./CreateUserManagementView";
import CreateUserManagementPage from "./views/CreateUserManagement";
const CreateUserManagement = () => {
  return (
    <CreateUserManagementContextProvider>
      <CreateUserManagementPage />
    </CreateUserManagementContextProvider>
  );
};
export default CreateUserManagement;
