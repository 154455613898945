import React, { useContext } from "react";
import { DeviceAlertContext } from "../DeviceAlertView";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import exportGraph from "../../../assets/images/exportGraph.png";
import "./deviceAlert.css";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import DataTable from "../../../components/DataTableComponent/DataTable";
import { Pagination } from "../../../components/Pagination/Pagination";
import DatePickerModal from "../../../components/DatePickerModal/DatePickerModal";
import RefreshIcon from "../../../assets/images/icons/Refresh.svg";
import downloadcsvIcon from "../../../assets/images/icons/downloadcsv.svg";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import ErrorIcon from "../../../assets/images/icons/Error.svg";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import pagecontent from "../../../constants/ContentFile.json";
import SuccessImage from "../../../assets/images/Success.svg";
import moment from "moment";
const DeviceAlert = () => {
  const {
    header,
    responseData,
    exportDataCsv,
    selectExportDate,
    downlodDataModal,
    setDownlodDataModal,
    onHandlePagination,
    pageState,
    refreshClick,
    downloadCsv,
    showDownloadButton,
    isExportClicked,
    ondownloadClick,
    showLoader,
    showErrorPopup,
    setShowErrorPopup,
    popupErrorMsg,
    isReportGenerated,
    successModal,
    setSuccessModal,
    sucessModalText,
    exportTimeRange,
    isDownloadComplete,
    reportGeneratedStartDate,
    reportGeneratedEndDate,
  } = useContext(DeviceAlertContext);
  const date = new Date();
  const reportStartDate = moment(reportGeneratedStartDate, "YYYY-MM-DD").format(
    "MMM-D,YYYY"
  );
  const reportEndDate = moment(reportGeneratedEndDate, "YYYY-MM-DD").format(
    "MMM-D,YYYY"
  );
 
  const pageContents = pagecontent.alertsPage;
  return (
    <div className="device-alert-main-page">
      {showLoader ? <Loader /> : ""}

      {/* heading */}
      <div className="device-heading-row">
        <div className="device-alert-tilte"> {pageContents.alerts}</div>
        <div className="download-export-box">
          {isReportGenerated && (
            <div className="alert-selcted-date-range">
              Report From
              <div className="selcteddate-range">
                {reportStartDate} - {reportEndDate}
              </div>
            </div>
          )}

          {/* Render the Refresh button only if data is loaded, the report is not generated, and the download is not complete */}

          {isExportClicked && !isReportGenerated && (
            <div className="refresh-button-label">
              <div className="arrow_box">
                <p>Report generation in progress. Click refresh button</p>
              </div>
              <ButtonComponent
                coloredButton={true}
                customClass="settings-button"
                buttonImage={RefreshIcon}
                tittle={"loading"}
                onClick={refreshClick}
              />
            </div>
          )}
          {/* Render the Download CSV button only if the report is generated and the download is not complete */}
          {isReportGenerated && (
            <ButtonComponent
              text={pageContents.downloadcsv}
              buttonImage={downloadcsvIcon}
              buttonWidth="170px"
              onClick={ondownloadClick}
            />
          )}

          <ButtonComponent // export data button
            coloredButton={true}
            text={pageContents.exportcsv}
            customClass={"download-graph-button"}
            onClick={() => setDownlodDataModal(true)}
          />
        </div>
      </div>

      <ThemeCard>
        <DataTable header={header} tableData={responseData} />
      </ThemeCard>
      {responseData ? (
        <div className="pagination-button">
          <Pagination
            onChnagePagination={(pageState) => onHandlePagination(pageState)}
            currentPageState={pageState}
            dataTablevalue={responseData}
          />
        </div>
      ) : (
        ""
      )}

      <DatePickerModal
        title={"Export as CSV"}
        showModal={downlodDataModal}
        setShowModal={setDownlodDataModal}
        initialDate={date}
        // dateRange={30}
        getSelectedDates={(fromDate, toDate) => {
          selectExportDate(fromDate, toDate);
        }}
        getSelectedTime={(startTime, endTime) => {
          exportTimeRange(startTime, endTime);
        }}
        onClickSubmit={exportDataCsv}
        submitButtoncustomClass={"submit-button-box"}
        SubmitButtonText={"Export as CSV"}
      />
      {/* Alert pop to show error */}
      <Alertpopup
        image={ErrorIcon}
        setShowModal={setShowErrorPopup}
        showModal={showErrorPopup}
        titleText="Error"
        DetailText={popupErrorMsg}
        modalType="error-modal"
        alertButtonText="Continue"
      />
      {/* Alert pop to show succes */}
      <Alertpopup
        showModal={successModal}
        setShowModal={setSuccessModal}
        modalType={true}
        titleText={"Success"}
        DetailText={sucessModalText}
        image={SuccessImage}
        alertButtonText={"Continue"}
      ></Alertpopup>
    </div>
  );
};

export default DeviceAlert;
