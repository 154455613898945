import React from "react";

import "./radioButton.css";

const RadioButton = ({ onChangeRadioButton, checked, value }) => {
  return (
    <div className="radio-button">

        <input
          type="radio"
          name="inlineRadio"
          value={value}
          onChange={onChangeRadioButton}
          checked={checked}
        />

    </div>
  );
};

export default RadioButton;
