import React from 'react'
import { WaterHeaterDeviceDataContextProvider } from './WaterHeaterDeviceDataView'
import WaterHeaterDeviceData from './views/WaterHeaterDeviceData'

const WaterHeaterDeviceDataPage = () => {
  return (
   <WaterHeaterDeviceDataContextProvider>
    <WaterHeaterDeviceData/>
   </WaterHeaterDeviceDataContextProvider>
  )
}

export default WaterHeaterDeviceDataPage
