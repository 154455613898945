import React, { createContext, useState, useEffect, useContext } from "react";
import {
  getModalPlugList,
  verifyModel,
  getDeviceDataAPI,
  getFanSettingParameterAPI,
  getModelFromAPI,
} from "../../../apis/Api";
import { GlobalStateContext } from "../../../store/store";
import { useNavigate, useLocation } from "react-router";
import RouteConstant from "../../../constants/routeConstants.json";
import moment from "moment";
export const FanDashBoardPageContext = createContext({});
export const FanDashBoardContextProvider = ({ children }) => {
  // data for dispaly
  var date = new Date().toLocaleDateString();
  let previousDate = new Date(date);
  previousDate.setDate(previousDate.getDate() - 1);
  var currentDate = moment(previousDate).format("YYYY-MM-DD");
  const {
    state,
    changeSideMenu,
    changeSideMenuBasedOnSession,
    updateDeviceStatus,
  } = useContext(GlobalStateContext);
  // dummy  modal list
  const navigate = useNavigate();
  const location = useLocation();
  const [modalFanList, setmodalFanList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("All");
  const [selectedItemId, setSelectedItemId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [modelRequired, setModelRequired] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [serialNumberRequired, setSerialNumberRequired] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [statusCode, setStatusCode] = useState();
  const catogoryId = localStorage.getItem("dashboard_Id");
  const [currentpageState, setCurrentPageState] = useState(null);
  const [modelValue, setModelValue] = useState("");
  useEffect(() => {
    getListOfplugModal();

    setSelectedValue("All");
    changeSideMenu("Fan");
  }, []);
  const getListOfplugModal = async () => {
    setIsLoading(true);
    try {
      const response = await getModalPlugList(catogoryId);
      if (response.data.status == 200) {
        const modelList = response.data.data.models;
        const iterateModelArray = modelList?.map((item) => {
          return {
            id: item?.id,
            model: item?.name,
            name: `${item?.modelName} (${item?.name}) `,
          };
        });
        setmodalFanList(iterateModelArray);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.status === 400 ||
        error?.response?.data?.status === 401
      ) {
        setStatusCode(error?.response?.data?.status);
        setIsLoading(false);
        const errorMessage = error?.response?.data?.message;
        setShowErrorMessage(errorMessage);
        setShowErrorAlert(true);
      } else {
        setIsLoading(false);
        setShowErrorMessage("Something went wrong...");
        setShowErrorAlert(true);
      }
    }
  };

  // dropdown select function
  const handleSelect = (id, name, model) => {
    setSelectedValue(name);
    setSelectedItemId(id);
    validateModelDropdown(id, name);
    localStorage.setItem("modelId", id);
    localStorage.setItem("model", model);
  };


  const productType = state?.productType;
  const onClickSerialNumberSearch = (serialNumber) => {
    setIsLoading(false);

    localStorage.setItem("serialNo_NavBar", serialNumber);

    if (serialNumber === "" && selectedValue === null) {
      setSerialNumberRequired(true);
      setModelRequired(true);
      return true;
    } else if (serialNumber === "" && selectedValue !== null) {
      setModelRequired(false);
      setSerialNumberRequired(true);
      return true;
    } else if (selectedValue === null && serialNumber !== "") {
      setModelRequired(true);
      setSerialNumberRequired(false);

      return true;
    } else if (selectedValue === "All") {
      getmodelwithserialNumber();

      // setModelRequired(true);
      // setSerialNumberRequired(false);
    } else {
      setSerialNumberRequired(false);
      sessionStorage.setItem("productName", "smartfan");
      changeSideMenu("Fan");
      const devicemodel = localStorage.getItem("model");
      verifyModelData(devicemodel, productType, serialNumber, currentpageState);

      return false;
    }
  };
  // get model for serial number if all is selcted  api call from dropdownlist
  const getmodelwithserialNumber = async () => {
    setIsLoading(true);
    const data = {
      type: "smartfan",
      serialNumber: serialNumber,
    };

    try {
      const response = await getModelFromAPI(data);
      if (response.data.status == 200) {
        setModelValue(response.data.data);
        localStorage.setItem("model", response.data.data);
        // const dasfan = localStorage.getItem("model")
  
        navigate({
          pathname: RouteConstant.FAN_DEVICE_DATA,
          state: {
            filter: 1,
            serialNumber: serialNumber,
             model:  selectedValue,
            type: state?.productType,
            startDate: currentDate,
            endDate: currentDate,
          },
          search: "?fan=singleFan",
        });
      } else if (response?.data?.status == 400) {
        setShowErrorAlert(true);
        setShowErrorMessage(response.data.data.error.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
    setIsLoading(false);
  };
  // model verification API
  const verifyModelData = async (
    devicemodel,
    productType,
    serialNumber,
    currentpageState
  ) => {
    setIsLoading(true);
    const data = {
      model: devicemodel,
      type: productType,
      date: moment(date).format("YYYY-MM-DD"),
      srno: serialNumber,
      fetchSize: 10,
      pageState: currentpageState,
    };

    try {
      const response = await verifyModel(data); //getFanSettingParameterAPI
      if (response.data.status == 200) {
        navigate({
          pathname: RouteConstant.FAN_DEVICE_DATA,
          state: {
            filter: 1,
            serialNumber: serialNumber,
            model: selectedValue,
            type: state?.productType,
            startDate: currentDate,
            endDate: currentDate,
          },
          search: "?fan=singleFan",
        });
      } else if (response?.data?.status == 400) {
        setShowErrorAlert(true);
        setShowErrorMessage(response.data.data.error.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorMessage(true);
    }
    setIsLoading(false);
  };

  // validate dropdown
  const validateModelDropdown = (id, name) => {
    let validationStatus = false;
    if (name === "All") {
      setModelRequired(false);
      validationStatus = false;
    } else if (id === "") {
      setModelRequired(true);
      validationStatus = true;
    } else {
      setModelRequired(false);
      validationStatus = false;
    }
    return validationStatus;
  };
  const providerValue = {
    modalFanList,
    setmodalFanList,
    selectedValue,
    setSelectedValue,
    selectedItemId,
    setSelectedItemId,
    isLoading,
    setIsLoading,
    handleSelect,
    modelRequired,
    setModelRequired,
    onClickSerialNumberSearch,
    serialNumber,
    setSerialNumber,
    serialNumberRequired,
    setSerialNumberRequired,
    showErrorAlert,
    setShowErrorAlert,
    showErrorMessage,
    setShowErrorMessage,
    statusCode,
    setStatusCode,
    currentpageState,
    setCurrentPageState,
    modelValue,
    setModelValue,
  };
  return (
    <FanDashBoardPageContext.Provider value={providerValue}>
      {children}
    </FanDashBoardPageContext.Provider>
  );
};
