import {
  Children,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Badge } from "react-bootstrap";
import moment from "moment";
import {
  downloadCsvData,
  exportCsvData,
  postAlertTargetDevice,
} from "../../apis/Api";
import { GlobalStateContext } from "../../store/store";
export const DeviceAlertContext = createContext({});
export const DeviceAlertContextProvider = ({ children }) => {
  const { state } = useContext(GlobalStateContext);
  const [pageState, setpageState] = useState(null);
  const [responseData, setResponseData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [downlodDataModal, setDownlodDataModal] = useState(false);
  const [isExportClicked, setIsExportClicked] = useState(false);
  const [isUrl, setIsUrl] = useState("");
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [popupErrorMsg, setPopupErrorMsg] = useState("");
  const [successModal, setSuccessModal] = useState(false); // success modal show and hide
  const [sucessModalText, setSuccessModalText] = useState("");
  const [isDownloadComplete ,setIsDownloadComplete] = useState(false)
  const [reportGeneratedStartDate,setReportGeneratedStartDate] = useState("")
  const [reportGeneratedEndDate,setReportGeneratedEndDate] = useState("")
  const [startTime, setStartTime] = useState(
    new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  );
  const [endTime, setEndTime] = useState(
    new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
  );
  const deviceModel = localStorage?.getItem("model");
  const serialNumber = localStorage?.getItem("serialNo_NavBar");
  const productType = state?.productType;

  const serialmnuber = localStorage.getItem("serialNo_NavBar");
  const data = {
    srno: serialmnuber,
    fetchSize: 10,
  };
  useEffect(() => {
    GetTargetDeviceList(data, pageState);
    downloadCsv();
  }, []);

  const GetTargetDeviceList = async (data, pageState) => {
    setShowLoader(true);
    try {
      const getData = await postAlertTargetDevice(data, pageState);
      setResponseData(getData?.data?.data?.rows);
      setpageState(getData?.data?.data?.pageState);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setShowErrorPopup(true);
      setPopupErrorMsg("Something went wrong");
    }
  };

  const header = [
    {
      title: "Raised On",
      prop: "time",
      cell: (row) => (
        <div>
          <div>{moment(row.time).format("DD/MM/YYYY")}</div>
          <div>{moment(row?.time).format("HH:mm:ss")}</div>
        </div>
      ),
    },

    {
      title: "Alert Details",
      prop: "alert_message",
      cell: (row) => (
        <div>
          <div>{row.alert_message}</div>
        </div>
      ),
    },
  ];

  // api call- export csv | create csv
  const exportDataCsv = async () => {
    setIsReportGenerated(false);
    setDownlodDataModal(false);
    const data = {
      key: "Alerts",
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      srno: serialNumber,
      model: deviceModel,
      type: productType,
      startTime: startTime,
      endTime: endTime,
    };
    setShowLoader(true);

    try {
      const response = await exportCsvData(data);
      setIsExportClicked(true);
      setShowLoader(false);
      setSuccessModal(true);
      setSuccessModalText(
        "Data is exported successfully,Report generation in progress"
      );
    } catch (error) {
      setShowLoader(false);
      setShowErrorPopup(true);
      setPopupErrorMsg("Something went wrong");
    }
  };
  // function to change date on download data
  const selectExportDate = (fromDate, toDate) => {
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  // pagination fuction
  const onHandlePagination = (pageState) => {
    GetTargetDeviceList(data, pageState);
  };
  // refresh click on for download data
  const refreshClick = () => {
    setIsUrl("");
    if (isReportGenerated) {
      setIsExportClicked(false);
    }
    setShowDownloadButton(true);
    downloadCsv();
  };
  // downloading data on clicking
  const ondownloadClick = () => {
    if (isUrl) {
      setShowLoader(true);
      window.open(isUrl, "_self");
    }
    setShowLoader(false);
  };
  // api call -download data
  const downloadCsv = async () => {
    setShowLoader(true);
    const data = {
      key: "Alerts",
      srno: serialNumber,
    };
    try {
      const response = await downloadCsvData(data);
      setIsUrl(response.data.data.url);
     
      setIsReportGenerated(response.data.data.reportGenerated);
      setReportGeneratedStartDate(response.data.data.startDate)
      setReportGeneratedEndDate(response.data.data.endDate)
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setShowErrorPopup(true);
      setPopupErrorMsg("Something went wrong");
    }
  };
  // function to set  time range  for export graph
  const exportTimeRange = (startTime, endTime) => {
    setStartTime(startTime);
    setEndTime(endTime);
  };
  const providerValue = {
    header,
    responseData,
    exportDataCsv,
    selectExportDate,
    downlodDataModal,
    setDownlodDataModal,
    onHandlePagination,
    pageState,
    isExportClicked,
    isUrl,
    refreshClick,
    downloadCsv,
    ondownloadClick,
    showDownloadButton,
    showLoader,
    showErrorPopup,
    setShowErrorPopup,
    popupErrorMsg,
    isReportGenerated,
    successModal,
    setSuccessModal,
    sucessModalText,
    setSuccessModalText,
    exportTimeRange,
    isDownloadComplete ,setIsDownloadComplete,
    reportGeneratedStartDate,setReportGeneratedStartDate, // alert start date foe export csv
    reportGeneratedEndDate,setReportGeneratedEndDate // alert end date foe export csv
  };
  return (
    <DeviceAlertContext.Provider value={providerValue}>
      {children}
    </DeviceAlertContext.Provider>
  );
};
