import React, { useContext } from "react";
import {StabilizerDashBoardPageContext } from "../StabilizerDashboardView";
import RoundedDropDownItem from "../../../../components/RoundedDropDownComponent/RoundedDropDown";
import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import ThemeCard from "../../../../components/ThemeCardComponent/ThemeCard";
import Loader from "../../../../components/LoaderComponent/LoaderComponent";
import InputComponent from "../../../../components/InputComponent/InputComponent";
import Search from "../../../../assets/images/icons/Search.svg";
import Alertpopup from "../../../../components/AlertPopup/AlertPopup";
 import Error from "../../../../assets/images/icons/Error.svg";

import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import "./stabilizerDashBoard.css";

import DashboardPageContent from "../../../../constants/ContentFile.json";
const StabilizerDashboardPage = () => {
  const {
    isLoading,
    modalFanList,
    selectedValue,
    handleSelect,
    modelRequired,
    onClickSerialNumberSearch,
    serialNumber,
    setSerialNumber,
    serialNumberRequired,
    setSerialNumberRequired,
    showErrorAlert,
    setShowErrorAlert,
    showErrorMessage,
    setShowErrorMessage
  } = useContext(StabilizerDashBoardPageContext);
   
  const dashboardContent = DashboardPageContent.DashboardPageContent;

  return (
    <div className="stabilizer-dashboard-page">
      {isLoading ? <Loader /> : ""}
      <MDBContainer fluid>
        <ThemeCard customClass="title-none">
          <div className="input-box">
            <MDBRow>
              <MDBCol size="md-6">
                <RoundedDropDownItem
                  list={modalFanList}
                  selectedValue={selectedValue}
                  // onSelect={handleSelect}
                  onSelect={(id, name, model) => {
                    handleSelect(id, name, model);
                  }}
                  customClass={`customRounded-dropdown ${modelRequired ? 'highlighted' : ''}`}
                  // customClass={
                  //   modelRequired === true ? "customRounded-dropdown" : ""
                  // }
                  PlaceHolderValue="Select a model"
                />
              </MDBCol>
              <MDBCol size="md-4">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onClickSerialNumberSearch(serialNumber);
                  }}
                >
                  <InputComponent
                    roundedButton={true}
                    image={Search}
                    placeholder={dashboardContent.searchDevice}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    onChange={(e) => {
                      setSerialNumberRequired(false);
                      setSerialNumber(e.target.value);
                    }}
                    value={serialNumber}
                    type="number"
                    customClass={
                      serialNumberRequired ? "error-input-field" : ""
                    }
                  />
                </form>
              </MDBCol>
              <MDBCol size="md-2">
                <ButtonComponent
                  customClass={"submit-button"}
                  coloredButton={true}
                  text={"Submit"}
                  onClick={() => {
                    onClickSerialNumberSearch(serialNumber);
                  }}
                  buttonWidth="100%"
                />
              </MDBCol>
            </MDBRow>
          </div>
        </ThemeCard>

        <Alertpopup
        image={Error}
        setShowModal={setShowErrorAlert}
        showModal={showErrorAlert}
        titleText="Error"
        DetailText={showErrorMessage}
        modalType="error-modal" 
        alertButtonText="Continue"
        onclickModalContinue={()=>{setShowErrorAlert(false)}}
      ></Alertpopup> 
       
       
      </MDBContainer>
    </div>
  );
};
export default StabilizerDashboardPage;
