import React, { useEffect, useState } from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import RadioButton from "../RadioButton/RadioButton";
import ClockIcon from "../../assets/images/icons/Time-inteval-icon.svg";
import ContentFile from "../../constants/ContentFile.json";
import "./timeIntervalModal.css";

const TimeIntervalModal = ({
  showModal,
  setShowModal,
  title,
  onClickSubmit,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const timeInterval = ContentFile?.timeInterval;

  const onRadioButtonChange = (value) => {
    setSelectedValue(value);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setSelectedValue("");
  };

  return (
    <div className="time-inteval-modal">
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        onClickCancel={toggleModal}
        title={title}
        cancelButtonText="Cancel"
        SubmitButtonText="Submit"
        customWidth="modal-width"
        buttonCenter={true}
        onClickSubmit={() => {
          onClickSubmit(selectedValue);
          setSelectedValue("");
        }}
        submitButtonDisable={selectedValue === "" ? true : false}
        submitButtoncustomClass="submit-button"
      >
        {timeInterval?.map((item, index) => {
          return (
            <div className="time-interval-container mt-4" key={index}>
              <div className="d-flex">
                <img src={ClockIcon} alt="Clock icon" />
                <div className="ms-3 time-label">{item?.time}</div>
              </div>
              <RadioButton
                value={item?.value}
                checked={selectedValue === item?.value}
                onChangeRadioButton={() => {
                  onRadioButtonChange(item?.value);
                }}
              />
            </div>
          );
        })}
      </ModalComponent>
    </div>
  );
};

export default TimeIntervalModal;
