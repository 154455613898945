const checkCommaSeparatedNumber = (number) => {
  const regex = /^\d+(,\d+)*$/;
  return regex.test(number);
};
const validateMobileNumber = (number) => {
  const regex = /^\+?[1-9][0-9]{7,12}$/;
  return regex.test(number);
};

const blankSpaceCheck = (value) => {
  const regex = /^\s|\s$/;
  return regex.test(value);
};
const validateEmail = (emailId) => {
  const regEmail = /\S+@\S+\.\S+/;

  return regEmail.test(emailId);
};
const validateName = (name) => {
  const regName = /^((?:[A-Za-z]+ ?){1,3})$/; ///^([a-zA-Z]+(?: [a-zA-Z]+)?)$/; ///^.+\s.+$/g;
  return regName.test(name);
};
const nonEmpty = (value) => {
  if (value.length < 1) {
    return false;
  } else {
    return true;
  }
};

const spaceCheck = (value) => {
  const inputCheck = /^\s+|\s+$/g;
  return inputCheck.test(value);
};

const singleNumber = (password) => {
  const reg = /\d/;
  return reg.test(password);
};

const miniCharacters = (password) => {
  const passwordRegex = /^.{8,}$/;
  return passwordRegex.test(password);
};

const specialCharacter = (password) => {
  const passwordRegex = /[~@#$%^&*()]/;
  return passwordRegex.test(password);
};

const capitalLetterValidate = (password) => {
  const reg = /[A-Z]/;
  return reg.test(password);
};

const lowercaseLetterValidate = (password) => {
  const reg = /[a-z]/;
  return reg.test(password);
};
// Function to get a cookie value
  const getCookie = (name) => {
    const cookie = {};
    document.cookie.split(';').forEach(function (el) {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });
    return cookie[name];
  };

  // Function to delete a cookie
  const deleteCookie = (name) => {
    document.cookie = name + '=; expires=' + new Date(0).toUTCString() + ';';
  };

  // Function to create a cookie
  const createCookie = (name, value, days) => {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    } else {
      expires = '';
    }

    document.cookie = name + '=' + value + expires + '; path=/';
  };


export {
  checkCommaSeparatedNumber,
  blankSpaceCheck,
  validateMobileNumber,
  validateEmail,
  validateName,
  nonEmpty,
  spaceCheck,
  singleNumber,
  miniCharacters,
  specialCharacter,
  capitalLetterValidate,
  lowercaseLetterValidate,
  getCookie,
  deleteCookie,
  createCookie,
};
