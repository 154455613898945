import React, { useContext, useState } from "react";
import { PlugTrendsContext } from "../PlugTrendsView";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import "./plugTrends.css";
import exportIcon from "../../../assets/images/exportGraph.png";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import MixedChart from "../../../components/ChartComponent/ChartComponent";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import DropDownItem from "../../../components/DropDownComponent/DropDown";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import InputComponent from "../../../components/InputComponent/InputComponent";
import CloseButton from "../../../assets/images/icons/Close-button.svg";
import csvFormat from "../../../assets/images/icons/exportGraphModal.svg";
import pdf from "../../../assets/images/icons/PDF.svg";

import AddButton from "../../../assets/images/icons/Add-icon.svg";
import emptyDataIcon from "../../../assets/images/icons/emptyData.svg";
import {
  MDBCol,
  MDBRow,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import RadioButton from "../../../components/RadioButton/RadioButton";
import infoIcon from "../../../assets/images/icons/info-button.svg";
import WeekAndYearPicker from "../../../components/WeekAndYearPickerComponent/WeekAndYearPicker";
const PlugTrends = () => {
  const {
    serialNumberData,
    locationData,
    legendClick,
    legends,
    pageContent, // page static contents

    handleClickActiveTab, // function to active tab
    tabData, // state to store tab data
    removeSelectedSerialNumber, // function to remove selected item
    serialNumberAddingModal, // serial number adding modal state
    setSerialNumberAddingModal, // serial number adding modal set state
    showSerialNumberAddingModal, //function to show serial number adding modal
    closeSerialNumberAddingModal, //function to close serial number adding modal
    onChnageSerialNumberInput, // serial number input onChnage fuction
    serialNumber, // serial number input value storing state
    onkeyDownSerialNumber, // serial number input keydown function
    onSubmitSerialNumberInput, // serial number modal submit function
    InputValidationError, // state to check input validation
    serialNumberErrorMsg, // state to show serial number input error message
    onSelectLocation, // location dropdown on select funtion
    locationCount, // number of location selected
    locationSelectedText, // location selected text
    colors, // color list for location
    newLocationList, // itrated location list
    removeSelectedLocation, // function to remove selected location
    locationDisable, // state to disable location dropdown
    locationErrorvalidationMsg, // state to store location validation message
    isLoading,
    showExportGraphModal,
    exportGraphModal,
    setExportGraphModal,
    closeExportmodal,
    onExportGraph,
    setPdf,
    setCsv,
    validateExportGraph,
    setValidateExportGraph,
    setSelectedDates,
    setSelectedDatesLocation,
    startDate,
    endDate,
    addSerialNumberButtonShow, //show add serial number button
    selectDeviceTabId,
    stateList,
    onSelectState,
    selectedState,
    locationStartDate,
    locationEndDate,
    updateSelectedYear,
    setSelectedDateFormat,
    setSelectedYearForLocation,
    setSelectedDateFormatForLocation,
    showScrollbar,
    emptyGraphData,
    exportGraph,
    setCalculationModalShow,
    calculationModalShow,
    badgeModalCancel,
    showCalculationModal,
    showFilteredCalender,
    setShowFilteredCalender,
    serialnumberTempArray,
    setSerialnumberTempArray,
    locationTempArray,
    setLocationTempArray,
    serialnumberTempData,
  } = useContext(PlugTrendsContext);
  let currentDate = new Date();
  const previousDay = new Date(currentDate.setDate(currentDate.getDate() - 1));
  const [yaxisLabel, setYaxisLabel] = useState("Average Voltage");
  return (
    <div className="plug-trends-main">
      <div className="trends-export-row">
        <div className="trends-heading">Trends</div>
        <div>
          <ButtonComponent
            coloredButton={true}
            text={"Export "}
            buttonImage={exportIcon}
            customClass={"trends-button"}
            onClick={exportGraph}
          />
        </div>
      </div>

      <div>
        <ThemeCard cardTitle={pageContent.selectDevice}>
          {" "}
          {/* ----------------- Tab component start ------------------------------- */}
          <div className="select-tab">
            <MDBTabs>
              {tabData?.map((item, index) => {
                return (
                  <>
                    <MDBTabsItem key={index}>
                      <MDBTabsLink
                        onClick={() => handleClickActiveTab(item?.tabId)}
                        active={item?.active}
                      >
                        {item?.tabTitle}
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </>
                );
              })}
            </MDBTabs>
            <MDBTabsContent>
              {tabData?.map((item, index) => {
                return (
                  <MDBTabsPane show={item?.active} key={index}>
                    {/* --------------------------Location dropdown  start --------------- */}

                    {item?.tabId === 2 ? (
                      <div>
                        <MDBRow>
                          <MDBCol md="4" className="dropdown-margin-top">
                            <label className="location-label">State</label>
                            <DropDownItem
                              list={stateList}
                              placeHolderValue="Select State"
                              onSelect={(value) => {
                                onSelectState(value, item?.tabId);
                              }}
                              selectedValue={selectedState}
                            />
                          </MDBCol>
                          {selectedState && (
                            <MDBCol md="4" className="dropdown-margin-top">
                              <label className="location-label">Location</label>
                              <DropDownItem
                                list={newLocationList}
                                placeHolderValue="Select Location"
                                onSelect={(value) => {
                                  onSelectLocation(value, item?.tabId);
                                }}
                                selectedValue={`${
                                  locationCount
                                    ? locationCount
                                    : "Select Location"
                                }  ${
                                  locationSelectedText
                                    ? locationSelectedText
                                    : ""
                                }`}
                                disabled={locationDisable}
                              />
                            </MDBCol>
                          )}
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="4"></MDBCol>
                          <MDBCol md="4">
                            <span
                              className={
                                locationErrorvalidationMsg === ""
                                  ? "display-none"
                                  : "error-text"
                              }
                            >
                              {locationErrorvalidationMsg
                                ? locationErrorvalidationMsg
                                : ""}
                            </span>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className={item?.tabId === 1 ? "content-margin" : ""}>
                      <div className="data-container">
                        {item?.tabBody?.map((data, index) => {
                          return (
                            <div className="selected-value-box" key={index}>
                              <span
                                className="color-dot"
                                style={{ background: colors[index] }}
                              ></span>

                              {data}
                              <img
                                src={CloseButton}
                                alt="close-button"
                                onClick={() => {
                                  if (item?.tabId === 1) {
                                    removeSelectedSerialNumber(
                                      item?.tabId,
                                      index
                                    );
                                  } else if (item?.tabId === 2) {
                                    removeSelectedLocation(item?.tabId, index);
                                  }
                                }}
                              />
                            </div>
                          );
                        })}
                      COMET

                        {(item?.tabId === 1) & addSerialNumberButtonShow ? (
                          <div
                            className="add-button"
                            onClick={() => {
                              showSerialNumberAddingModal(item?.tabId);
                            }}
                          >
                            <img src={AddButton} alt="Add-button" />
                            <span className="button-text">
                              {item?.buttonName}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                     
                      </div>
                      COMTE
                    </div> */}
                    {/* --------------------------Location dropdown end --------------- */}
                    <div className={item?.tabId === 1 ? "content-margin" : ""}>
                      <div className="data-container">
                        {item?.tabId === 1
                          ? serialnumberTempArray?.map(
                              (serialnumberTempArray, index) => {
                                return (
                                  <div
                                    className="selected-value-box"
                                    key={index}
                                  >
                                    <span
                                      className="color-dot"
                                      style={{ background: colors[index] }}
                                    ></span>

                                    {serialnumberTempArray}
                                    <img
                                      src={CloseButton}
                                      alt="close-button"
                                      onClick={() =>
                                        removeSelectedSerialNumber(
                                          item?.tabId,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                );
                              }
                            )
                          : item.tabBody?.map((locationTempArray, index) => {
                              return (
                                <div className="selected-value-box" key={index}>
                                  <span
                                    className="color-dot"
                                    style={{ background: colors[index] }}
                                  ></span>

                                  {locationTempArray}
                                  <img
                                    src={CloseButton}
                                    alt="close-button"
                                    onClick={() =>
                                      removeSelectedLocation(item?.tabId, index)
                                    }
                                  />
                                </div>
                              );
                            })}
                        {/* -------------------------- add serial number button start --------------- */}
                        {item?.tabId === 1 && addSerialNumberButtonShow ? (
                          <div
                            className="add-button"
                            onClick={() => {
                              showSerialNumberAddingModal(item?.tabId);
                            }}
                          >
                            <img src={AddButton} alt="Add-button" />
                            <span className="button-text">
                              {item?.buttonName}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* -------------------------- add serial number button end --------------- */}
                      </div>
                    </div>
                  </MDBTabsPane>
                );
              })}
            </MDBTabsContent>
          </div>
          {/* ----------------- Tab component end ------------------------------- */}
        </ThemeCard>
      </div>
      {/* ------------------- Modal component start --------------------------- */}
      <ModalComponent
        showModal={serialNumberAddingModal}
        setShowModal={setSerialNumberAddingModal}
        cancelButtonText="Cancel"
        onClickCancel={closeSerialNumberAddingModal}
        customWidth="add-modal-width"
        title="Add New Device"
        SubmitButtonText="Submit"
        submitButtoncustomClass="modal-button-width"
        cancelButtoncustomClass="modal-button-width"
        onClickSubmit={() => onSubmitSerialNumberInput()}
      >
        <div className="mt-3">
          <label className="input-label">Serial Number</label>
          <InputComponent
            placeholder="Enter serial number"
            // type="number"
            onChange={onChnageSerialNumberInput}
            value={serialNumber}
            onKeyDown={onkeyDownSerialNumber}
            customClass={InputValidationError === true ? "border-red" : ""}
          />
          <p className="error-text">
            {serialNumberErrorMsg ? serialNumberErrorMsg : ""}
          </p>
          <span className="notice-text">Comma (,) to add multiple devices</span>
        </div>
      </ModalComponent>
      {/* ------------------- Modal component end --------------------------- */}
      {/* ------------------------export modal start------------------------------- */}
      <ModalComponent
        showModal={exportGraphModal}
        setShowModal={setExportGraphModal}
        cancelButtonText="Cancel"
        onClickCancel={closeExportmodal}
        customWidth="add-modal-width"
        title="Export Graph"
        SubmitButtonText="Export"
        submitButtoncustomClass="modal-button-width"
        cancelButtoncustomClass="modal-button-width"
        onClickSubmit={() => onExportGraph()}
      >
        <div
          className={
            validateExportGraph
              ? "export-graph-validation mt-4"
              : "export-graph-container mt-4"
          }
        >
          <div className="d-flex">
            <img src={csvFormat} alt="Clock icon" />
            <div className="ms-3 time-label">
              {pageContent.exportGrpahModal.csvFormat}
            </div>
          </div>

          <RadioButton
            onClick={() => {
              setCsv(true);
              setValidateExportGraph(false);
            }}
          />
        </div>
        <div
          className={
            validateExportGraph
              ? "export-graph-validation mt-4"
              : "export-graph-container mt-4"
          }
        >
          <div className="d-flex">
            <img src={pdf} alt="Clock icon" />
            <div className="ms-3 time-label">
              {" "}
              {pageContent.exportGrpahModal.pdfFormat}
            </div>
          </div>

          <RadioButton
            onClick={() => {
              setPdf(true);
              setValidateExportGraph(false);
            }}
          />
        </div>
      </ModalComponent>

      {/* ------------------------export modal end------------------------------- */}
      {/*------------------------ graph start---------------------*/}
      <div className="trends-graph">
        <ThemeCard customClass="card-title">
          {isLoading ? <Loader /> : ""}

          <MDBRow className="graph-top-box">
            {selectDeviceTabId === 2 && (
              <MDBCol md={2}>
                <div
                  className="information-icon"
                  onClick={showCalculationModal}
                >
                  <img src={infoIcon} />{" "}
                </div>
              </MDBCol>
            )}

            <MDBCol md={4} className="trend-date-range-picker">
              {selectDeviceTabId === 1 && (
                <WeekAndYearPicker
                  dateRange={6}
                  maximumDate={previousDay}
                  intialStartDate={startDate}
                  initialEndDate={endDate}
                  getSelectedDates={(startDate, endDate) => {
                    setSelectedDates(startDate, endDate);
                  }}
                  showFilteredCalender={showFilteredCalender}
                  getYearOfData={(selectedYear, currentDateFormat) => {
                    setSelectedDateFormat(currentDateFormat);
                    updateSelectedYear(selectedYear);
                  }}
                  onRemoveDate={(value) => {
                    setShowFilteredCalender(value);
                  }} // Pass callback to update `showFilteredCalender`
                />
              )}
              {selectDeviceTabId === 2 && (
                <WeekAndYearPicker
                  dateRange={6}
                  maximumDate={previousDay}
                  intialStartDate={locationStartDate}
                  initialEndDate={locationEndDate}
                  getSelectedDates={(locationStartDate, locationEndDate) => {
                    setSelectedDatesLocation(
                      locationStartDate,
                      locationEndDate
                    );
                  }}
                  showFilteredCalender={showFilteredCalender}
                  getYearOfData={(selectedYear, currentDateFormat) => {
                    setSelectedDateFormatForLocation(currentDateFormat);
                    setSelectedYearForLocation(selectedYear);
                  }}
                  onRemoveDate={(value) => setShowFilteredCalender(value)} // Pass callback to update `showFilteredCalender`
                />
              )}
            </MDBCol>
          </MDBRow>
          {!emptyGraphData ? (
            <>
              <div className="plug-trends-chart">
                <MixedChart
                  data={
                    selectDeviceTabId === 1 ? serialNumberData : locationData
                  }
                  legendShow={false}
                  customLegendClass={"custom-trend-chart"}
                  showSrollableBarChart={showScrollbar}
                />
                <span className="yaxis-label">{yaxisLabel}</span>
              </div>
              <div className="legend-section">
                {legends.map((item) => {
                  return (
                    <div
                      className={
                        item.active
                          ? "legend-radio-button"
                          : "clicked-legend-radio-button"
                      }
                      onClick={() => {
                        legendClick(item.id);
                        setYaxisLabel(`Average ` + item.name);
                      }}
                    >
                      <div>
                        <span
                          className={
                            item.active
                              ? "clicked-legend-round-button"
                              : "legend-round-button"
                          }
                        ></span>
                      </div>{" "}
                      <span>{item.name}</span>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="data-unavailable-placeholder">
              <img src={emptyDataIcon} />
              <div>Empty Data</div>
            </div>
          )}
        </ThemeCard>{" "}
        {/* trends graph with location */}
      </div>
      {/*------------------------ graph end---------------------------------------*/}

      {/* -------------------command modal---------------------- */}
      <div className="badge-modal">
        <ModalComponent
          showModal={calculationModalShow}
          setShowModal={setCalculationModalShow}
          cancelButtonText={"Cancel"}
          onClickCancel={badgeModalCancel}
          title={"Trends Calculation"}
          customWidth="command-badge-modal-width"
        >
          <div className="calculation-description">
            {pageContent.calculationcontent}
          </div>
        </ModalComponent>
      </div>
      {/* -------------------command modal----------------------- */}
    </div>
  );
};

export default PlugTrends;
