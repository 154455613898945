import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

import "./modalComponent.css";

const ModalComponent = ({
  title,
  children,
  cancelButtonText,
  onClickSubmit,
  SubmitButtonText,
  showModal,
  setShowModal,
  onClickCancel,
  buttonCenter,
  size,
  customWidth,
  cancelButtoncustomClass,
  submitButtoncustomClass,
  submitButtonDisable,
  cancelButtonDisable,
  alarmStatus
}) => {
  return (
    <div className="modal-component">
      <MDBModal show={showModal} setShow={setShowModal} tabIndex="-1">
        <MDBModalDialog
          size={size ? size : ""}
          className={size ? "" : customWidth}
          centered
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{title}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>{children}</MDBModalBody>
            <MDBModalFooter
              className={buttonCenter === true ? "mt-4 button-center" : "mt-4"}
            >
              {alarmStatus ? (
                <ButtonComponent
                  onClick={onClickCancel}
                  text={cancelButtonText}
                  customClass={cancelButtoncustomClass}
                  disabled={cancelButtonDisable}
                />
              ) : (
                <>
                  <ButtonComponent
                    onClick={onClickCancel}
                    text={cancelButtonText}
                    customClass={cancelButtoncustomClass}
                    disabled={cancelButtonDisable}
                  />

                  <ButtonComponent
                    coloredButton={true}
                    text={SubmitButtonText}
                    onClick={onClickSubmit}
                    customClass={`left-margin ${submitButtoncustomClass}`}
                    disabled={submitButtonDisable}
                  />
                </>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default ModalComponent;
