import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RouteConstants from "../../constants/routeConstants.json";
import moment from "moment";
import {
  deviceDashboard,
  singlePlugGragh,
  sendDeviceCommand,
} from "../../apis/Api";
import { GlobalStateContext } from "../../store/store";
import { useContext } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const PlugDashBoardPageContext = createContext({});
export const PlugDashBoardContextProvider = ({ children }) => {
  const { state } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState("ON");
  const [updateFrequncyModal, setUpdateFrequencyModal] = useState(false);
  const [exportPdfModal, setExportPdfModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [singlePlugGraph, setSinglePlugGraph] = useState([]);
  const [energyGraph, setEnergyGraph] = useState([]);
  const [voltageGraph, seVoltageGraph] = useState([]);
  const [currentGraph, setCurrentGraph] = useState([]);
  const [currentReadings, setCurrentReadings] = useState([]); //current readings
  const [profile, setProfile] = useState([]); //  profile details
  const [holiday, setHoliday] = useState([]); //  holiday mode  details
  const [loopTimer, setLoopTimer] = useState([]); //  loop timer details
  const [schedule, setSchedule] = useState([]); // schedule details
  const [offTimer, setOffTimer] = useState([]); // off timer
  const [errorModal, setErrorModal] = useState(false); // api error alert
  const [errorModalMessage, setErrorModalMessage] = useState([]);
  const [labels, setLabels] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("ll")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).subtract(1, "days").format("ll")
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [successModal, setSuccessModal] = useState(false); // success modal show and hide
  const [sucessModalText, setSuccessModalText] = useState("");
  const [dateFormat, setDateFormat] = useState("WEEKLY");
  const [year, setYear] = useState("");
  const [showAlarmModal, setShowAlarmModal] = useState(false); // alarm modal
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  let targets = [];
  targets = [localStorage.getItem("serialNo_NavBar")];

     // show and hide weekand year
     const [showFilteredCalender, setShowFilteredCalender] = useState(true);
    
  if (location.state?.serialNumber) {
 
    localStorage.setItem("serialNo_NavBar", location.state?.serialNumber);
  }

let  serialNumberOngraph = location.state?.serialNumber
  const categoryId = localStorage.getItem("dashboard_Id");
  let serialNumber =  localStorage.getItem("serialNo_NavBar");
  let type = state?.productType;
  let model = localStorage.getItem("model");
  let modelId=localStorage.getItem("modelId")
  useEffect(() => {
    getPlugGraph(targets, startDate, endDate, year);
    plugDashboardDetails();
  }, [startDate, endDate, dateFormat, year]);
  // api call for getting graph data
  const getPlugGraph = async (targets, startDate, endDate, year) => {
    setIsLoading(true);
   
    let data = {
      filter: 1,
      targets:location.state?.serialNumber?[location.state?.serialNumber]:[localStorage.getItem("serialNo_NavBar")],
      type: type,
      startDate: startDate,
      endDate: endDate,
      categoryId: categoryId,
      format: dateFormat,
      year: year,
    };
    try {
      const response = await singlePlugGragh(data);
      setIsLoading(false);
      if (response.data.status === 200) {
        setSinglePlugGraph(response.data.data);
        const trendsDataItems = response?.data?.data;
  
        // getting empty data sets
        trendsDataItems.map((item) => {
          if (
            item?.energy.length === 0 &&
            item?.voltage.length === 0 &&
            item?.current.length === 0
          ) {
            setEmptyGraphData(true);
          } else {
            setEmptyGraphData(false);
          }
        });
        const energyLine = response.data.data[0]?.energy?.map((item, index) => {
          return item?.count;
        });
        const labelEnergy = response.data.data[0]?.energy?.map((item) => {
          const energyDate = moment(item?.date).utc().format("MMM DD, YYYY");
          return energyDate;
        });
        const labelEnergyForYear = response.data.data[0]?.energy?.map(
          (item) => {
            const energyDate = item?.date;
            return energyDate;
          }
        );

        // setting label array if label
        if (dateFormat === "WEEKLY") {
          setLabels(labelEnergy);
        } else {
          setLabels(labelEnergyForYear);
        }

        setEnergyGraph(energyLine);
        const voltagLine = response.data.data[0]?.voltage?.map(
          (item, index) => {
            return item?.count;
          }
        );

        const labelVoltage = response.data.data[0]?.voltage?.map(
          (item, index) => {
            const voltageDate = moment(item?.date).utc().format("MMM DD, YYYY");
            return voltageDate;
          }
        );

        // setLabels(labelVoltage);
        seVoltageGraph(voltagLine);
        const currentLine = response.data.data[0]?.current?.map(
          (item, index) => {
            return item?.count;
          }
        );
        const labelCurrent = response.data.data[0]?.voltage?.map((item) => {
          const currentDate = moment(item?.date).utc().format("MMM DD, YYYY");
          return currentDate;
        });

        // setLabels(labelCurrent);
        setCurrentGraph(currentLine);
      }
    } catch (error) {
      if (error?.data?.status === 401) {
        setErrorModal(true);
        setErrorModalMessage(error?.response?.data?.message);
      } else {
        setErrorModal(true);
        setErrorModalMessage(error?.response?.data?.message);
      }
    }
    setIsLoading(false);
  };
  //  off timer constants
  const offTimerArray = [
    {
      id: 1,
      time: "1",
      value: "01",
    },
    {
      id: 2,
      time: "2",
      value: "02",
    },
    {
      id: 3,
      time: "4",
      value: "04",
    },
    {
      id: 4,
      time: "6",
      value: "06",
    },
  ];
  //  device dashboard details api call

  const plugDashboardDetails = async () => {
    setIsLoading(true);
    const data = {
      type: type,
      model: model,
      srno: location.state?.serialNumber?location.state?.serialNumber: serialNumber,
      modelId: modelId,
    };

    try {
      const response = await deviceDashboard(data);

      if (response?.data?.status === 200) {
        setCurrentReadings(response?.data?.data?.currentReadings); // current readings of plug
        setProfile(response?.data?.data?.profile); //  profile  details of plug
        setHoliday(response.data?.data.holiday); //  holiday mode details
        setLoopTimer(response?.data?.data?.loopTimer); // loop timer
        setSchedule(response?.data?.data?.schedule); // schedule
        setOffTimer(response?.data?.data?.offTimer); // OffTimer
      } else if (response?.data?.status === 500) {
        setErrorModal(true);
        setErrorModalMessage("Something went wrong");
      }
    } catch (error) {
      if (error?.data?.status === 401) {
        setErrorModal(true);
        setErrorModalMessage(error?.response?.data?.message);
      } else {
        setErrorModal(true);
        setErrorModalMessage(error?.response?.data?.message);
      }
    }
    setIsLoading(false);
  };
  // setIsLoading(false);

  //function to  change the date  on graph
  const selectDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    getPlugGraph(targets, startDate, endDate, year);
  };
  // function to change date on export graph
  const selectExportDate = (fromDate, toDate) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };
  const [legendMangement, setLegendManagement] = useState({
    1: true,
    2: true,
    3: true,
  });
  // data for dispaly

  const dataEnergy = energyGraph;
  const dataVoltage = voltageGraph;
  const dataCurrent = currentGraph;

  // data  show bar chat
  const data1 = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Energy",
        backgroundColor: "#F4B351",
        data: legendMangement[1] ? dataEnergy : [],
        barThickness: 8,
        borderRadius: [5, 5, 5, 5],
        stack: "Stack 0",
        id: 1,
      },
      {
        type: "line",
        label: "Voltage",
        data: legendMangement[2] ? dataVoltage : [],
        borderColor: "#5A9EDD",
        backgroundColor: "#5A9EDD",
        borderWidth: 2,
        tension: 0.5,

        id: 2, //if id is not there onclick function doesnt work
      },
      {
        type: "line",
        label: "Current",
        data: legendMangement[3] ? dataCurrent : [],
        borderColor: "#767676",
        backgroundColor: "#767676",
        borderWidth: 2,
        tension: 0.5,
        id: 3, //if id is not there onclick function doesnt work
      },
    ],
  };

  // legend click function
  const onClickLegend = (id) => {
    let newLegendState = null;
    newLegendState = !legendMangement[id];
    setLegendManagement({
      ...legendMangement,
      [id]: newLegendState,
    });
  };
  // navigate to device logs
  const goToDeviceLogs = () => {
    // navigate(RouteConstants.DEVICE_LOGS);
    navigate({
      pathname: RouteConstants.DEVICE_LOGS,
      search: "?plug=singlePlug",
    });
  };
  // export data function
  const exportData = () => {
    setExportPdfModal(false);
  };
  // state frequency update
  const onSubmitTimeInterval = (time) => {
    const stateFrquencyUpdteObject = {
      logType: "sendCommand",
      targetType: "srno",
      target: targets,
      key: "timeInterval",
      interval: time,
    };
    updateStateFrequrencyApi(stateFrquencyUpdteObject);
    setUpdateFrequencyModal(false);
  };
  // api send command statefrequency update
  const updateStateFrequrencyApi = async (data) => {
    setIsLoading(true);
    try {
      const response = await sendDeviceCommand(data);
      setIsLoading(false);
      if (response?.data?.status == 200) {
        plugDashboardDetails();
        setSuccessModal(true);
        setSuccessModalText(response?.data?.message);
      } else if (response?.data?.status === 500) {
        setIsLoading(false);
        setErrorModal(true);
        setShowErrorMessage(response?.data?.message);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorModal(true);
      setShowErrorMessage(true);
    }
  };

  // download graph as pdf
  const downloadGraph = async () => {
    const downloadDiv = document.getElementById("dashboardId");
    if (!downloadDiv) {
      return;
    }
    html2canvas(downloadDiv, { scale: 1 }).then((canvas) => {
      // const dataURL = canvas.toDataURL();
      // const link = document.createElement("a");
      // link.download = "device-dashboard.jpg";
      // link.href = dataURL;
      // link.click();
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "px", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save("device-dashboard.pdf");
    });
  };
  // year based selection -week or year
  const updateSelectedDateFormat = (format) => {
    setDateFormat(format);
  };
  // year based selection
  const updateSelectedYear = (selectedYear) => {
    setYear(selectedYear);
    // getPlugGraph(targets, startDate, endDate, selectedYear);
  };
  // refresh button click
  const refreshButtonClick = () => {
    getPlugGraph(targets, startDate, endDate, year);
    plugDashboardDetails();
  };

  // show alarm modal
  const ShowAlarmModal = () => {
    setShowAlarmModal(true)
  };
// new changes
  const showModalInfomationOnGraph = (time) => {
    setInfoModalVisible(true);
  };
  // cancel table badge modal
  const InfomationModalCancel = () => {
    setInfoModalVisible(false);
  };


  const providerValue = {
    status,
    setStatus,
    onClickLegend,
    data1,
    goToDeviceLogs,
    legendMangement,
    updateFrequncyModal,
    setUpdateFrequencyModal,
    exportPdfModal,
    setExportPdfModal,
    currentReadings,
    profile,
    holiday,
    loopTimer,
    schedule,
    offTimer,
    offTimerArray,
    isLoading,
    errorModal,
    setErrorModal,
    errorModalMessage,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    getPlugGraph,
    selectDate,
    showErrorMessage,
    setShowErrorMessage,
    selectExportDate,
    exportData,
    startDate,
    endDate,
    onSubmitTimeInterval,
    successModal,
    setSuccessModal,
    sucessModalText,
    downloadGraph,
    updateSelectedDateFormat,
    updateSelectedYear,
    refreshButtonClick,
    setShowAlarmModal,
    showAlarmModal,
    ShowAlarmModal,
    emptyGraphData,
    showModalInfomationOnGraph,
    setInfoModalVisible,infoModalVisible,
    InfomationModalCancel,
    showFilteredCalender, setShowFilteredCalender
  };
  return (
    <PlugDashBoardPageContext.Provider value={providerValue}>
      {children}
    </PlugDashBoardPageContext.Provider>
  );
};
