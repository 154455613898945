
import React, { createContext, useState } from "react";

import { useNavigate, useLocation } from "react-router";
import RoutConstants from "../../../constants/routeConstants.json";
export const DupsUserDeviceListContext = createContext({});
export const DupsUserDeviceListContextProvider = ({ children }) => {
  const [count, setCount] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const deviceList = location?.state?.result;
  const catogoryId = localStorage.getItem("dashboard_Id");
  const navigateToDeviceDashboard = (modelName) => {

      navigate({
        pathname: RoutConstants.DUPS_DEVICE_DATA,
        search: "?Inverter=singleInverter",
      });
   
   
 
    localStorage.setItem("model", modelName);
  };
  const providerValue = {
    count,
    deviceList,
    navigateToDeviceDashboard,
  };
return (
  <DupsUserDeviceListContext.Provider value={providerValue}>
    {children}
  </DupsUserDeviceListContext.Provider>
)
}

