import React, { useContext } from "react";
import "./dashBoard.css";
import RoundedDropDownItem from "../../../components/RoundedDropDownComponent/RoundedDropDown";
import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import ThemeCard from "../../../components/ThemeCardComponent/ThemeCard";
import { DashBoardPageContext } from "../DashBoardView";
import InputComponent from "../../../components/InputComponent/InputComponent";
import Search from "../../../assets/images/icons/Search.svg";
import DashboardPageContent from "../../../constants/ContentFile.json";
import MixedChart from "../../../components/ChartComponent/ChartComponent";
import Card from "../../../components/CardComponent/Card";
import DropDownItem from "../../../components/DropDownComponent/DropDown";
import Alertpopup from "../../../components/AlertPopup/AlertPopup";
import Error from "../../../assets/images/icons/Error.svg";
import Loader from "../../../components/LoaderComponent/LoaderComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import infoIcon from "../../../assets/images/icons/info-button.svg";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
const DashBoardPage = () => {
  const {
    modalPlugList,
    data1,
    onClickLegend,
    handleSelect,
    selectedValue,
    onlineCount,
    offlineCount,
    errorCount,
    isLoading,
    onClickSerialNumberSearch, // serial number search bar onclick funtion
    serialNumber,
    setSerialNumber,
    serialNumberRequired,
    countLoading,
    showErrorAlert,
    setShowErrorAlert,
    showErrorMessage,
    modelRequired,
    setSerialNumberRequired,
    navigateToStatusDeviceList,
    errorAlertShow,
    setErrorAlertShow,
    errorAlertMessage,
    setErrorAlertMessage,
    navigateToLogin,
    statusCode,
    showGraphInfo,setShowGraphInfo,
    InfomationModalCancel,
    showGraphInfoamtion
  } = useContext(DashBoardPageContext);
 
  const dashboardContent = DashboardPageContent.DashboardPageContent;
  return (
    <div className="dashboard-page">
      {isLoading ? <Loader /> : ""}
      <MDBContainer fluid>
        <ThemeCard customClass="title-none">
          <div className="input-box">
            <MDBRow>
              <MDBCol size="md-4">
                <RoundedDropDownItem
                  list={modalPlugList}
                  selectedValue={selectedValue}
                  // onSelect={handleSelect}
                  onSelect={(id, name, model) => {
                    handleSelect(id, name, model);
                  }}
                  customClass={
                    modelRequired === true ? "error-input-field" : ""
                  }
                  PlaceHolderValue="Select a model"
                />
              </MDBCol>
              <MDBCol size="md-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onClickSerialNumberSearch(serialNumber);
                  }}
                >
                  <InputComponent
                    roundedButton={true}
                    image={Search}
                    placeholder={dashboardContent.searchDevice}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    onChange={(e) => {
                      setSerialNumberRequired(false);
                      setSerialNumber(e.target.value);
                    }}
                    value={serialNumber}
                    type="number"
                    customClass={
                      serialNumberRequired ? "error-input-field" : ""
                    }
                  />
                </form>
              </MDBCol>
              <MDBCol size="md-2">
                <ButtonComponent
                  customClass={"dashboard-submit-button"}
                  coloredButton={true}
                  text={"Submit"}
                  onClick={() => {
                    onClickSerialNumberSearch(serialNumber);
                  }}
                  buttonWidth="100%"
                />
              </MDBCol>
            </MDBRow>
          </div>
        </ThemeCard>
        <div className="modal-item-display">
          {selectedValue === null ? "" : "Model: " + selectedValue}
        </div>
        <MDBContainer fluid className="card-row">
          <MDBRow>
            <MDBCol size="md-3" className="card-online">
              <Card
                cardColur={"#4DCF71"}
                cardTypeText={dashboardContent.online}
                countOnCard={
                  countLoading ? (
                    <MDBSpinner className="count-loader" size="sm"></MDBSpinner>
                  ) : (
                    onlineCount
                  )
                }
                onClick={() => {
                  navigateToStatusDeviceList("online");
                }}
              />
            </MDBCol>
            <MDBCol size="md-3" className="card-online">
              <Card
                cardColur={"#F46F6F"}
                cardTypeText={dashboardContent.ofline}
                countOnCard={
                  countLoading ? (
                    <MDBSpinner className="count-loader" size="sm"></MDBSpinner>
                  ) : (
                    offlineCount
                  )
                }
                onClick={() => {
                  navigateToStatusDeviceList("shutdown");
                  localStorage.setItem("model","")
                }}
              />
            </MDBCol>
            <MDBCol size="md-3" className="card-online">
              <Card
                cardColur={"#929292"}
                cardTypeText={dashboardContent.error}
                countOnCard={
                  countLoading ? (
                    <MDBSpinner className="count-loader" size="sm"></MDBSpinner>
                  ) : (
                    errorCount
                  )
                }
                onClick={() => {
                  navigateToStatusDeviceList("error");
                }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <ThemeCard
          cardTitle={dashboardContent.deviceAdded}
          customClass={"device-card-title"}
         // customMainClass={"device-card-main"}
        >
          <div className="addDevices-status">
        <div className="textadd-devices">{dashboardContent.deviceAdded}
       
            </div>
            <div
                className="information-icon"
                 onClick={showGraphInfoamtion}
              >
                <img src={infoIcon} />
              </div>
            </div>
            
          <MixedChart data={data1} clickLegend={onClickLegend} />
        </ThemeCard>
      </MDBContainer>
      
  <Alertpopup
        image={Error}
        setShowModal={setShowErrorAlert}
        showModal={showErrorAlert}
        titleText="Error"
        DetailText={showErrorMessage}
        modalType="error-modal" 
        alertButtonText="Continue"
        onclickModalContinue={()=>{setShowErrorAlert(false)}}
      ></Alertpopup> 
           <div className="badge-modal">
        <ModalComponent
          showModal={showGraphInfo}
          setShowModal={setShowGraphInfo}
          cancelButtonText={"Cancel"}
          onClickCancel={InfomationModalCancel}
          title={"Registered Devices"}
          customWidth="command-badge-modal-width"
        >
          <div className="calculation-description">
          The graph shows the number of registered devices per month in a year.
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};
export default DashBoardPage;
