import React, { createContext, useState } from "react";
import ContentFile from "../../constants/ContentFile.json";
import { validateEmail } from "../../utils/service";
import { useNavigate } from "react-router-dom";
import RouteConstant from "../../constants/routeConstants.json";
import { forgotPasswordAPI } from "../../apis/Api";

export const ForgotPasswordContext = createContext();
export const ForgotPasswordContextProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [emailvalidationMsg, setEmailvalidationMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [SubmitSuccessModal, setSubmitSuccessModal] = useState(false);
  const [modalSucsessMessage, setModalSuccessMssage] = useState("");
  const [SubmitErrorModal, setSubmitErrorModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const pageContent = ContentFile.authPage;
  const navigate = useNavigate();

  // onChnage email input
  const onChangeEmailInput = (e) => {
    setEmail(e.target.value);
    emailValidation(e.target.value);
  };

  // validate email
  const emailValidation = (value) => {
    if (!value) {
      setEmailvalidationMsg("Email required");
      return false;
    } else if (!validateEmail(value)) {
      setEmailvalidationMsg("Invalid Email");
      return false;
    } else {
      setEmailvalidationMsg("");
      return true;
    }
  };

  // onclick submit email
  const onHandleSubmitEmail = async (e) => {
    e.preventDefault();
    if (emailValidation(email)) {
      setShowLoader(true);
      const data = {
        email: email,
      };
      try {
        const response = await forgotPasswordAPI(data);
        setShowLoader(false);
        if (response?.data?.status === 200) {
          setSubmitSuccessModal(true);
          setModalSuccessMssage("Email submitted successfully.");
          setEmail("");
        }
      } catch (error) {
        setShowLoader(false);
        setSubmitErrorModal(true);
        setModalErrorMessage(error.response.data?.message);
        setEmail("");
      }
    }
  };

  // function for redirect to login
  const onHandleBackToLogin = () => {
    navigate(RouteConstant.LOGIN);
  };

  const providervalue = {
    pageContent, // page static text
    onChangeEmailInput, // on change email inpput
    email, // state to store email
    emailvalidationMsg, // state to store email validation message
    onHandleBackToLogin, // function for redirect to login
    onHandleSubmitEmail, // on click submit email
    showLoader, // state to show loader
    SubmitSuccessModal, // submit success modal state
    setSubmitSuccessModal, // submit success modal set state
    modalSucsessMessage, // success modal message
    SubmitErrorModal, // submit error modal state
    setSubmitErrorModal, // submit error modal set state
    modalErrorMessage, //  modal error message
  };
  return (
    <ForgotPasswordContext.Provider value={providervalue}>
      {children}
    </ForgotPasswordContext.Provider>
  );
};
