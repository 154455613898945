import React, { createContext, useContext, useEffect, useState } from "react";
// images import
import placeholder from "../../assets/images/placeholderImage.svg";

import { useNavigate, useLocation, createSearchParams } from "react-router";
import contentData from "../../constants/ContentFile.json";
import { getDeviceCategories } from "../../apis/Api";
import RouteConstants from "../../constants/routeConstants.json";
import { GlobalStateContext } from "../../store/store";
import { getCookie } from "../../utils/service";

export const HomePageContext = createContext({});
export const HomePageContextProvider = ({ children }) => {
  const {
    state,
    changeSideMenu,
    changeSideMenuBasedOnSession,
    setProductType,
  } = useContext(GlobalStateContext);

  const navigate = useNavigate();
  const contents = contentData.homePage;

  //for show data on loading
  const categoryItems = [
    { name: "-", categoryImage: placeholder, count: "-" },
    { name: "-", categoryImage: placeholder, count: "-" },
    { name: "-", categoryImage: placeholder, count: "-" },
    { name: "-", categoryImage: placeholder, count: "-" },
    { name: "-", categoryImage: placeholder, count: "-" },
    { name: "-", categoryImage: placeholder, count: "-" },
  ];
  const [categoryList, setCategoryList] = useState(categoryItems);
  const [isLoading, setIsLoading] = useState(false);
  const allowLogin = getCookie("x-access-token");
  useEffect(() => {
    getHomePageData();
    !allowLogin && navigate(RouteConstants?.LOGIN);
  }, []);

  // api call
  const getHomePageData = async () => {
    setIsLoading(true);
    try {
      const response = await getDeviceCategories();
      if (response.data.status == 200) {
        setCategoryList(response.data.data.categories);
      }
    } catch (error) {}
    setIsLoading(false);
  };
  // funtion to navigate
  const goToDashboard = (name, id) => {
  //  console.log("name",name , id)
    localStorage.setItem("dashboard_Id", id);
    if (name === "Plug") {
     
      navigate({
        pathname: RouteConstants.DASHBOARD,
        state: {
          id: id,
        },
        search: "?plug=Plug",
      });
      sessionStorage.setItem("productName", "Plug");
      changeSideMenu("Plug");
      setProductType("smartplug");
      // changeSideMenuBasedOnSession();
      // const {state} = useContext(GlobalStateContext);
    }
    else if (name === "Fan"){

      navigate({
        pathname: RouteConstants.FAN_DASHBOARD,
        state: {
          id: id,
        },
        search: "?fan=Fan",
      });
      sessionStorage.setItem("productName", "Fan");
      localStorage.setItem("model_Fan", "Fan");
      changeSideMenu("Fan");
      setProductType("smartfan");
    }
    else if (name === "Water Heater"){
      navigate({
        pathname: RouteConstants.WATER_HEATER_DASHBOARD,
        state: {
          id: id,
        },
        search: "?waterheater=waterheater",
      });
      sessionStorage.setItem("productName", "WaterHeater");
      localStorage.setItem("model_waterheater", "WaterHeater");
      changeSideMenu("waterheater");
      setProductType("smartewh");
    }
    else if (name === "Stabilizer"){
      navigate({
        pathname: RouteConstants.STABILIZER_DASHBOARD,
        state: {
          id: id,
        },
        search: "?stabilizer=stabilizer",
      });
      sessionStorage.setItem("productName", "Stabilizer");
     
      changeSideMenu("stabilizer");
      setProductType("stabilizer");
    }
    else if (name === "Switch"){
      navigate({
        pathname: RouteConstants.RETROFIT_DASHBOARD,
        state: {
          id: id,
        },
        search: "?retrofit=retrofit",
      });
      sessionStorage.setItem("productName", "retrofit");
     
      changeSideMenu("retrofit");
      setProductType("retrofit");
    }
    else if (name === "Inverter"){
      navigate({
        pathname: RouteConstants.DUPS_DASHBOARD,
        state: {
          id: id,
        },
   
        
        search: "?Inverter=Inverter",
      });
      
      sessionStorage.setItem("productName", "Inverter");
     
      changeSideMenu("Inverter");
      setProductType("dups");
    }
  };

  const providerValue = {
    categoryList,
    categoryItems,
    contents,
    goToDashboard,
    isLoading,
  };

  return (
    <HomePageContext.Provider value={providerValue}>
      {children}
    </HomePageContext.Provider>
  );
};
