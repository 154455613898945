import React from "react";
import { RetrofitUserDeviceListPageContextProvider } from "./RetrofitUserDeviceListView";
import RetrofitUserDeviceListPage from "./views/RetrofitUserDeviceList";
const RetrofitUserDeviceList =()=>{
    return(
    <RetrofitUserDeviceListPageContextProvider>
        <RetrofitUserDeviceListPage/>
    </RetrofitUserDeviceListPageContextProvider>
    )
}
export default RetrofitUserDeviceList