import React from 'react'
import { StabilizerDeviceDataContextProvider } from './StabilizerDeviceDataView'
import StabilizerDeviceData from './views/StabilizerDeviceData'

const StabilizerDeviceDataPage = () => {
  return (
   <StabilizerDeviceDataContextProvider>
    <StabilizerDeviceData/>
   </StabilizerDeviceDataContextProvider>
  )
}

export default StabilizerDeviceDataPage
